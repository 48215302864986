import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import right_arrowa from "../../../assets/images/right_arrowa.svg";
import toast, { Toaster } from "react-hot-toast";
import {
  PATIENT_CONTROLER_URL,
  PATIENT_CONTROLER_URL_ID,
  DOCTOR_DETAILS,
  GET_ALL_STAFF,
} from "../../../api/Service";
import { createAxiosInstance } from "../../../api/axiosConfig";
import moment from "moment";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { logoutFromHelper } from "../../../api/Helper";
import { logout } from "../../../redux/auth/AuthSlice";
import { removeuser } from "../../../redux/user/userSlice";
import {
  addPatientFullName,
  addPatientId,
} from "../../../redux/patient/PatientSlice";
import s from "../../pages/PatientDetailGp/components/EditModalForm/EditModalForm.module.css";
import Select from "../../../components/helpers/Select/Select";

const AddPatient = ({ route, navigation }) => {
  const [value, setValue] = useState(dayjs(new Date().setMinutes(0)));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let tenantVal = useSelector((state) => state.auth.user?.tenant);
  if (!tenantVal) tenantVal = localStorage.getItem("kinesin-tenant");
  const patientId = useSelector((state) => state.patient.patientId);
  const previousPageForEditPatient = useSelector((state) => {
    return state.patient.previousPageForEditPatient;
  });
  const [checkedConsentEmail, setCheckedConsentEmail] = React.useState(false);
  const [checkedConsentSms, setCheckedConsentSms] = React.useState(false);
  const [checkedConsentPhone, setCheckedConsentPhone] = React.useState(false);

  const [privateInsuranceProviders, setPrivateInsuranceProviders] =
    React.useState([
      { label: "Select", value: "" },
      { label: "VHI Healthcare", value: "VHI Healthcare" },
      { label: "Laya Healthcare", value: "Laya Healthcare" },
      { label: "Irish life Health", value: "Irish life Health" },
    ]);

  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    dob: moment(new Date(dayjs(value))).format("DD/MM/YYYY"),
    sex: "",
    telephone: "",
    mobileTel: "",
    email: "",
    ppsNumber: "",
    doctorDetailsId: 0,
    addressId: "",
    gsm: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    county: "",
    postCode: "",
    country: "",
    medicalCardNumber: "",
    privateInsuranceProvider: "",
    privateInsuranceNumber: "",
    ihiNumber: "",
    gpName: "",
    gpSurgery: "",
    gpTelephoneNumber: "",
    gpCity: "",
    gpAddressLine1: "",
    gpAddressLine2: "",
    gpAddressLine3: "",
    gpPostCode: "",
    gpHealthMail: "",
  });

  const fetchPatientById = async (id) => {
    try {
      if (id) {
        const axiosInstance = createAxiosInstance();
        const response = await axiosInstance.get(
          `${tenantVal}${PATIENT_CONTROLER_URL_ID}${id}`
        );

        let res = response.data;

        if (res) {
          formData.title = res.title;
          formData.firstName = res.firstName;
          formData.lastName = res.surname;
          if (res.dateOfBirth) {
            let aa = res.dateOfBirth.split("-");
            if (aa && aa.length == 3) {
              let date = new Date();
              date.setDate(aa[2]);
              date.setMonth(aa[1] - 1);
              date.setFullYear(aa[0]);

              formData.dob = moment(new Date(dayjs(date))).format("DD/MM/YYYY");
              setValue(dayjs(date));
            }
          }
          formData.sex = res.gender;
          formData.telephone = res.homePhone;
          formData.mobileTel = res.mobilePhone;
          formData.email = res.email;
          formData.gsm = res.gsm;
          formData.ppsNumber = res.nationalNumber;
          formData.medicalCardNumber = res.medicalCardNumber;
          formData.privateInsuranceNumber = res.privateInsuranceNumber;
          formData.privateInsuranceProvider = res.privateInsuranceProvider;
          setCheckedConsentEmail(res.contactByEmail);
          setCheckedConsentPhone(res.contactByPhone);
          setCheckedConsentSms(res.contactBySms);
          if (res.address) {
            formData.addressId = res.address.id;
            formData.addressLine1 = res.address.addressLine1;
            formData.addressLine2 = res.address.addressLine2;
            formData.addressLine3 = res.address.addressLine3;
            formData.city = res.address.city;
            formData.county = res.address.county;
            formData.country = res.address.country;
            formData.postCode = res.address.postCode;
          }
          if (res.doctorDetails) {
            formData.gpName = res.doctorDetails.firstName;
            formData.gpSurgery = res.doctorDetails.surgery;
            formData.gpTelephoneNumber = res.doctorDetails.telephone;
            formData.gpHealthMail = res.doctorDetails.healthMail;
            if (res.doctorDetails.address) {
              formData.gpCity = res.doctorDetails.address.city;
              formData.gpAddressLine1 = res.doctorDetails.address.addressLine1;
              formData.gpAddressLine2 = res.doctorDetails.address.addressLine2;
              formData.gpAddressLine3 = res.doctorDetails.address.addressLine3;
              formData.gpPostCode = res.doctorDetails.address.postCode;
            }
          }
          setFormData({ ...formData });
        }
      }
    } catch (error) {
      errorHandling(error);
    }
  };

  const [doctors, setDoctors] = useState([]);

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const axiosInstance = createAxiosInstance();
        const response = await axiosInstance.get(
          `${tenantVal}${GET_ALL_STAFF}`
        );
        let t = [];
        if (response && response.data) {
          response.data.forEach((element) => {
            t.push({
              label: element.firstName + " " + element.lastName,
              doctorName: `${element.firstName} ${element.lastName}`,
              firstName: element.firstName,
              lastName: element.lastName,
              value: element.id,
              profilePic: element.profilePic,
            });
          });
        }
        setDoctors(t);
      } catch (error) {
        errorHandling(error);
      }
    };

    fetchDoctors();
  }, []);

  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetchPatientById(patientId);
    }
  }, []);

  useEffect(() => {
    validateForm();
  }, [formData]);

  const [formParam, setFormParam] = useState({
    errors: {
      email: "",
    },
    submitted: false,
  });
  const validateForm = () => {
    let fData = Object.assign({}, formData);
    let formIsValid = true;
    let f = Object.assign({}, formParam);
    f.errors.firstName = "";
    f.errors.lastName = "";
    f.errors.dob = "";

    if (!formData.firstName) {
      formIsValid = false;
      f.errors.firstName = "*Please enter first name.";
    }
    if (!formData.lastName) {
      formIsValid = false;
      f.errors.lastName = "*Please enter last name.";
    }
    if (!formData.dob) {
      formIsValid = false;
      f.errors.dob = "*Please enter date of birth.";
    } else if (formData.dob.length < 10) {
      formIsValid = false;
      f.errors.dob = "Please check date of birth";
    } else {
      if (formData.dob.indexOf("/") > 0) {
        let age = calculateAge([
          formData.dob.split("/")[2],
          formData.dob.split("/")[1],
          formData.dob.split("/")[0],
        ]);

        if (age < 0) {
          formIsValid = false;
          f.errors.dob = "Age must be greater than zero";
        }
      }
    }

    if (formData.email && !/.+@.+\.[A-Za-z]+$/.test(formData.email)) {
      formIsValid = false;
      f.errors.email = "Please enter a valid email address.";
    }
    setFormParam(f);
    return formIsValid;
  };

  const calculateAge = (dob) => {
    let age;
    if (dob) {
      const dobDate = new Date(
        dob[0], // Year
        dob[1] - 1, // Month
        dob[2] // Day
      );
      const today = new Date();
      age = today.getFullYear() - dobDate.getFullYear();
      const monthDiff = today.getMonth() - dobDate.getMonth();
      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < dobDate.getDate())
      ) {
        age--;
      }
    }
    return age;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let formattedValue = value;

    if (name === "dob") {
      formattedValue = formatDob(value);
    } else if (
      name === "telephone" ||
      name === "mobileTel" ||
      name === "gpTelephoneNumber"
    ) {
      // For the "telephone", "mobileTel", and "gpTelephoneNumber" fields, only allow numbers
      formattedValue = value.replace(/\D/g, ""); // Remove all non-numeric characters
    }
    setFormData({ ...formData, [name]: formattedValue });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    formParam.submitted = true;
    if (validateForm()) {
      formParam.submitted = true;
    } else return;
    let doctorDetailsId;
    const postDataForDoctorDetails = {
      tenant: tenantVal,
      firstName: formData.gpName,
      surgery: formData.gpSurgery,
      telephone: formData.gpTelephoneNumber,
      healthMail: formData.gpHealthMail,
      address: {
        tenant: tenantVal,
        city: formData.gpCity,
        addressLine1: formData.gpAddressLine1,
        addressLine2: formData.gpAddressLine2,
        addressLine3: formData.gpAddressLine3,
        postCode: formData.gpPostCode,
      },
    };
    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.post(
        `${tenantVal}${DOCTOR_DETAILS}`,
        postDataForDoctorDetails
      );
      doctorDetailsId =
        response && response.data && response.data.id ? response.data.id : "";
    } catch (error) {
      errorHandling(error);
    }
    const postData = {
      title: formData.title,
      firstName: formData.firstName,
      surname: formData.lastName,
      dateOfBirth: formData.dob
        ? moment(formData.dob, "DD/MM/YYYY").format("YYYY-MM-DD")
        : undefined,
      gender: formData.sex && formData.sex !== "Select" ? formData.sex : "",
      homePhone: formData.telephone,
      mobilePhone: formData.mobileTel,
      email: formData.email,
      nationalNumber: formData.ppsNumber,
      medicalCardNumber: formData.medicalCardNumber,
      contactBySms: checkedConsentSms,
      contactByEmail: checkedConsentEmail,
      contactByPhone: checkedConsentPhone,
      nextOfKin: formData.nextOfKin,
      nextOfKinRelationship: formData.nextOfKinRelationship,
      nextOfKinPhone: formData.nextOfKinPhone,
      address: {
        id: formData.addressId,
        tenant: tenantVal,
        addressLine1: formData.addressLine1,
        addressLine2: formData.addressLine2,
        addressLine3: formData.addressLine3,
        city: formData.city,
        county: formData.county,
        country: formData.country,
        postCode: formData.postCode,
      },
      doctorDetails: {
        tenant: tenantVal,
        firstName: formData.gpName,
        surgery: formData.gpSurgery,
        telephone: formData.gpTelephoneNumber,
        healthMail: formData.gpHealthMail,
        address: {
          tenant: tenantVal,
          city: formData.gpCity,
          addressLine1: formData.gpAddressLine1,
          addressLine2: formData.gpAddressLine2,
          addressLine3: formData.gpAddressLine3,
          postCode: formData.gpPostCode,
        },
      },
      doctorDetailsId: formData.doctorDetailsId,
      privateInsuranceProvider: formData.privateInsuranceProvider,
      privateInsuranceNumber: formData.privateInsuranceNumber,
      gsm: formData.gsm,
      died: formData.died,
      dateOfDeath: formData.dateOfDeath
        ? moment(formData.dateOfDeath, "DD/MM/YYYY").format("YYYY-MM-DD")
        : undefined,
    };

    if (patientId) postData.id = patientId;

    if (formData.addressId) postData.address.id = formData.addressId;
    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.post(
        `${tenantVal}${PATIENT_CONTROLER_URL}`,
        postData
      );
      setTimeout(() => {
        customBackButton(true);
      }, 500);
    } catch (error) {
      errorHandling(error);
    }
  };

  const customBackButton = (submit) => {
    if (submit) {
      if (!previousPageForEditPatient)
        navigate("/patient", { state: { ...formData, showToast: true } });
      else if (previousPageForEditPatient == "patient")
        navigate("/patient", { state: { ...formData, showToast: true } });
      else if (previousPageForEditPatient == "patient-detail") {
        dispatch(addPatientId(patientId));
        localStorage.setItem("kinesin-patientId", patientId);
        dispatch(
          addPatientFullName(
            formData.firstName
              ? formData.firstName +
                  (formData.lastName ? " " + formData.lastName : "")
              : ""
          )
        );
        navigate("/patient-detail", { state: { showToast: true } });
      }
    } else {
      if (!previousPageForEditPatient)
        navigate("/patient", { state: formData });
      else if (previousPageForEditPatient == "patient")
        navigate("/patient", { state: formData });
      else if (previousPageForEditPatient == "patient-detail") {
        dispatch(addPatientId(patientId));
        localStorage.setItem("kinesin-patientId", patientId);
        dispatch(
          addPatientFullName(
            formData.firstName
              ? formData.firstName +
                  (formData.lastName ? " " + formData.lastName : "")
              : ""
          )
        );
        navigate("/patient-detail", { state: { showToast: false } });
      }
    }
  };
  // DOB format handler
  const formatDob = (dob) => {
    const cleanedValue = dob.replace(/[^\d]/g, ""); // Remove non-numeric characters
    if (cleanedValue.length <= 2) {
      return cleanedValue;
    } else if (cleanedValue.length <= 4) {
      return cleanedValue.replace(/^(\d{2})(\d{0,2})/, "$1/$2");
    } else {
      return cleanedValue.replace(/^(\d{2})(\d{2})(\d{0,4}).*/, "$1/$2/$3");
    }
  };

  const errorHandling = (error) => {
    console.log(error);
    toast.error(error.message);
    if (error && error.response && error.response.status) {
      if (error.response.status == 403) {
        logoutFromHelper();
        dispatch(logout());
        dispatch(removeuser());
        navigate("/logout");
      }
    }
  };

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="dashBcontbody addpatentdashbord">
        <div className="patientsheading">
          <div className="patientsearchbar">
            <div>
              <h3> {patientId ? "Edit Patient" : "Add Patient"} </h3>
              <button
                className="add_patientsbtn"
                onClick={() => customBackButton()}
              >
                <img src={right_arrowa} alt="" />
                {patientId
                  ? !previousPageForEditPatient ||
                    previousPageForEditPatient == "patient"
                    ? "All Patients"
                    : "Patient Details"
                  : "All Patients"}
              </button>
            </div>
          </div>
        </div>
        <div className="signup_threebox addpatientlist mt-0">
          <div className="addpatientlistcontbox">
            <h4>Personal Information</h4>
            <form onSubmit={handleFormSubmit}>
              <div className="row">
                <div className="col-xl-3 col-md-6 mb-3">
                  <label>Title</label>
                  <select
                    className="form-select form-control dpblock"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Miss">Miss</option>
                    <option value="Dr">Dr</option>
                    <option value="Prof">Prof</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label className="requiredValidator">First Name</label>
                  <input
                    name="firstName"
                    type="text"
                    className="form-control"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                  {formParam.submitted && (
                    <div className="errorMsg text-start">
                      {formParam.errors.firstName}
                    </div>
                  )}
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label className="requiredValidator">Last Name</label>
                  <input
                    name="lastName"
                    type="text"
                    className="form-control"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                  {formParam.submitted && (
                    <div className="errorMsg text-start">
                      {formParam.errors.lastName}
                    </div>
                  )}
                </div>
                <div className="col-xl-3 col-md-6 mb-3">
                  <label>DOB</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        value={value}
                        sx={{ width: "100%" }}
                        onChange={(newValue) => {
                          setValue(newValue);
                          var d = new Date(dayjs(newValue));
                          setFormData({
                            ...formData,
                            ["dob"]: moment(d).format("DD/MM/YYYY"),
                          });
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  {formParam.submitted && (
                    <div className="errorMsg text-start">
                      {formParam.errors.dob}
                    </div>
                  )}
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label>Sex </label>
                  <select
                    className="form-select form-control dpblock"
                    name="sex"
                    value={formData.sex}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    <option value=""></option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label>Telephone</label>
                  <input
                    name="telephone"
                    type="text"
                    className="form-control"
                    value={formData.telephone}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label>Mobile Tel</label>
                  <input
                    name="mobileTel"
                    type="text"
                    className="form-control"
                    value={formData.mobileTel}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label>Email </label>
                  <input
                    name="email"
                    type="text"
                    className="form-control"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {formParam.submitted && (
                    <div className="errorMsg text-start">
                      {formParam.errors.email}
                    </div>
                  )}
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label>PPS Number</label>
                  <input
                    name="ppsNumber"
                    type="text"
                    className="form-control"
                    value={formData.ppsNumber}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label>Main doctor</label>
                  <select
                    name="doctorDetailsId"
                    as="select"
                    className="form-select form-control custom-select"
                    value={formData.doctorDetailsId}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>

                    {doctors
                      ? doctors.map((Staff) => (
                          <option value={Staff.value} key={Staff.value}>
                            {Staff.firstName} {Staff.lastName}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label>GSM patient</label>
                  <select
                    className="form-select form-control dpblock"
                    name="gsm"
                    value={formData.gsm}
                    onChange={handleInputChange}
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                </div>
                <div className="col-xl-3 col-md-6 mb-3">
                  <label>Medical card number (GSM)</label>
                  <input
                    name="medicalCardNumber"
                    type="text"
                    className="form-control"
                    value={formData.medicalCardNumber}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label>Private Insurance Provider</label>
                  <select
                    className="form-select form-control dpblock"
                    name="privateInsuranceProvider"
                    value={formData.privateInsuranceProvider}
                    onChange={handleInputChange}
                  >
                    {privateInsuranceProviders.map((option) => {
                      return (
                        <option label={option.label} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label>Next of kin</label>
                  <input
                    name="nextOfKin"
                    type="text"
                    className="form-control"
                    value={formData.nextOfKin}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label>Next of kin Relationship</label>
                  <input
                    name="nextOfKinRelationship"
                    type="text"
                    className="form-control"
                    value={formData.nextOfKinRelationship}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label>Next of kin Phone</label>
                  <input
                    name="nextOfKinPhone"
                    type="text"
                    className="form-control"
                    value={formData.nextOfKinPhone}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label className={s.label}>Is deceased</label>
                  <Select
                    options={[".", "Yes", "No"]}
                    value={formData.died ? "Yes" : "No"}
                    onChange={(value) => {
                      console.log("died " + value);
                      if (value === "Yes") {
                        setFormData({ ...formData, died: true });
                      } else {
                        setFormData({ ...formData, died: false });
                      }
                    }}
                  />
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label className={s.label}>Date of Death</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        value={formData.dateOfDeath}
                        sx={{ width: "100%" }}
                        onChange={(newValue) => {
                          setValue(newValue);
                          const d = new Date(dayjs(newValue));
                          setFormData({
                            ...formData,
                            ["dateOfDeath"]: moment(d).format("DD/MM/YYYY"),
                          });
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>

                <div className="col-md-12 mt-3">
                  <h4>Address</h4>
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label>Address Line 1</label>
                  <input
                    name="addressLine1"
                    type="text"
                    className="form-control"
                    value={formData.addressLine1}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label>Address Line 2</label>
                  <input
                    name="addressLine2"
                    type="text"
                    className="form-control"
                    value={formData.addressLine2}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label>Address Line 3</label>
                  <input
                    name="addressLine3"
                    type="text"
                    className="form-control"
                    value={formData.addressLine3}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label>City</label>
                  <input
                    name="city"
                    type="text"
                    className="form-control"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label>County</label>
                  <input
                    name="county"
                    type="text"
                    className="form-control"
                    value={formData.county}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label>Post Code</label>
                  <input
                    name="postCode"
                    type="text"
                    className="form-control"
                    value={formData.postCode}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-xl-3 col-md-6 mb-3">
                  <label>Country</label>
                  <input
                    name="country"
                    type="text"
                    className="form-control"
                    value={formData.country}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-md-12 mt-3">
                  <div className="btnxscenter">
                    <button className="custom_btn savwidth" type="submit">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPatient;
