import React from "react";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Download, Edit, Loader2 } from "lucide-react";
import { Image } from "react-bootstrap";

import srcicon from "../../../../../../../assets/images/srcicon.svg";
import EmptyState from "../../../../../../../assets/images/medications-empty-state.svg";
import styles from "./Table.module.css";
import { SortByButton } from "./SortByButton";
import { AddPrescriptionForm } from "../../../../../../../components/AddPrescriptionForm/AddPrescriptionForm";
import Modal from "../../../../../../../components/helpers/Modal/Modal";
import moment from "moment";
import { useSelector } from "react-redux";
import { createAxiosInstance } from "../../../../../../../api/axiosConfig";

export const Table = ({ items, tableName, isLoading }) => {
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState("newest-first");
  const [isEditPrescriptionModalOpen, setIsEditPrescriptionModalOpen] =
    useState(false);
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");
  const patientId = localStorage.getItem("kinesin-patientId");

  const sortedAndFilteredItems =
    items
      ?.map((item) => ({
        ...item,
        medicationList:
          item.medicines?.map((medicine) => medicine.tradeName).join(", ") ||
          "N/A",
      }))
      .filter((item) =>
        item.medicationList.toLowerCase().includes(search.toLowerCase())
      ) || [];

  const handleDownload = async (item) => {
    try {
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/patientprescription/download/prescription/${item.id}/patient/${patientId}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `prescription_${item.id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      toast.error("Error downloading prescription");
    }
  };

  if (items.length === 0) {
    return (
      <div className={styles.emptyState}>
        <Image src={EmptyState} alt="category" />
        <div>
          <p className={styles.emptyStateTitle}>No prescriptions</p>
          <p className={styles.emptyStateText}>
            {search
              ? "Try adjusting your search or filters to find what you're looking for."
              : "Once the patient receives a prescription, it will appear here."}
          </p>
        </div>
      </div>
    );
  }

  const renderTableContent = () => {
    if (isLoading) {
      return (
        <div className={styles.emptyState}>
          <Loader2 className={styles.loadingSpinner} size={24} />
          <p className={styles.emptyStateTitle}>Loading data...</p>
          <p className={styles.emptyStateText}>
            Please wait while we fetch the medical history.
          </p>
        </div>
      );
    }

    if (sortedAndFilteredItems.length === 0) {
      return (
        <div className={styles.emptyState2}>
          <p className={styles.emptyStateTitle2}>No items found</p>
          <p className={styles.emptyStateText2}>
            {search
              ? "Try adjusting your search or filters to find what you're looking for."
              : "There are no items to display at this time."}
          </p>
        </div>
      );
    }

    return (
      <ul className={styles.tableItems}>
        {sortedAndFilteredItems.map((item) => {
          const datePrescribed = item.datePrescribed;

          return (
            <li className={styles.tableItem} key={item.id}>
              <p aria-describedby={`${tableName}-table-date-prescribed`}>
                {datePrescribed}
              </p>
              <p aria-describedby={`${tableName}-table-doctor`}>
                {item.prescribedStaffMember?.firstName &&
                item.prescribedStaffMember?.lastName
                  ? item.prescribedStaffMember?.firstName +
                    " " +
                    item.prescribedStaffMember?.lastName
                  : "N/A"}
              </p>
              <div aria-describedby={`${tableName}-table-drugs-name`}>
                {item.medications.length}
                <div className={styles.buttonsWrapper}>
                  <a
                    className={styles.downloadLink}
                    onClick={() => handleDownload(item)}
                    aria-label="Download prescription"
                    role="button"
                    tabIndex={0}
                  >
                    <Download width={16} height={16} />
                  </a>
                  <button
                    className={styles.editButton}
                    onClick={() => setIsEditPrescriptionModalOpen(true)}
                    aria-label="Edit prescription"
                  >
                    <Edit width={16} height={16} />
                  </button>
                  {isEditPrescriptionModalOpen && (
                    <Modal
                      title="Edit Prescription"
                      position="center"
                      onClose={() => setIsEditPrescriptionModalOpen(false)}
                      hideCancel={true}
                      width="1100px"
                      maxHeight="90vh"
                    >
                      <AddPrescriptionForm
                        initialData={{
                          id: item.id,
                          filePath: item.filePath,
                          instructions: item.note || "",
                          medicines: item.medications.map((medication) => ({
                            id: medication.id,
                            dosage: medication.dosage || "",
                            form: medication.form || "",
                            startDate: moment(medication.startDate).format(
                              "DD-MM-YYYY"
                            ),
                            finishDate: moment(medication.finishDate).format(
                              "DD-MM-YYYY"
                            ),
                            duration: medication.duration || "",
                            durationFormat: medication.durationFormat || "",
                            usageType: medication.acuteLongTerm || "",
                            repeat: medication.repeat || "",
                            tradeName: medication.tradeName || "",
                            manufacturer: medication.manufacturer || "",
                            packSize: medication.pack || "",
                            medicineId: medication.medicationId || "",
                            route: medication.route || "",
                            frequency: medication.frequency || "",
                            isManual: !medication.medicationId,
                          })),
                        }}
                        onSuccess={() => setIsEditPrescriptionModalOpen(false)}
                        onCancel={() => setIsEditPrescriptionModalOpen(false)}
                      />
                    </Modal>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className={styles.tableWarpper}>
        <div className={styles.table}>
          <div className={styles.tableTopBarWrapper}>
            <div className={`${styles.searchWrapper} search_bar`}>
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                disabled={isLoading}
              />
              <img src={srcicon} alt="" />
            </div>
            <SortByButton
              selectedItem={sorting}
              setSelectedItem={setSorting}
              disabled={isLoading}
            />
          </div>
          <div className={styles.tableHeader}>
            <p id={`${tableName}-table-date-prescribed`}>Date prescribed</p>
            <p id={`${tableName}-table-doctor`}>Doctor</p>
            <p id={`${tableName}-table-drugs-name`}>Drugs prescribed</p>
          </div>
          {renderTableContent()}
        </div>
      </div>
    </>
  );
};
