import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";
import { useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";

export const useUpdateAppointmentStatus = (onSuccess) => {
  const queryClient = useQueryClient();

  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error saving medication: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useMutation({
    mutationFn: async ({ id, status }) => {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.post(
        `${tenantVal}/appointment/id/${id}/updatestatus/${status}`
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("Data saved successfully!");
      queryClient.invalidateQueries([""]);
      onSuccess?.();
    },
    onError: handleNetworkError,
  });
};
