import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useActiveConsultationReferral = (consultationId) => {
  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error fetching referrals: ${error.message}`)
  );
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["activeConsultationReferral", tenantVal, consultationId],
    queryFn: async () => {
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/patientreferral/getbyconsultationid/${consultationId}`
      );
      return response.data;
    },
    enabled: !!consultationId,
    onError: handleNetworkError,
  });
};
