import { toast } from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import deleteIcon from "../../../../../assets/images/trash-red.svg";
import editIcon from "../../../../../assets/images/edit-green-pencil.svg";
import downloadIcon from "../../../../../assets/images/download-green.svg";
import styles from "./VaccineDetails.module.css";
import { createAxiosInstance } from "../../../../../api/axiosConfig";
import moment from "moment";

export const VaccineDetails = ({
  vaccine,
  onOpenEditClick,
  closeModal,
  tenantVal,
}) => {
  const queryClient = useQueryClient();

  const { mutate: deletePregnancy } = useMutation({
    mutationFn: async () => {
      const axiosInstance = createAxiosInstance();
      await axiosInstance.delete(
        `${tenantVal}/patientimmunisation/delete/${vaccine.id}`
      );
    },
    onSuccess: () => {
      toast("Vaccine has been deleted.");
      closeModal();
      queryClient.invalidateQueries(["vaccines"]);
    },
    onError: () => {
      toast.error("Oups, something went wrong!");
    },
  });

const formatDate = (dateArray) => {
  if (!dateArray || dateArray.length !== 3) return "-";
  return moment(
    new Date(dateArray.at(0), dateArray.at(1) - 1, dateArray.at(2))
  ).format("DD-MM-YYYY");
};
  return (
    <>
      <div className={styles.vaccineDetailsWrapper}>
        <div className={styles.vaccineDetailsButtonRow}>
          <button className={styles.deleteButton} onClick={deletePregnancy}>
            Delete
            <img src={deleteIcon} alt="delete" />
          </button>
          <div>
            <button onClick={onOpenEditClick}>
              Edit <img src={editIcon} alt="edit" />
            </button>
            <button>
              Download <img src={downloadIcon} alt="download" />
            </button>
          </div>
        </div>
        <div className={styles.vaccineDetailsInfoGrid}>
          <div>
            <h4>Vaccine Name</h4>
            <p>{vaccine.vaccineName || "-"}</p>
          </div>
          <div>
            <h4>Reaction</h4>
            <p>{vaccine.reaction || "-"}</p>
          </div>
          <div>
            <h4>Batch No.</h4>
            <p>{vaccine.batchNo || "-"}</p>
          </div>
          <div>
            <h4>Administartion Method</h4>
            <p>{vaccine.administrationMethod || "-"}</p>
          </div>
          <div>
            <h4>Menufacturer</h4>
            <p>{vaccine.manufacturer || "-"}</p>
          </div>
          <div>
            <h4>Expiry Date</h4>
            <p>{formatDate(vaccine.expiryDate) || "-"}</p>
          </div>
          <div>
            <h4>Site Given</h4>
            <p>{vaccine.siteGiven || "-"}</p>
          </div>
          <div>
            <h4>Reaction</h4>
            <p>{vaccine.reaction || "-"}</p>
          </div>
          <div>
            <h4>Reaction Date</h4>
            <p>{formatDate(vaccine.reactionDate) || "-"}</p>
          </div>
          <div>
            <h4>Dose ML</h4>
            <p>{vaccine.doseMl || "-"}</p>
          </div>
          <div>
            <h4>Notes</h4>
            <p>{vaccine.notes || "-"}</p>
          </div>
        </div>
      </div>
    </>
  );
};
