import { Table } from "./Table/Table";
import styles from "../../PatientOverviewModal.module.css";
import { useAllergies } from "../../../../../../hooks";

export const Allergies = () => {
  const patientId = localStorage.getItem("kinesin-patientId");
  const { data: all, isPending: isLoading } = useAllergies(patientId);

  const [allergies, intolerances] = (all || []).reduce(
    (acc, item) => {
      if (item.allergyType === "ALLERGY") {
          item.drugAllergyVal = item.drugAllergy ? 'Yes' : '-';
          item.seriousVal = item.serious ? 'Yes' : '-'
        acc[0].push(item);
      } else {
        acc[1].push(item);
      }
      return acc;
    },
    [[], []]
  );

    const columns = [
        { header: "Name", field: "name" },
        { header: "Symptoms", field: "allergyReaction" },
        { header: "Drug allergy", field: "drugAllergyVal" },
        { header: "Serious", field: "seriousVal" },
    ];

  return (
      <div>
          <h3 className={styles.tableTitle}>Allergies</h3>
          <Table
              columns={columns}
              items={allergies || []}
              tableName="allergies-allergies"
              isLoading={isLoading}
          />
          <h3 className={styles.tableTitle}>Intolerance</h3>
          <Table
              items={intolerances || []}
              tableName="allergies-intolerance"
              isLoading={isLoading}
          />

      </div>
  );
};
