import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import "../../../assets/css/patient.css";
import message from "../../../assets/images/message.svg";
import srcicon from "../../../assets/images/srcicon.svg";
import styles from "./Patient.module.css";
import { Pagination } from "@mui/material";
import {
  addPatientFullName,
  addPatientId,
  clearPatientId,
  clearPreviousPageForEditPatient,
} from "../../../redux/patient/PatientSlice";
import toast, { Toaster } from "react-hot-toast";
import { Empty, Table } from "antd";
import Modal from "../../../components/helpers/Modal/Modal";
import SmsModal2 from "../../../components/Modal/SmsModal2";
import { usePaginatedPatients } from "../../../hooks/usePaginatedPatients";

const AlliedPatient = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state } = location;

  let tenantVal = useSelector((state) => state.auth.user?.tenant);
  if (!tenantVal) tenantVal = localStorage.getItem("kinesin-tenant");
  const userType = useSelector((state) => state.auth.user?.type || "");

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSmsModalOpen, setIsSmsModalOpen] = useState(false);
  const [patient, setPatient] = useState({});

  const { data, isLoading } = usePaginatedPatients(
    tenantVal,
    currentPage,
    searchQuery
  );

  const paginatedPatients = data?.data || [];
  const totalRecords = data?.totalRecords || 0;

  const start = (currentPage - 1) * 10 + 1;
  const end = Math.min(currentPage * 10, totalRecords);

  useEffect(() => {
    if (state && state.searchQuery) {
      setSearchQuery(state.searchQuery);
    }
    if (state && state.showToast) {
      toast.success("Patient updated successfully");
    }
  }, [state]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearchQuery(query);
      setCurrentPage(1);
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    debouncedSearch(event.target.value);
  };

  const toggleSmsModal = (patient) => {
    setPatient(patient);
    setIsSmsModalOpen(!isSmsModalOpen);
  };

  const toggleSmsClose = () => {
    setIsSmsModalOpen(false);
  };

  const goToPatientDetails = (id, patientFullName) => {
    dispatch(addPatientId(id));
    localStorage.setItem("kinesin-patientId", id);
    dispatch(addPatientFullName(patientFullName));
    if (userType === "GP") {
      navigate("/patient-detail-gp");
    } else {
      navigate("/patient-detail");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text, record) => (
        <button
          className={styles.patientName}
          onClick={(e) => {
            e.preventDefault();
            goToPatientDetails(record.key, record.patientname);
          }}
        >
          {text}
        </button>
      ),
    },
    {
      title: "DOB",
      dataIndex: "dob",
      key: "dob",
    },
    {
      title: "Address Line 1",
      dataIndex: "addressline",
      key: "addressline",
    },
    {
      title: "Sex",
      dataIndex: "sex",
      key: "sex",
    },
    {
      title: "Mobile phone",
      dataIndex: "telephone",
      key: "telephone",
    },
    {
      title: "Doctor",
      dataIndex: "mainDoctor",
      key: "mainDoctor",
    },
    {
      title: "Last Visit",
      dataIndex: "lastvisit",
      key: "lastvisit",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
    },
  ];

  const dataSource = paginatedPatients.map((element) => ({
    key: element.id,
    patientname: `${element.firstName} ${element.surname || ""}`,
    name: (
      <>
        {element.firstName} {element.surname}
        <span style={{ color: "#ef0064" }}>{element.died ? " (D)" : ""}</span>
      </>
    ),
    dob: element.dateOfBirth,
    addressline: element.address?.addressLine1 || "",
    sex: element.gender,
    telephone: element.homePhone,
    mainDoctor: element.doctorDetails
      ? `${element.doctorDetails.firstName} ${element.doctorDetails.lastName}`
      : "Not set",
    lastvisit: element.lastVisit,
    action: (
      <div className="d-flex align-items-center action-wrap">
        <button
          id="my-tooltip-anchor"
          type="button"
          className="deletebtn"
          onClick={() => toggleSmsModal(element)}
        >
          <span className="smsicon">
            <img src={message} alt="" />
          </span>
        </button>
        <button
          className="view_file_btn"
          onClick={() => goToPatientDetails(element.id, element.firstName)}
        >
          Edit
        </button>
      </div>
    ),
  }));

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="dashBcontbody">
        <div className="patientsheading">
          <div className="patientsearchbar flex-wrap justify-content-between">
            <div
              className="d-flex align-items-center allied-documents-wrap"
              style={{ float: "right" }}
            >
              <div className="search_bar">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search patient here..."
                  onChange={handleSearchChange}
                />
                <img src={srcicon} alt="" />
              </div>
              <div className="btnxscenter">
                <button
                  className="custom_btn addform_btn"
                  type="submit"
                  onClick={() => {
                    dispatch(clearPatientId());
                    dispatch(clearPreviousPageForEditPatient());
                    localStorage.removeItem("kinesin-patientId");
                    navigate("/add_patient");
                  }}
                >
                  + Add Patients
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="custom-table-wrapper patients-table">
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            locale={{
              emptyText: isLoading ? (
                <Empty description="Loading..." />
              ) : (
                <Empty description="No Records Found" />
              ),
            }}
          />
          {dataSource?.length && dataSource.length > 0 ? (
            <div className={styles.paginationWrapper}>
              <p className={styles.resultsInfo}>
                {start}-{end} of {totalRecords} results
              </p>
              <Pagination
                count={Math.ceil(data?.totalRecords / data?.rowsPerPage)}
                onChange={(_event, value) => {
                  setCurrentPage(value);
                }}
                page={currentPage}
              />
            </div>
          ) : null}
        </div>

        {isSmsModalOpen && (
          <Modal onClose={toggleSmsClose} title={"Send sms"} hideCancel={true}>
            <SmsModal2
              tenantVal={tenantVal}
              patient={patient}
              onClose={toggleSmsModal}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default AlliedPatient;
