import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserIcon } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";

import information from "../../../../assets/images/information.svg";
import tick from "../../../../assets/images/tick.svg";
import Delete from "../../../../assets/images/delete.svg";
import {
  addPatientFullName,
  addPatientId,
} from "../../../../redux/patient/PatientSlice";

import Pagination from "../../../../components/Pagination";
import { DisplayTaskModal } from "../../../../components/Modal/DisplayTaskModal/DisplayTaskModal";
import { AddTaskModal } from "../../../../components/AddTaskModal/AddTaskModal";
import { useTasks } from "../../../../hooks/useTasks";
import { useDeleteTask } from "../../../../hooks/useDeleteTask";
import { DeleteModal } from "../../../../components/Modal/DeleteModal";

export const Tasks = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.auth.user?.type || "");

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { data: tasks, isLoading } = useTasks();
  const { mutate: deleteTask } = useDeleteTask();

  const itemsPerPage = 25;
  const totalPages = Math.ceil((tasks?.length || 0) / itemsPerPage);
  const currentItems =
    tasks?.slice(
      currentPage * itemsPerPage - itemsPerPage,
      currentPage * itemsPerPage
    ) || [];

  const handleDeleteTask = (id) => {
    setSelectedTaskId(id);
    setShowModal(false);
  };

  const goToPatientDetails = (id, patientFullName) => {
    dispatch(addPatientId(id));
    localStorage.setItem("kinesin-patientId", id);
    dispatch(addPatientFullName(patientFullName));
    if (userType === "GP") {
      navigate("/patient-detail-gp");
    } else {
      navigate("/patient-detail");
    }
  };

  const handleDisplayTask = async (id) => {
    const selectedTask = tasks.find((item) => item.id === id);

    if (selectedTask) {
      setSelectedTaskId(selectedTask.id);
      setShowModal(true);
    }
  };

  return (
    <>
      <div className="informationSummary documents_box">
        <div className="summary_whitebox">
          <div className="summary_heading">
            <div className="sum_headicon">
              <img src={information} alt="" />
            </div>
            <h2>General Tasks</h2>
            <div className="addtaskbtnwrapper">
              <AddTaskModal />
            </div>
          </div>
          <div className="summary_boddy">
            <div className="summary_colorbg">
              <ul>
                {isLoading ? null : currentItems?.length === 0 ? (
                  <p className="no-record-text">No Records Found</p>
                ) : (
                  currentItems?.map((item, i) => {
                    return (
                      <li key={item.id}>
                        <p onClick={() => handleDisplayTask(item.id)}>
                          <span className="number-wrap">{i + 1}</span>{" "}
                          <span className="text-ellipsis">
                            {" "}
                            {item.patient?.firstName} {item.patient?.surname} :-{" "}
                            {item.title}
                          </span>
                        </p>
                        <div className="delbtnlist">
                          <button
                            aria-label="View user"
                            onClick={(e) => {
                              goToPatientDetails(
                                item.patient.id,
                                item.patient.firstName +
                                  " " +
                                  item.patient.surname
                              );
                            }}
                          >
                            <UserIcon
                              className="userIcon"
                              color="#8d8da3"
                              width={20}
                              height={20}
                            />
                          </button>
                          <button
                            onClick={() => handleDisplayTask(item.id)}
                            className={
                              item.status === "Complete" ? "green-tick" : ""
                            }
                          >
                            <img src={tick} alt="" />
                          </button>
                          <button
                            data-bs-toggle="modal"
                            data-bs-target="#myModal"
                            onClick={() => handleDeleteTask(item.id)}
                          >
                            <img src={Delete} alt="" />
                          </button>
                        </div>
                      </li>
                    );
                  })
                )}
              </ul>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>

      <DeleteModal
        deleteService={() => {
          deleteTask(selectedTaskId);
        }}
        context="task"
        itemName="task"
      />
      <DisplayTaskModal
        closeModal={() => {
          setShowModal(false);
        }}
        task={tasks?.find((item) => item.id === selectedTaskId)}
        isOpen={showModal}
      />
    </>
  );
};
