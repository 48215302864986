import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useMedicalHistory = (patientId) => {
  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error fetching medical history: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["medicalHistory", patientId, tenantVal],
    queryFn: async () => {
      if (!patientId) return null;
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/patientmedicalhistory/getbypatientid/${patientId}`
      );
      return response.data;
    },
    enabled: !!patientId,
    onError: handleNetworkError,
  });
};
