import Plus from "../../../../../../assets/images/transparrent-plus.svg";
import CardWithTitle from "../../../../../../components/helpers/CardWithTitle/CardWithTitle";
import React, { useState } from "react";
import { MedicalHistory } from "./MedicalHistory";
import { usePatientData } from "../../../../../../hooks";
import EmptyState from "../../../../../../assets/images/medical-history-empty-state.svg";

import styles from "./MedicalHistoryCard.module.css";
import { Image } from "react-bootstrap";
import Modal from "../../../../../helpers/Modal/Modal";
import { AddMedicalHistoryForm } from "./Form/AddMedicalHistoryForm";

export const MedicalHistoryCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: patient } = usePatientData();

  return (
    <>
      <CardWithTitle
        title={"Medical History"}
        width={"100%"}
        marginBottom={"34px"}
        buttonTitle={"Add new"}
        icon={Plus}
        className={"addNewBtn"}
        onButtonClick={() => setIsModalOpen(true)}
      >
        <MedicalHistory
          patient={patient}
          emptyState={
            <div className={styles.emptyState}>
              <Image src={EmptyState} alt="category" />
              <div>
                <p className={styles.emptyStateTitle}>No medical history</p>
                <p className={styles.emptyStateText}>
                  Once a doctor add medical history <br />
                  it will appear here.
                </p>
              </div>
            </div>
          }
        />
      </CardWithTitle>
      {isModalOpen && (
        <Modal
          width="600px"
          title="Add Medical History"
          onClose={() => setIsModalOpen(false)}
          position="center"
          hideCancel
        >
          <AddMedicalHistoryForm closeModal={() => setIsModalOpen(false)} />
        </Modal>
      )}
    </>
  );
};
