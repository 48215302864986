import moment from "moment";
import style from "./PregnancyPostNatalConsultationContent.module.css";

export const PregnancyPostNatalConsultationContent = ({ consultation }) => {
  return (
    <div>
      <div>
        <h3 className={style.pregnancyPostNatalConsultationLabel}>Doctor</h3>
        <p className={style.pregnancyPostNatalConsultationContent}>
          {consultation.doctor}
        </p>
      </div>
      <h2 className={style.pregnancyPostNatalConsultationSectionTitle}>
        Subject
      </h2>
      <div className={style.pregnancyPostNatalConsultationTwoColumnSection}>
        <div>
          <h3 className={style.pregnancyPostNatalConsultationLabel}>Born at</h3>
          <p className={style.pregnancyPostNatalConsultationContent}>
            {moment(consultation.subject.bornAt).format("DD.MM.YYYY")}
          </p>
        </div>
        <div>
          <h3 className={style.pregnancyPostNatalConsultationLabel}>
            Delivery method
          </h3>
          <p className={style.pregnancyPostNatalConsultationContent}>
            {consultation.subject.deliveryMethod.replace(/-/g, " ")}
          </p>
        </div>
        <div>
          <h3 className={style.pregnancyPostNatalConsultationLabel}>
            Weight at birth
          </h3>
          <p className={style.pregnancyPostNatalConsultationContent}>
            {consultation.subject.weightAtBirth}
          </p>
        </div>
        <div>
          <h3 className={style.pregnancyPostNatalConsultationLabel}>Jaudice</h3>
          <p className={style.pregnancyPostNatalConsultationContent}>
            {consultation.subject.jaudice}
          </p>
        </div>
      </div>
      <div className={style.pregnancyPostNatalConsultationSection}>
        <h3 className={style.pregnancyPostNatalConsultationLabel}>Feeding</h3>
        <p className={style.pregnancyPostNatalConsultationContent}>
          {consultation.subject.feeding}
        </p>
      </div>
      <div className={style.pregnancyPostNatalConsultationSection}>
        <h3 className={style.pregnancyPostNatalConsultationLabel}>
          Issues of note
        </h3>
        <p className={style.pregnancyPostNatalConsultationContent}>
          {consultation.subject.issuesOfNote}
        </p>
      </div>
      <div>
        <h3 className={style.pregnancyPostNatalConsultationLabel}>
          PHN Visit:
        </h3>
        <p className={style.pregnancyPostNatalConsultationContent}>
          {consultation.subject.phnVisit}
        </p>
      </div>
      <h2 className={style.pregnancyPostNatalConsultationSectionTitle}>
        Objective
      </h2>
      <div className={style.pregnancyPostNatalConsultationTwoColumnSection}>
        <div>
          <h3 className={style.pregnancyPostNatalConsultationLabel}>Weight</h3>
          <p className={style.pregnancyPostNatalConsultationContent}>
            {consultation.objective.weight}
          </p>
        </div>
        <div>
          <h3 className={style.pregnancyPostNatalConsultationLabel}>Length</h3>
          <p className={style.pregnancyPostNatalConsultationContent}>
            {consultation.objective.length}
          </p>
        </div>
        <div>
          <h3 className={style.pregnancyPostNatalConsultationLabel}>
            Head circumference
          </h3>
          <p className={style.pregnancyPostNatalConsultationContent}>
            {consultation.objective.headCircumference}
          </p>
        </div>
        <div>
          <h3 className={style.pregnancyPostNatalConsultationLabel}>CRT</h3>
          <p className={style.pregnancyPostNatalConsultationContent}>
            {consultation.objective.CRT}
          </p>
        </div>
      </div>
      <div className={style.pregnancyPostNatalConsultationSection}>
        <h3 className={style.pregnancyPostNatalConsultationLabel}>Umbilicus</h3>
        <p className={style.pregnancyPostNatalConsultationContent}>
          {consultation.objective.umbilicus}
        </p>
      </div>
      <div className={style.pregnancyPostNatalConsultationSection}>
        <h3 className={style.pregnancyPostNatalConsultationLabel}>
          Barlow's and Ortalani's tests
        </h3>
        <p className={style.pregnancyPostNatalConsultationContent}>
          {consultation.objective.barlowsAndOrtalanisTests}
        </p>
      </div>
      <div className={style.pregnancyPostNatalConsultationSection}>
        <h3 className={style.pregnancyPostNatalConsultationLabel}>Heart</h3>
        <p className={style.pregnancyPostNatalConsultationContent}>
          {consultation.objective.heart}
        </p>
      </div>
      <div className={style.pregnancyPostNatalConsultationSection}>
        <h3 className={style.pregnancyPostNatalConsultationLabel}>
          Fonantelles
        </h3>
        <p className={style.pregnancyPostNatalConsultationContent}>
          {consultation.objective.fonantelles}
        </p>
      </div>
      <div className={style.pregnancyPostNatalConsultationSection}>
        <h3 className={style.pregnancyPostNatalConsultationLabel}>
          Buccal mucosa
        </h3>
        <p className={style.pregnancyPostNatalConsultationContent}>
          {consultation.objective.buccalMucosa}
        </p>
      </div>
      <div
        className={style.pregnancyPostNatalConsultationSectionWithBottomMargin}
      >
        <h3 className={style.pregnancyPostNatalConsultationLabel}>Feet</h3>
        <p className={style.pregnancyPostNatalConsultationContent}>
          {consultation.objective.feet}
        </p>
      </div>
      <div className={style.pregnancyPostNatalConsultationSection}>
        <h3 className={style.pregnancyPostNatalConsultationLabel}>
          Impression
        </h3>
        <p className={style.pregnancyPostNatalConsultationContent}>
          {consultation.objective.impression}
        </p>
      </div>
      <div>
        <h3 className={style.pregnancyPostNatalConsultationLabel}>Plan</h3>
        <p className={style.pregnancyPostNatalConsultationContent}>
          {consultation.objective.plan}
        </p>
      </div>
      <h2 className={style.pregnancyPostNatalConsultationSectionTitle}>
        Mother
      </h2>
      <div className={style.pregnancyPostNatalConsultationTwoColumnSection}>
        <div>
          <h3 className={style.pregnancyPostNatalConsultationLabel}>Gravida</h3>
          <p className={style.pregnancyPostNatalConsultationContent}>
            {consultation.mother.gravida}
          </p>
        </div>
        <div>
          <h3 className={style.pregnancyPostNatalConsultationLabel}>Para</h3>
          <p className={style.pregnancyPostNatalConsultationContent}>
            {consultation.mother.para}
          </p>
        </div>
      </div>
      <div className={style.pregnancyPostNatalConsultationSection}>
        <h3 className={style.pregnancyPostNatalConsultationLabel}>
          Issues during or since pregnancy
        </h3>
        <p className={style.pregnancyPostNatalConsultationContent}>
          {consultation.mother.issuesDuringOrSincePregnancy}
        </p>
      </div>
      <div className={style.pregnancyPostNatalConsultationSection}>
        <h3 className={style.pregnancyPostNatalConsultationLabel}>
          Post-natal mood
        </h3>
        <p className={style.pregnancyPostNatalConsultationContent}>
          {consultation.mother.postNatalMood}
        </p>
      </div>
      <div className={style.pregnancyPostNatalConsultationSection}>
        <h3 className={style.pregnancyPostNatalConsultationLabel}>Supports</h3>
        <p className={style.pregnancyPostNatalConsultationContent}>
          {consultation.mother.supports}
        </p>
      </div>
      <div className={style.pregnancyPostNatalConsultationSection}>
        <h3 className={style.pregnancyPostNatalConsultationLabel}>
          Contraception
        </h3>
        <p className={style.pregnancyPostNatalConsultationContent}>
          {consultation.mother.contraception}
        </p>
      </div>
      <div
        className={style.pregnancyPostNatalConsultationSectionWithBottomMargin}
      >
        <h3 className={style.pregnancyPostNatalConsultationLabel}>Mirena</h3>
        <p className={style.pregnancyPostNatalConsultationContent}>
          {consultation.mother.mirena}
        </p>
      </div>
    </div>
  );
};
