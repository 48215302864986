import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useCompleteTask = (onSuccess) => {
  const queryClient = useQueryClient();

  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error while completing task: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useMutation({
    mutationFn: async (taskId) => {
      const axiosInstance = await createAxiosInstance();
      await axiosInstance.post(`${tenantVal}/task/close/${taskId}`, {
        id: taskId,
        tenant: tenantVal,
      });
    },
    onSuccess: () => {
      toast.success("Task completed successfully");
      queryClient.invalidateQueries(["tasks", "closedPaginatedTasks"]);
      onSuccess?.();
    },
    onError: handleNetworkError,
  });
};
