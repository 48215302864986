import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useDeleteRelatioship = (onSuccess) => {
  const queryClient = useQueryClient();

  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error while deleting task: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useMutation({
    mutationFn: async (relationId) => {
      const axiosInstance = await createAxiosInstance();
      await axiosInstance.delete(`${tenantVal}/patientfamilymember/delete`, {
        data: {
          id: relationId,
          tenant: tenantVal,
        },
      });
    },
    onSuccess: () => {
      toast.success("Relation deleted successfully.");
      queryClient.invalidateQueries(["relationships", "relationship"]);
      onSuccess?.();
    },
    onError: handleNetworkError,
  });
};
