import { useState } from "react";
import dayjs from "dayjs";
import { Autocomplete, TextField } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import style from "./AddTaskForm.module.css";

import { FormField } from "../form/FormField/FormField";
import { useStaffMembers } from "../../hooks/useStaffMembers";
import { TaskPriority, Taskstatus } from "../../Utils/Constant";
import { useClientSearch } from "../../hooks/useClientSearch";
import { useSelector } from "react-redux";
import { useCreateTask } from "../../hooks/useCreateTask";

const formSchema = z.object({
  patientId: z.number({ message: "Please select patient." }),
  assignedTo: z.string().min(1, { message: "Please select assigned to." }),
  from: z.string().min(1, { message: "Please select from." }),
  priority: z.string().min(1, { message: "Please select priority." }),
  status: z.string().min(1, { message: "Please select status." }),
  dueDate: z.string().min(1, { message: "Please select due date." }),
  patientName: z.string().optional(),
  taskDescription: z
    .string()
    .min(1, { message: "Please provide task description." }),
});

export const AddTaskForm = ({ patient, task, closeModal }) => {
  const today = dayjs(new Date().setMinutes(0));

  let defaultPatient = {};

  if (patient) {
    defaultPatient = {
      id: patient.id,
      title: `${patient.firstName}${
        patient.surname ? " " + patient.surname : ""
      }${
        patient.dateOfBirth
          ? ` (${moment(patient.dateOfBirth).format("DD-MM-YYYY")})`
          : ""
      }`,
    };
  }

  if (task?.patient) {
    defaultPatient = {
      id: task.patient.id,
      title: `${task.patient.firstName}${
        task.patient.surname ? " " + task.patient.surname : ""
      }${
        task.patient.dateOfBirth
          ? ` (${moment(task.patient.dateOfBirth).format("DD-MM-YYYY")})`
          : ""
      }`,
    };
  }

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(defaultPatient);

  const { data: staffMembers } = useStaffMembers();
  const { data: patients } = useClientSearch(searchTerm);
  const saveTask = useCreateTask();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      patientId: task?.patientId || patient?.id || undefined,
      assignedTo: task?.assignedToStaffId.toString() || "",
      from:
        useSelector((state) => state.auth.user?.name) ||
        localStorage.getItem("kinesin-name"),
      priority:
        task?.priority?.toString() || TaskPriority.at(2).value.toString(),
      status: task?.status?.toString() || Taskstatus.at(0).value.toString(),
      dueDate: task?.dueDate
        ? moment(task?.dueDate, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY")
        : moment(new Date(today)).format("DD-MM-YYYY"),
      taskDescription: task?.title || "",
    },
  });

  const handleFormSubmit = async (values) => {
    await saveTask.mutate(
      {
        id: task?.id,
        dueDate: moment(values.dueDate, "DD-MM-YYYY").format(
          "YYYY-MM-DD HH:mm"
        ),
        patientId: values.patientId,
        assignedToStaffId: values.assignedTo,
        priority: Number(values.priority),
        status: values.status,
        title: values.taskDescription,
        tenant: localStorage.getItem("kinesin-tenant"),
      },
      {
        onSuccess: () => {
          closeModal?.(false);
        },
      }
    );
  };

  return (
    <FormProvider {...form}>
      <form
        className={style.addTaskForm}
        onSubmit={form.handleSubmit(handleFormSubmit)}
      >
        <div className={style.inputGroupGrid}>
          <div className="autocompleteWarpper">
            <FormField name="patientId">
              <div className={style.addTaskFormAutocompleteWrapper}>
                <label className={style.addTaskFormLabel}>Patient name</label>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  className={style.addTaskFormInput}
                  value={selectedPatient}
                  options={
                    patients?.map((patient) => ({
                      id: patient.id,
                      title: `${patient.firstName}${
                        patient.surname ? " " + patient.surname : ""
                      }${
                        patient.dateOfBirth
                          ? ` (${moment(patient.dateOfBirth).format(
                              "DD-MM-YYYY"
                            )})`
                          : ""
                      }`,
                    })) || []
                  }
                  getOptionLabel={(option) =>
                    option && option.title ? option.title : ""
                  }
                  onInputChange={(_event, value) => {
                    setSearchTerm(value);
                  }}
                  onChange={(_event, newValue) => {
                    setSelectedPatient(newValue);
                    form.setValue("patientId", newValue?.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={style.addTaskFormAutocompleteInput}
                      placeholder="Search here..."
                    />
                  )}
                />
              </div>
            </FormField>
          </div>
          <FormField name="assignedTo">
            <label className={style.addTaskFormLabel}>Assigned To</label>
            <select
              as="select"
              className="form-select form-control custom-select"
              {...form.register("assignedTo")}
            >
              <option value="">Select</option>
              {(staffMembers || [])?.map((staff) => (
                <option value={staff.id} key={staff.id}>
                  {staff.firstName} {staff.lastName}
                </option>
              ))}
            </select>
          </FormField>
        </div>
        <div className={style.inputGroupGrid}>
          <FormField name="from">
            <label className={style.addTaskFormLabel}>From</label>
            <input
              className="form-control"
              {...form.register("from")}
              disabled
            />
          </FormField>
          <FormField name="priority">
            <label className={style.addTaskFormLabel}>Priority</label>
            <select
              as="select"
              className="form-select form-control custom-select"
              {...form.register("priority")}
            >
              {TaskPriority.map((priority, id) => (
                <option value={priority.value} key={id}>
                  {priority.name}
                </option>
              ))}
            </select>
          </FormField>
        </div>
        <div className={style.inputGroupGrid}>
          <FormField name="dueDate">
            <label className={style.addTaskFormLabel}>Due Date</label>
            <div className={style.addPregnancyDatePickerWrapper}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD-MM-YYYY"
                  name="dueDate"
                  value={
                    task?.dueDate
                      ? dayjs(task.dueDate, "YYYY-DD-MM HH:mm")
                      : today
                  }
                  sx={{
                    width: "100%",
                    height: "36px",
                  }}
                  onChange={(newValue) => {
                    form.setValue(
                      "dueDate",
                      moment(new Date(dayjs(newValue))).format("DD-MM-YYYY")
                    );
                  }}
                />
              </LocalizationProvider>
            </div>
          </FormField>
          <FormField name="status">
            <label className={style.addTaskFormLabel}>Status</label>
            <select
              as="select"
              className="form-select form-control custom-select"
              {...form.register("status")}
            >
              {Taskstatus.map((status, id) => (
                <option value={status.value} key={id}>
                  {status.name}
                </option>
              ))}
            </select>
          </FormField>
        </div>
        <FormField name="taskDescription">
          <label className={style.addTaskFormLabel}>Task</label>
          <textarea
            className="form-control textareaform-control"
            style={{ minHeight: "140px" }}
            {...form.register("taskDescription")}
          />
        </FormField>
        <div className={style.bottomSection}>
          <button className={style.sendButton} type="submit">
            Save
          </button>
        </div>
      </form>
    </FormProvider>
  );
};
