import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useStaffInfo = () => {
  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error fetching staff info: ${error.message}`)
  );

  return useQuery({
    queryKey: ["staffInfo"],
    queryFn: async () => {
      const axiosInstance = await createAxiosInstance(true);
      const response = await axiosInstance.get(`/api/v1/whoami`);
      return response.data;
    },
    onError: handleNetworkError,
    staleTime: 1000,
  });
};
