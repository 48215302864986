import moment from "moment/moment";

export const fetchColumns = (reportType) => {
    switch (reportType) {
        case "immunisations":
            return getImmunisationCols();
        case "prescriptions":
            return getPatientCols();
        case "investigations":
            return getPatientCols();
        case "letters":
            return getPatientCols();
        case "accounting":
            return getPatientCols();
        case "appointments":
            return getPatientCols();
        case "medico-legal":
            return getPatientCols();
        case "audit-log":
            return getAuditCols();
        default:
            return getPatientCols();
    }
}


const getAuditCols = () => {
    return [
        {
            accessorKey: "accessDateTime",
            header: "Date accessed",
            cell: (info) => {
                const date = info.getValue();

                if (!date) return "N/A";

                return moment(date).format("DD/MM/YYYY");
            },
            size: 135,
        },
        {
            accessorFn: (row) => `${row.firstName || ""} ${row.lastName || ""}`,
            header: "Patient name",
            size: 150,
        },
        {
            accessorKey: "dateOfBirth",
            header: "Date of Birth",
            cell: (info) => moment(info.getValue()).format("DD/MM/YYYY"),
            size: 120,
        },
        {
            accessorKey: "gender",
            header: "Gender",
            size: 70,
            cell: (info) =>
                info
                    .getValue()
                    ?.toLowerCase()
                    .replace(/^\w/, (c) => c.toUpperCase()) || "N/A",
        },
        {
            accessorFn: (row) => {
                if (!row.staffFirstName && !row.staffLastName) return "N/A";

                return `${row.staffFirstName || ""} ${row.staffLastName || ""}`;
            },
            header: "Accessed by",
            size: 150,
        },
        {
            accessorKey: "accessReason",
            header: "Access reason",
            cell: (info) => info.getValue() || "-",
            size: 90,
        },
        {
            accessorKey: "dailyInteractions",
            header: "Daily access count",
            cell: (info) => info.getValue() || "-",
            size: 90,
        }
    ];
}

const getPatientCols = () => {
    return [
        {
            accessorKey: "dateOfConsultation",
            header: "Consultation Date",
            cell: (info) => {
                const date = info.getValue();

                if (!date) return "N/A";

                return moment(date).format("DD/MM/YYYY");
            },
            size: 135,
        },
        {
            accessorFn: (row) => `${row.firstName || ""} ${row.lastName || ""}`,
            header: "Name",
            size: 150,
        },
        {
            accessorKey: "dateOfBirth",
            header: "Date of Birth",
            cell: (info) => moment(info.getValue()).format("DD/MM/YYYY"),
            size: 120,
        },
        {
            accessorKey: "gender",
            header: "Gender",
            size: 70,
            cell: (info) =>
                info
                    .getValue()
                    ?.toLowerCase()
                    .replace(/^\w/, (c) => c.toUpperCase()) || "N/A",
        },
        {
            accessorFn: (row) => {
                if (!row.doctorFirstName && !row.doctorSecondName) return "N/A";

                return `${row.doctorFirstName || ""} ${row.doctorSecondName || ""}`;
            },
            header: "Doctor Name",
            size: 150,
        },
        {
            accessorKey: "prescription",
            header: "Prescription",
            cell: (info) => info.getValue() || "-",
            size: 90,
        },
        {
            accessorKey: "investigation",
            header: "Investigation",
            cell: (info) => info.getValue() || "-",
            size: 100,
        },
        {
            accessorKey: "referral",
            header: "Referral",
            cell: (info) => info.getValue() || "-",
            size: 70,
        },
        {
            accessorKey: "treatments",
            header: "Treatment",
            cell: (info) => info.getValue() || "-",
            size: 85,
        }
    ];
}


const getImmunisationCols = () => {
    return [
        {
            accessorKey: "dateAdministered",
            header: "Administered Date",
            cell: (info) => {
                const date = info.getValue();

                if (!date) return "N/A";

                return moment(date).format("DD/MM/YYYY");
            },
            size: 135,
        },
        {
            accessorFn: (row) => `${row.firstName || ""} ${row.lastName || ""}`,
            header: "Name",
            size: 150,
        },
        {
            accessorKey: "dateOfBirth",
            header: "Date of Birth",
            cell: (info) => moment(info.getValue()).format("DD/MM/YYYY"),
            size: 120,
        },
        {
            accessorKey: "gender",
            header: "Gender",
            size: 70,
            cell: (info) =>
                info
                    .getValue()
                    ?.toLowerCase()
                    .replace(/^\w/, (c) => c.toUpperCase()) || "N/A",
        },
        {
            accessorKey: "vaccineName",
            header: "Vaccine Name",
            cell: (info) => info.getValue() || "-",
            size: 90,
        },
        {
            accessorKey: "batchNo",
            header: "Batch No",
            cell: (info) => info.getValue() || "-",
            size: 90,
        },
        {
            accessorKey: "reaction",
            header: "Reaction",
            cell: (info) => info.getValue() || "-",
            size: 90,
        },
    ];
}