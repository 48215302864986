// import { useState } from "react";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import moment from "moment";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useRef } from "react";
import { debounce } from "lodash";

import style from "./AddReferralForm.module.css";

import { FormField } from "../form/FormField/FormField";
// import CustomCheckbox from "../helpers/CustomCheckbox/CustomCheckbox";
import RichEditor from "../RichEditor/RichEditor";
import { useCreateReferral } from "../../hooks/useCreateReferral";
import { useSelector } from "react-redux";
import { createAxiosInstance } from "../../api/axiosConfig";

const formSchema = z.object({
  saveAsTemplate: z.boolean(),
  referral: z
    .string()
    .min(1, { message: "Please provide content for referral." }),
  title: z.string().min(1, { message: "Please provide referral title." }),
  type: z.string().min(1, { message: "Please select referral type." }),
  notifyAt: z.string(),
});

export const AddReferralForm = ({
  patient,
  onSuccess,
  // onCancel,
  consultationId,
  initialData = {},
  autoSave,
}) => {
  const savedId = useRef(null);
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");
  const today = dayjs(new Date().setMinutes(0));
  // const [notify, setNotify] = useState(false);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      saveAsTemplate: false,
      referral: initialData?.text || "",
      title: initialData?.name || "",
      type: initialData?.referralType || "type-1",
      notifyAt: moment(new Date(today)).format("DD-MM-YYYY"),
    },
  });

  const createReferral = useCreateReferral();

  const downloadReferral = async (id, tenantVal, patientId) => {
    try {
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/patientreferral/download/${id}/patient/${patientId}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `prescription_${id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      //toast.error
    }
  };

  const handleFormSubmit = async (values) => {
    try {
      const res = await createReferral.mutateAsync({
        consultationId,
        id: initialData?.id || savedId.current,
        patientId: patient.id,
        tenant: tenantVal,
        text: values.referral,
        name: values.title,
        referralType: values.type,
      });
      onSuccess?.();
      savedId.current = res.id;
    } catch (error) {
      console.error("Submission error:", error);
    }
  };

  const debouncedSave = debounce((values) => {
    handleFormSubmit(values);
  }, 1000);

  useEffect(() => {
    if (autoSave) {
      const subscription = form.watch((values) => {
        debouncedSave(values);
      });
      return () => subscription.unsubscribe();
    }
  }, [autoSave, form, debouncedSave]);

  return (
    <FormProvider {...form}>
      <form
        className={style.addReferralForm}
        onSubmit={form.handleSubmit(handleFormSubmit)}
      >
        {/* <div className={style.templateSection}>
          <p className={style.addReferralFormLabel}>Templates (optional)</p>
          <div className={style.inputGroupGrid}>
            <button className={style.openModalButton}>Select template</button>
          </div>
        </div> */}
        <div className={style.inputGroupGrid}>
          <FormField name="title">
            <label className={style.addReferralFormLabel}>Title</label>
            <div className={style.cmInput}>
              <input className="form-control" {...form.register("title")} />
            </div>
          </FormField>
          <div>
            <label className={style.addReferralFormLabel}>Patient</label>
            <input
              type="text"
              className="form-control"
              value={
                patient &&
                `${patient.firstName} ${patient.surname} (DOB - ${moment(
                  new Date(patient.dateOfBirth)
                ).format("DD/MM/YYYY")})`
              }
              disabled
            />
          </div>
        </div>

        <FormField name="referral">
          <label className={style.addReferralFormLabel}>Referral</label>
          <RichEditor
            editorValue={initialData?.text || ""}
            onChange={(e) => {
              form.setValue("referral", e);
            }}
          ></RichEditor>
        </FormField>

        <div className={style.bottomSection}>
          <label className={style.formCheckboxLabel}>
            {/* <CustomCheckbox {...form.register("saveAsTemplate")} />
            Save as template */}
          </label>
          <div>
            {!autoSave && (
              <button
                className={style.sendButton}
                onClick={form.handleSubmit(handleFormSubmit)}
              >
                Save
              </button>
            )}
            <button
              className={`${style.openModalButton} ${style.downloadButton}`}
              onClick={() =>
                downloadReferral(initialData.id, tenantVal, patient.id)
              }
            >
              Download referral
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
