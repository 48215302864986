import { Drawer } from "@mui/material";
import { useState } from "react";
import styles from "./PatientOverviewModal.module.css";
import DocIcon from "../../../../assets/images/doc.svg";
import { useNavigate } from "react-router-dom";
import { X } from "lucide-react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { MedicalHistory } from "./components/MedicalHistory/MedicalHistory";
import { Allergies } from "./components/Allergies/Allergies";
import { Medications } from "./components/Medications/Medications";
import { Consultations } from "./components/Consultations/Consultations";
import CustomButton from "../../../helpers/CustomButton/CustomButton";

export const PatientOverviewModal = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState("medicalHistory");

  return (
    <>
      <CustomButton
        buttonClassName={styles.actionBtn}
        buttonHandle={() => setOpen(true)}
        title={"Patient overview"}
        icon={DocIcon}
      />
      <Drawer open={open} onClose={() => setOpen(false)} anchor={"right"}>
        <div className={styles.drawerHeader}>
          <div className={styles.headerTitle}>
            <h2>Patient overview </h2>
            <button onClick={() => setOpen(false)}>
              <X />
            </button>
          </div>
          <div className={styles.content}>
            <ToggleButtonGroup
              value={tab}
              exclusive
              onChange={(e) => setTab(e.target.value)}
              aria-label="patient overview navigation"
              className={styles.toggleBtnGroup}
            >
              <ToggleButton
                value="medicalHistory"
                aria-label="medical history"
                className={styles.toggleBtn}
                disableRipple
              >
                Medical History
              </ToggleButton>
              <ToggleButton
                value="allergies"
                aria-label="allergies"
                className={styles.toggleBtn}
                disableRipple
              >
                Allergies
              </ToggleButton>
              <ToggleButton
                value="medications"
                aria-label="medications"
                className={styles.toggleBtn}
                disableRipple
              >
                Medications
              </ToggleButton>
              <ToggleButton
                value="consultations"
                aria-label="consultations"
                className={styles.toggleBtn}
                disableRipple
              >
                Consultations
              </ToggleButton>
            </ToggleButtonGroup>
            <div className={styles.content}>
              {tab === "medicalHistory" && <MedicalHistory />}
              {tab === "allergies" && <Allergies />}
              {tab === "medications" && <Medications />}
              {tab === "consultations" && <Consultations />}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};
