import { useState } from "react";
import styles from "./TaskTable.module.css";
import { Loader2 } from "lucide-react";
import srcicon from "../../../../../assets/images/srcicon.svg";

import { Pagination } from "@mui/material";
import { TableItem } from "./TableItem";
import { usePaginatedTasks } from "../../../../../hooks/usePaginatedTasks";

export const TaskTable = ({ goToPatientDetails }) => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const { data, isLoading } = usePaginatedTasks({ search, page });

  const totalPages = Math.ceil(data?.totalRecords / 10);
  const start = (page - 1) * 10 + 1;
  const end = Math.min(page * 10, data?.totalRecords);

  const renderTableContent = () => {
    if (isLoading) {
      return (
        <div className={styles.emptyState}>
          <Loader2 className={styles.loadingSpinner} size={24} />
          <p className={styles.emptyStateTitle}>Loading tasks...</p>
          <p className={styles.emptyStateText}>
            Please wait while we fetch your tasks.
          </p>
        </div>
      );
    }

    if (data?.data?.length <= 0) {
      return (
        <div className={styles.emptyState}>
          <p className={styles.emptyStateTitle}>No tasks found</p>
          <p className={styles.emptyStateText}>
            {search
              ? "Try adjusting your search to find what you're looking for."
              : "You don't have any tasks at this time."}
          </p>
        </div>
      );
    }

    return (
      <>
        <ul className={styles.tableItems}>
          {data?.data?.map((task) => (
            <TableItem
              task={task}
              goToPatientDetails={goToPatientDetails}
              key={task.id}
            />
          ))}
        </ul>
        <div className={styles.paginationWrapper}>
          <p className={styles.resultsInfo}>
            {start}-{end} of {data?.totalRecords} results
          </p>
          <Pagination
            count={totalPages}
            onChange={(_event, value) => {
              setPage(value);
            }}
            page={page}
          />
        </div>
      </>
    );
  };

  return (
    <div className={styles.tableWarpper}>
      <div className={styles.table}>
        <div className={styles.tableTopBarWrapper}>
          <div className={`${styles.searchWrapper} search_bar`}>
            <input
              type="search"
              className="form-control"
              placeholder="Search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setPage(1);
              }}
            />
            <img src={srcicon} alt="" />
          </div>
        </div>
        <div className={styles.tableHeader}>
          <p>Patient</p>
          <p>Task</p>
        </div>
        {renderTableContent()}
      </div>
    </div>
  );
};
