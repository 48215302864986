import React, { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import dayjs from "dayjs";

import arrowUpIcon from "../../../../assets/images/arrowUp.svg";
import arrowDownIcon from "../../../../assets/images/arrowDown.svg";

import reportsStyles from "../Reports.module.css";
import { getQueryParamsFromObject } from "../utils/getQueryParamsFromObject";

export const PrescriptionsFilters = ({ setQueryParams, setPage }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [filters, setFilters] = useState({
    patientFirstname: "",
    patientLastname: "",
    patientAge: "",
    patientGmsStatus: "",
    patientGender: "",

    drugName: "",
    drugManufacturer: "",
    prescribedStartDate: "",
    prescribedEndDate: "",
  });

  return (
    <div>
      <div className={reportsStyles.inputGrid4}>
        <div>
          <label className={reportsStyles.formLabel}>Drug name</label>
          <input
            className="form-control"
            name="drugName"
            value={filters.drugName}
            onChange={(e) => {
              setFilters((prev) => ({
                ...prev,
                drugName: e.target.value || null,
              }));
            }}
          />
        </div>
        <div>
          <label className={reportsStyles.formLabel} name="drugManufacturer">
            Manufacturer
          </label>
          <input
            className="form-control"
            value={filters.drugManufacturer}
            name="drugManufacturer"
            onChange={(e) => {
              setFilters((prev) => ({
                ...prev,
                drugManufacturer: e.target.value || null,
              }));
            }}
          />
        </div>
        <div>
          <label className={reportsStyles.formLabel}>
            Prescribed Start Date
          </label>
          <div className={reportsStyles.datePickerWrapper}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="DD-MM-YYYY"
                name="prescribedStartDate"
                sx={{
                  width: "100%",
                  height: "36px",
                }}
                onChange={(newValue) => {
                  setFilters((prev) => ({
                    ...prev,
                    prescribedStartDate:
                      moment(new Date(dayjs(newValue)))
                        .toISOString()
                        .split("T")[0] || null,
                  }));
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div>
          <label className={reportsStyles.formLabel}>Prescribed End Date</label>
          <div className={reportsStyles.datePickerWrapper}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="DD-MM-YYYY"
                name="prescribedEndDate"
                sx={{
                  width: "100%",
                  height: "36px",
                }}
                onChange={(newValue) => {
                  setFilters((prev) => ({
                    ...prev,
                    prescribedEndDate:
                      moment(new Date(dayjs(newValue)))
                        .toISOString()
                        .split("T")[0] || null,
                  }));
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>
      {isExpanded && (
        <>
          <h4 className={reportsStyles.formSubTitle}>Patient</h4>
          <div className={reportsStyles.inputGrid3}>
            <div>
              <label className={reportsStyles.formLabel}>First name</label>
              <input
                className="form-control"
                name="patientFirstname"
                value={filters.patientFirstname}
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    patientFirstname: e.target.value || null,
                  }));
                }}
              />
            </div>
            <div>
              <label className={reportsStyles.formLabel}>Last name</label>
              <input
                className="form-control"
                name="patientLastname"
                value={filters.patientLastname}
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    patientLastname: e.target.value || null,
                  }));
                }}
              />
            </div>
            <div>
              <label className={reportsStyles.formLabel}>Age</label>
              <input
                className="form-control"
                type="text"
                inputMode="numeric"
                value={filters.patientAge}
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
                name="patientAge"
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    patientAge: e.target.value || null,
                  }));
                }}
              />
            </div>
            <div>
              <label className={reportsStyles.formLabel}>GMS Status</label>
              <select
                as="select"
                className="form-select form-control custom-select"
                name="patientGmsStatus"
                value={filters.patientGmsStatus}
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    patientGmsStatus: e.target.value || null,
                  }));
                }}
              >
                <option disabled selected value="">
                  Select
                </option>
                <option value="medical-card">Medical Card</option>
                <option value="doctor-visit-card">Doctor Visit Card</option>
                <option value="private">Private</option>
              </select>
            </div>
            <div>
              <label className={reportsStyles.formLabel}>Gender</label>
              <select
                  as="select"
                  className="form-select form-control custom-select"
                  name="patientGender"
                  value={filters.patientGender}
                  onChange={(e) => {
                    setFilters((prev) => ({
                      ...prev,
                      patientGender: e.target.value || null,
                    }));
                  }}
              >
                <option disabled selected value="">
                  Select
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
        </>
      )}
      {isExpanded ? (
          <button
              className={reportsStyles.expandButon}
              onClick={() => setIsExpanded(false)}
        >
          less filters <img src={arrowUpIcon} alt="show less" />
        </button>
      ) : (
        <button
          className={reportsStyles.expandButon}
          onClick={() => setIsExpanded(true)}
        >
          more filters <img src={arrowDownIcon} alt="show more" />
        </button>
      )}
      <button
        className={reportsStyles.applyFiltersButton}
        onClick={() => {
          setQueryParams(getQueryParamsFromObject(filters));
          setPage(1);
        }}
      >
        Apply filters
      </button>
    </div>
  );
};
