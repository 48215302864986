import { createAxiosInstance } from "../../../../../api/axiosConfig.js";

export const fetchPostNatalConsultations = async (pregnancyId, tenantVal) => {
  if (!pregnancyId) return;

  const axiosInstance = createAxiosInstance();
  const response = await axiosInstance.get(
    `${tenantVal}/postnatalconsultation/getbypregnancyid/${pregnancyId}`
  );

  return response.data.map((item) => ({
    id: item.id.toString(),
    title: "Ante Natal consultation",
    date: new Date(),
    doctor: `${item?.pregnancy.conductedByStaffMember?.firstName} ${item?.pregnancy.conductedByStaffMember?.lastName}`,
    subject: {
      bornAt: new Date(item.bornAt),
      deliveryMethod: item.deliveryMethod,
      weightAtBirth: `${item.weightAtBirth}g`,
      jaudice: item?.jaudice?.toLowerCase() === "yes" ? "Yes" : "No",
      feeding: item.breastFeeding
        ? "Breast"
        : item.forumlaFeeding
        ? "Formula"
        : "Breast and Formula combination",
      issuesOfNote: item.issuesOfNote || "-",
      phnVisit: item.phvVisit || "-",
    },
    objective: {
      weight: item.weightInG ? `${item.weightInG}g` : "-",
      length: item.lenghtInCm ? `${item.lenghtInCm}cm` : "-",
      headCircumference: item.headCircumferenceInCm
        ? `${item.headCircumferenceInCm}cm`
        : "-",
      CRT: item.crt,
      umbilicus: item.umbilicus || "-",
      barlowsAndOrtalanisTests: item.barlowAndOrlaniTest || "-",
      heart: item.heart || "-",
      fonantelles: item.fonantelles || "-",
      buccalMucosa: item.buccalMucosa || "-",
      feet: item.feet || "-",
      impression: item.impression || "-",
      plan: item.plan || "-",
    },
    mother: {
      gravida: item.mothesrGravida,
      para: item.motherPara,
      issuesDuringOrSincePregnancy: item.issueDuringOrSincePregnancy || "-",
      postNatalMood: item.postNatalMood || "-",
      supports: item.motherSupports || "-",
      contraception: item.motherContraception || "-",
      mirena: item.motherMirena || "-",
    },
    attachments: {
      refferrals: [],
      prescriptions: [],
    },
  }));
};
