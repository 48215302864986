import { z } from "zod";
import { createAxiosInstance } from "../../api/axiosConfig";
import toast from "react-hot-toast";

export const medicineSchema = z
  .object({
    dosage: z.string().min(1, { message: "Please provide dosage." }),
    form: z.string().min(1, { message: "Please provide form." }),
    startDate: z.string().min(1, { message: "Please provide start date." }),
    medicineId: z.number().or(z.string()).optional(),
    route: z.string().min(1, { message: "Please provide route." }),
    duration: z.string().optional(),
    durationFormat: z.string().optional(),
    usageType: z.string().min(1, { message: "Please provide usage type." }),
    repeat: z.string().optional(),
    isManual: z.boolean(),
  })
  .refine(
    (data) => {
      if (data.isManual) {
        return data.tradeName && data.manufacturer && data.packSize;
      }
      return data.medicineId;
    },
    {
      message: "Please provide required medication details",
      path: ["medicineId"],
    }
  );

export const downloadPrescription = async (id, tenantVal, patientId) => {
  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.get(
      `${tenantVal}/patientprescription/download/prescription/${id}/patient/${patientId}`,
      {
        responseType: "blob",
      }
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `prescription_${id}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    toast.error("Error downloading prescription");
  }
};
