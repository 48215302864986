import React from "react";
import NavigationBar from "../../PatientDetailGp/components/NavigationBar/NavigationBar.jsx";
import s from "./PatientConsultations.module.css";
import PatientCard from "../PatientCard/PatientCard.jsx";
import { ConsultationsCard } from "./components/ConsultationsCard/ConsultationsCard.jsx";

const PatientConsultations = () => {
  return (
    <>
      <NavigationBar />
      <div style={{ margin: "0 auto" }}>
        <div className={s.wrapper}>
          <div className={s.firstRowWrapper}>
            <div className={s.firstColumnWrapper}>
              <PatientCard />
            </div>
            <div className={s.secondColumnWrapper}>
              <ConsultationsCard />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientConsultations;
