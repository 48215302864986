import React from "react";
import { Toaster } from "react-hot-toast";
import s from "./PatientFamilyTable.module.css";
import Plus from "../../../../assets/images/transparrent-plus.svg";
import redTrash from "../../../../assets/images/red-trash.svg";

import { Empty, Table } from "antd";
import CustomButton from "../../../../components/helpers/CustomButton/CustomButton";
import { DeleteModal } from "../../../../components/Modal/DeleteModal";
import { useDeleteRelatioship } from "../../../../hooks/useDeleteFamilyRelation";

const PatientsFamilyTable = ({
  relationshipListList,
  handleClick,
  refetchRelationships,
}) => {
  const { mutate: deleteRelationship } =
    useDeleteRelatioship(refetchRelationships);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.patientname.length - b.patientname.length,
      sortDirections: ["ascend", "descend"],
      render: (text) => <a href="javascript:void(0)">{text}</a>,
    },
    {
      title: "DOB",
      dataIndex: "dob",
      key: "dob",
    },
    {
      title: "Relationship",
      dataIndex: "familyRelationType",
      key: "familyRelationType",
    },
    {
      title: "Can access",
      dataIndex: "canSeeRecords",
      key: "canSeeRecords",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "10px", justifyContent: "end" }}>
          <button
            className={s.stopButton}
            data-bs-toggle="modal"
            data-bs-target="#myModal"
          >
            <img className={s.stopIcon} src={redTrash} alt="stop button" />
          </button>
          <DeleteModal
            deleteService={async () => {
              await deleteRelationship(record.id);
            }}
            context="user"
            itemName="reletionship"
          />
          <button
            className={s.editButton}
            onClick={() => {
              handleClick(record);
            }}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className={s.topWrapper}>
      <Toaster position="top-center" reverseOrder={false} />
      <div style={{ width: "100%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <div className={s.titleWrapper}>
            <h3 className={s.title}>{"Patient family relationships"}</h3>
          </div>
          <div style={{ float: "right" }}>
            <CustomButton
              title="Add New"
              buttonHandle={handleClick}
              icon={Plus}
              buttonClassName={s.addButton}
            />
          </div>
        </div>

        <div className="custom-table-wrapper patients-table">
          <Table
            dataSource={relationshipListList}
            columns={columns}
            locale={<Empty description="No Records Found" />}
          />
        </div>
      </div>
    </div>
  );
};

export default PatientsFamilyTable;
