import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import style from "../../../PatientPregnancy/AddPregnancyForm/AddPregnancyForm.module.css";
import style2 from "./ConsultationEdit.module.css";
import { CheckIcon } from "lucide-react";
import { useConsultationNote } from "../../../../../../hooks/useConsultationNote";
import { useUpdateConsultationNotes } from "../../../../../../hooks/useUpdateConsultationNotes";
import { useMainService } from "../../../../../../hooks/useMainService.js";
import CustomCheckbox from "../../../../../../components/helpers/CustomCheckbox/CustomCheckbox.jsx";
import { ChooseTemplateModal } from "./components/ChooseTemplateModal.jsx";
import { AddLetterModal } from "../../../../../../components/AddLetterModal/AddLetterModal.jsx";
import { AddPrescriptionModal } from "../../../../../../components/AddPrescriptionModal/AddPrescriptionModal.jsx";
import { AddReferralModal } from "../../../../../../components/AddReferralModal/AddReferralModal.jsx";

const ConsultationEdit = ({
                            patient,
                            isDisabled,
                            consultation,
                            staffInfo,
                            saveAsTemplate,
                            setSaveAsTemplate,
                          }) => {
  const { data: consultationNotes } = useConsultationNote(consultation?.id);
  const patientId = localStorage.getItem("kinesin-patientId");
  const [note, setNote] = useState("");
  const { updateNotes } = useUpdateConsultationNotes(
      consultation?.id,
      patientId,
      consultationNotes?.at(0)?.id
  );
  const { data: mainService } = useMainService(
      consultation?.mainPracticeServiceId
  );

  useEffect(() => {
    if (!consultation?.id) {
      setNote("");
    }
  }, [consultation?.id]);

  useEffect(() => {
    if (consultationNotes?.at(0)?.note && note === "") {
      setNote(consultationNotes?.at(0)?.note);
    }
  }, [consultationNotes, note]);

  return (
      <>
        <form className={style.addPregnancyForm}>
          <div className="row">
            <div className="col-md-4 mb-4">
              <div className={`${style.formField} form-field-controll`}>
                <div>
                  <label className={style.pregnancyFormLabel}>
                    Consultation type
                  </label>
                  <input
                      name="consultationType"
                      disabled
                      type="text"
                      className="form-control"
                      value={mainService?.name ?? ""}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className={`${style.formField} form-field-controll`}>
                <div>
                  <label className={style.pregnancyFormLabel}>
                    Consultation date
                  </label>
                  <input
                      name="consultationDate"
                      disabled
                      type="text"
                      className="form-control"
                      value={consultation?.created}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className={`${style.formField} form-field-controll`}>
                <div>
                  <label className={style.pregnancyFormLabel}>
                    Consultation Doctor
                  </label>
                  <input
                      name="doctorName"
                      disabled
                      type="text"
                      className="form-control"
                      value={staffInfo?.name}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={style2.addButtonsWrapperOuter}>
            <div>
              <p className={style.pregnancyFormLabel}>Templates (optional)</p>
              <ChooseTemplateModal
                  onSelect={(newNote) => {
                    setNote(newNote);
                    updateNotes(newNote);
                  }}
                  isDisabled={isDisabled}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mb-12">
              <div className={`${style.formField} form-field-controll`}>
              <textarea
                  placeholder="Type notes here"
                  className="form-control textareaform-control"
                  type="text"
                  rows="15"
                  maxLength="2500"
                  disabled={isDisabled}
                  value={note}
                  onChange={(e) => {
                    const newNote = e.target.value;

                    setNote(newNote);
                    updateNotes(newNote);
                  }}
              />
              </div>
            </div>
          </div>
        </form>
        <div className={style2.bottomSectionWrapper}>
          <div className={style2.checkboxWrapper}>
            <label
                className={`${style.pregnancyFormCheckboxLabel} ${
                    isDisabled && style2.pregnancyFormCheckboxLabelDisabled
                }`}
            >
              <CustomCheckbox
                  checked={saveAsTemplate}
                  disabled={isDisabled}
                  onChange={(e) => {
                    setSaveAsTemplate(e.target.checked);
                  }}
              />
              <p>Save as template</p>
            </label>
          </div>
          <p className={`${isDisabled && style2.autoSaveDisabled}`}>
            <CheckIcon width={16} height={16} />
            Auto saved
          </p>
        </div>
      </>
  );
};

export default ConsultationEdit;
