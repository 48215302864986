import { useSelector } from "react-redux";
import style from "./DisplayTaskModal.module.css";
import { AddTaskModal } from "../../AddTaskModal/AddTaskModal";
import Modal from "../../helpers/Modal/Modal";
import { useCompleteTask } from "../../../hooks/useCompleteTask";
import { useOpenPatientSummaryPage } from "../../../Utils/openPatientSummaryPage";
import moment from "moment";

const priorityLabels = {
  0: "High",
  1: "Medium",
  2: "Low",
};

export const DisplayTaskModal = ({ headerTitle, closeModal, task, isOpen }) => {
  const nameState =
    useSelector((state) => state.auth.user?.name) ||
    localStorage.getItem("kinesin-name");

  const { mutate: completeTask } = useCompleteTask(closeModal);

  const openPatientSummaryPage = useOpenPatientSummaryPage();

  if (!isOpen) return null;

  return (
    <Modal
      title={headerTitle || "Task Info"}
      position="center"
      onClose={closeModal}
      hideCancel={true}
      width="640px"
    >
      <div className={style.taskInfo}>
        <div>
          <label>Patient name </label>
          <button
            type="button"
            aria-label="Close"
            onClick={(e) => {
              e.preventDefault();
              if (task && task.patient)
                openPatientSummaryPage(
                  task.patient.id,
                  `${task.patient.firstName} ${task.patient.surname}`
                );
            }}
          >
            {task && task.patient
              ? `${task.patient.firstName} ${task.patient.surname}`
              : "-"}
          </button>
        </div>
        <div>
          <label>Assigned To</label>
          <p>
            {task && task.assignedToStaff
              ? `${task.assignedToStaff.firstName} ${task.assignedToStaff.lastName}`
              : "-"}
          </p>
        </div>
        <div>
          <label>From</label>
          <p>{nameState}</p>
        </div>
        <div>
          <label>Priority</label>
          <p>{task ? priorityLabels[task.priority] : "-"} </p>
        </div>
        <div>
          <label>Due date</label>
          <p>
            {task && task.dueDate
              ? moment(task.dueDate).format("DD.MM.YYYY")
              : "-"}
          </p>
        </div>
        <div>
          <label>Status</label>
          <p>{task && task.status ? task.status : "-"}</p>
        </div>
        <div className={style.taskInfoSpanCol2}>
          <label>Task</label>
          <p>{task && task.title ? task.title : "-"}</p>
        </div>
      </div>
      <div className={style.buttonContainer}>
        {task && task.status !== "Complete" && (
          <button
            className={style.button}
            onClick={() => completeTask(task.id)}
          >
            Complete Task
          </button>
        )}
        <AddTaskModal
          task={task}
          modalTitle={"Edit Task"}
          buttonText={"Edit Task"}
        />
      </div>
    </Modal>
  );
};
