import { usePatientConsultations } from "../../../../../../hooks/usePatientConsultations";
import { Table } from "./Table/Table";
import styles from "../../PatientOverviewModal.module.css";

export const Consultations = (patientIdVal) => {
  const patientId = localStorage.getItem("kinesin-patientId");
  const { data: consultations, isLoading } = usePatientConsultations(patientId ? patientId : patientIdVal);

  return (
    <div className={styles.medicalHistoryWrapper}>
      <Table items={consultations || []} isLoading={isLoading} />
    </div>
  );
};
