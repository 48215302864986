import React from "react";
import style from "./EmptyState.module.css";
import CustomButton from "../../../../components/helpers/CustomButton/CustomButton";
import emptyDocs from "../../../../assets/images/empty-docs.svg";

const EmptyState = ({ handleClick }) => {
  return (
    <div className={style.emptyState}>
      <img className={style.element} alt="Element" src={emptyDocs} />
      <div className={style.text}>
        <div className={style.div}>
          <div className={style.textWrapper}>No documents found</div>
          <p className={style.onceThePatient}>
            Once the patient receives a document, <br />
            it will appear here.
          </p>
        </div>
        <CustomButton
          buttonClassName={style.uploadButton}
          titleClassName={style.uploadButtonTitle}
          buttonHandle={handleClick}
          title={"Upload a new document"}
          icon={false}
        />
      </div>
    </div>
  );
};

export default EmptyState;
