import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import s from "./AppointmentsTable.module.css";
import AppointmentDetails from "./AppointmentDetails";
import { getAppointmentStatusColorForTable } from "../../../app/alliedhome/components/utils";
import { ChangeStatusButton } from "../../../app/alliedhome/components/ChangeStatusButton";

const AppointmentsTable = ({
  myAppointments,
  isLoading,
  hoursOfDay,
  nextSevenDays,
  openAddAppointmentModal,
  refetchMyAppointments,
  setSelectedAppointmentForEdit,
  setIsAddAppointmentModalOpen,
  setDeleteAppointmentId,
}) => {
  const appointmentsByDay = nextSevenDays.map((day) => {
    const dayDate = moment(day, "YYYY-MM-DD").format("YYYY-MM-DD");

    const appointmentsForDay = myAppointments
      ?.filter((appointment) => {
        const appointmentFormattedDate = moment(
          appointment.appointmentDate
        ).format("YYYY-MM-DD");
        return appointmentFormattedDate === dayDate;
      })
      .flatMap((appointment) => appointment.appointmentList);

    return {
      date: dayDate,
      dateDisplay: day,
      appointments: appointmentsForDay || [],
    };
  });

  const isInTimeRange = (appointment, hour) => {
    const appointmentStart = moment(appointment.startTime, "YYYY-MM-DD HH:mm");
    const appointmentEnd = moment(appointment.endTime, "YYYY-MM-DD HH:mm");
    const checkTime = moment(
      `${appointment.appointmentDate} ${hour}`,
      "YYYY-MM-DD hh:mm A"
    );
    return checkTime.isBetween(
      appointmentStart,
      appointmentEnd,
      "minute",
      "[)"
    );
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 1500 }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                border: "none",
                textAlign: "center",
                fontWeight: "bold",
              }}
            />
            {nextSevenDays.map((day, index) => (
              <TableCell
                key={index}
                align="center"
                sx={{
                  border: "1px solid var(--gray-400)",
                  padding: "15px 5px",
                  height: "100%",
                  width: "200px",
                  fontWeight: "500",
                }}
              >
                {moment(day).format("ddd, MMM D")}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {hoursOfDay.map((hour, index) => (
            <TableRow key={index} sx={{ ":hover": { background: "inherit" } }}>
              <TableCell
                sx={{
                  border: "none",
                  transform: "translateY(-50%)",
                  width: "100px",
                }}
              >
                {hour}
              </TableCell>
              {appointmentsByDay.map((dayData, dayIndex) => {
                const appointmentForTimeSlot = dayData.appointments.find(
                  (appointment) => {
                    const appointmentStart = moment(
                      appointment.startTime,
                      "YYYY-MM-DD HH:mm"
                    );
                    const checkTime = moment(
                      `${dayData.date} ${hour}`,
                      "YYYY-MM-DD hh:mm A"
                    );
                    return appointmentStart.isSame(checkTime, "minute");
                  }
                );

                const rowSpan =
                  parseInt(appointmentForTimeSlot?.durationInMins) / 15;

                const isOccupied = dayData.appointments.some((appointment) =>
                  isInTimeRange(appointment, hour)
                );

                const isPast = moment().isAfter(
                  moment(`${dayData.date} ${hour}`, "YYYY-MM-DD hh:mm A")
                );

                if (!appointmentForTimeSlot && isOccupied) return null;

                return (
                  <TableCell
                    className={s.tableCell}
                    sx={{
                      height: "55px",
                      borderRight: "1px solid var(--gray-400)",
                      borderLeft: "1px solid var(--gray-400)",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                      borderTop: "none",
                      padding: "10px 7px",
                      position: "relative",
                      cursor:
                        isPast && !appointmentForTimeSlot
                          ? "default"
                          : "pointer",
                    }}
                    rowSpan={rowSpan}
                    key={dayIndex}
                    align="center"
                    onClick={(e) => {
                      if (isPast && !appointmentForTimeSlot) {
                        return;
                      } else if (!appointmentForTimeSlot) {
                        setSelectedAppointmentForEdit(null);
                        openAddAppointmentModal(dayData.date, hour);
                      }
                    }}
                  >
                    {appointmentForTimeSlot ? (
                      <>
                        <div
                          className={`${s.appointmentCard} ${
                            appointmentForTimeSlot.durationInMins <= 15
                              ? s.timeDuration15
                              : appointmentForTimeSlot.durationInMins <= 30
                              ? s.timeDuration30
                              : appointmentForTimeSlot.durationInMins <= 45
                              ? s.timeDuration45
                              : s.timeDuration60
                          }
                              ${getAppointmentStatusColorForTable(
                                appointmentForTimeSlot.appointmentStatus
                              )}
                          `}
                        >
                          <div className={s.appointmentCardHeader}>
                            {appointmentForTimeSlot.patient.firstName}{" "}
                            {appointmentForTimeSlot.patient.surname}
                            <ChangeStatusButton
                              appointmentId={appointmentForTimeSlot.id}
                              onSelect={refetchMyAppointments}
                              deleteAppointment={() =>
                                setDeleteAppointmentId(
                                  appointmentForTimeSlot.id
                                )
                              }
                            />
                          </div>
                        </div>
                        <div
                          className={s.appointmentDetails}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedAppointmentForEdit(
                              appointmentForTimeSlot
                            );
                            setIsAddAppointmentModalOpen(true);
                          }}
                        >
                          <AppointmentDetails
                            appointment={appointmentForTimeSlot}
                            refetchMyAppointments={refetchMyAppointments}
                            status={appointmentForTimeSlot.appointmentStatus}
                          />
                        </div>
                      </>
                    ) : (
                      isPast && (
                        <div
                          style={{ background: "rgb(242, 242, 250)" }}
                          className="w-100 h-100"
                        />
                      )
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AppointmentsTable;
