import { createAxiosInstance } from "../../../../../api/axiosConfig.js";

export const fetchPregnanciesPatientById = async (patientId, tenantVal) => {
  if (!patientId) return;

  const axiosInstance = createAxiosInstance();
  const response = await axiosInstance.get(
    `${tenantVal}/pregnancy/getbypatientid/${patientId}`
  );

  return response.data.map((item) => ({
    ...item,
    doctor: `${item?.conductedByStaffMember?.firstName} ${item?.conductedByStaffMember?.lastName}`,
    estimatedDueDate: new Date(item.estimatedDueDate),
    lastMenstrualPeriod: new Date(item.lastMenstrualPeriod),
    history: item.history || "",
    medications: item.medications || "",
    exam: {
      hasClinicHCGPositive: item.confirmHcgPositive,
    },
  }));
};
