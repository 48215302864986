import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useICD10Codes = (search) => {
  const handleNetworkError = useHandleNetworkError();

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["icd10Codes", tenantVal, search],
    queryFn: async () => {
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/icd10codes/search/${search}`
      );
      return response.data;
    },
    keepPreviousData: true,
    onError: handleNetworkError,
  });
};
