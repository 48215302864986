import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";
import toast from "react-hot-toast";

export const useMedicationsById = (id) => {
  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error fetching medication: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["drugById", id, tenantVal],
    queryFn: async () => {
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/drugsfile/get/${id}`
      );
      return response.data;
    },
    enabled: !!id,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    debounceTime: 300,
    onError: handleNetworkError,
  });
};
