import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useConsultationNote = (consultationId) => {
  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error fetching consultation note: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["consultationNote", consultationId, tenantVal],
    queryFn: async () => {
      if (!consultationId) return null;
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/consultationnote/getbyconsultationid/${consultationId}`
      );
      return response.data;
    },
    enabled: !!consultationId,
    onError: handleNetworkError,
  });
};
