import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useDeleteAppointment = (onSuccess) => {
  const queryClient = useQueryClient();

  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error while deleting appointment: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useMutation({
    mutationFn: async (id) => {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.delete(
        `${tenantVal}/appointment/delete`,
        {
          data: {
            id: Number(id),
          },
        }
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("Appointment deleted successfully");
      queryClient.invalidateQueries(["appointments"]);
      onSuccess?.();
    },
    onError: handleNetworkError,
  });
};
