import React from "react";
import dayjs from "dayjs";
import { useForm, FormProvider } from "react-hook-form";
import { toast, Toaster } from "react-hot-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-calendar/dist/Calendar.css";

import style from "./PregnancyAddPostNatalConsultationForm.module.css";
import { FormField } from "../../../../../../components/form/FormField/FormField";
import CustomCheckbox from "../../../../../../components/helpers/CustomCheckbox/CustomCheckbox";
import { finishPostNatalConsultation } from "../../utils/finishPostNatalConsultation";
import { formSchema } from "./formSchema";
import { fetchPatientById } from "../../utils/fetchPatientById";
import { AddLetterModal } from "../../../../../../components/AddLetterModal/AddLetterModal";
import { AddPrescriptionModal } from "../../../../../../components/AddPrescriptionModal/AddPrescriptionModal";
import { AddReferralModal } from "../../../../../../components/AddReferralModal/AddReferralModal";

export const PregnancyAddPostNatalConsultationForm = ({
  closeView,
  tenantVal,
  pregnancyId,
  patientId,
}) => {
  const { data: patient } = useQuery({
    queryKey: ["patient", patientId, tenantVal],
    queryFn: () => fetchPatientById(patientId, tenantVal),
  });
  const today = dayjs(new Date().setMinutes(0));
  const queryClient = useQueryClient();
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      issues: "",
      postNatalMood: "",
      impression: "",
      plan: "",
      supports: "",
      mirena: "",
      contraception: "",
      para: "",
      gravida: "",
      weight: "",
      length: "",
      headCircumference: "",
      crt: "no",
      weightAtBirth: "",
      deliveryMethod: "c-section",
      bornAt: moment(new Date(today)).format("DD-MM-YYYY"),
      combination: false,
      phnVisit: "",
      issuesOfNote: "",
      feeding: "breast",
      umbilicus: "",
      barlowAndOrlaniTest: "",
      heart: "",
      fonantelles: "",
      buccalMucosa: "",
      feet: "",
    },
  });

  const { mutate: finishConsultation, isPending } = useMutation({
    mutationFn: async (values) => {
      await finishPostNatalConsultation(
        values,
        tenantVal,
        pregnancyId,
        patientId
      );
    },
    onSuccess: () => {
      form.reset();
      closeView();
      queryClient.invalidateQueries(["postNatalConsultations"]);
    },
    onError: () => {
      toast.error("Oups, something went wrong!");
    },
  });

  const handleFormSubmit = async (values) => {
    finishConsultation(values);
  };

  return (
    <div className={style.addPostNatalConsultationFormWrapper}>
      <Toaster position="top-center" reverseOrder={false} />
      <FormProvider {...form}>
        <form
          className={style.addPregnancyForm}
          onSubmit={form.handleSubmit(handleFormSubmit)}
        >
          <h3 className={style.pregnancyFormSectionTitleWithoutBorder}>
            Subject
          </h3>
          <div className={style.inputGroupGrid4Columns}>
            <FormField name="bornAt">
              <div className={style.addPregnancyDatePickerWrapper}>
                <label className={style.pregnancyFormLabel}>Born At</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD-MM-YYYY"
                    name="bornAt"
                    defaultValue={today}
                    sx={{
                      width: "100%",
                      height: "36px",
                    }}
                    onChange={(newValue) => {
                      form.setValue(
                        "bornAt",
                        moment(new Date(dayjs(newValue))).format("DD-MM-YYYY")
                      );
                    }}
                  />
                </LocalizationProvider>
              </div>
            </FormField>
            <FormField name="deliveryMethod">
              <label className={style.pregnancyFormLabel}>
                Delivery method
              </label>
              <select
                as="select"
                className="form-select form-control custom-select"
                {...form.register("deliveryMethod")}
              >
                <option value="c-section">C-section</option>
                <option value="vaginal-delivery">Vaginal delivery</option>
                <option value="assisted-vaginal-delivery">
                  Assisted vaginal delivery (vacuum or forceps).
                </option>
              </select>
            </FormField>
            <FormField name="weightAtBirth">
              <label className={style.pregnancyFormLabel}>
                Weight at birth
              </label>
              <div className={style.gramsInput}>
                <input
                  type="text"
                  inputMode="numeric"
                  className="form-control"
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  }
                  {...form.register("weightAtBirth")}
                />
              </div>
            </FormField>
            <FormField name="feeding">
              <label className={style.pregnancyFormLabel}>Feeding</label>
              <select
                as="select"
                className="form-select form-control custom-select"
                {...form.register("feeding")}
              >
                <option value="Breast">Breast</option>
                <option value="Formula">Formula</option>
                <option value="Breast and Formula combination">
                  Combination
                </option>
              </select>
            </FormField>
          </div>
          <div className={style.inputGroupGrid2Columns}>
            <FormField name="issuesOfNote">
              <label className={style.pregnancyFormLabel}>Issues of note</label>
              <textarea
                className="form-control textareaform-control"
                style={{ minHeight: "84px" }}
                {...form.register("issuesOfNote")}
              />
              <label
                className={`${style.pregnancyFormCheckboxLabel} ${style.pregnancyFormCheckboxLabelWithTopMargin}`}
              >
                <CustomCheckbox {...form.register("jaudice")} />
                Jaudice
              </label>
            </FormField>
            <FormField name="phnVisit">
              <label className={style.pregnancyFormLabel}>PHN Visit:</label>
              <textarea
                className="form-control textareaform-control"
                style={{ minHeight: "84px" }}
                {...form.register("phnVisit")}
              />
            </FormField>
          </div>
          <h3 className={style.pregnancyFormSectionTitle}>Objective</h3>
          <div className={style.inputGroupGrid4Columns}>
            <FormField name="weight">
              <label className={style.pregnancyFormLabel}>Weight</label>
              <div className={style.gramsInput}>
                <input
                  type="text"
                  inputMode="numeric"
                  className="form-control"
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  }
                  {...form.register("weight")}
                />
              </div>
            </FormField>
            <FormField name="length">
              <label className={style.pregnancyFormLabel}>Length</label>
              <div className={style.cmInput}>
                <input
                  type="text"
                  inputMode="numeric"
                  className="form-control"
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  }
                  {...form.register("length")}
                />
              </div>
            </FormField>
            <FormField name="headCircumference">
              <label className={style.pregnancyFormLabel}>
                Head circumference
              </label>
              <div className={style.cmInput}>
                <input
                  type="text"
                  inputMode="numeric"
                  className="form-control"
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  }
                  {...form.register("headCircumference")}
                />
              </div>
            </FormField>
            <FormField name="crt">
              <label className={style.pregnancyFormLabel}>CRT</label>
              <select
                as="select"
                className="form-select form-control custom-select"
                {...form.register("crt")}
              >
                <option value="yes">yes</option>
                <option value="no">no</option>
              </select>
            </FormField>
          </div>
          <FormField name="umbilicus">
            <label className={style.pregnancyFormLabel}>Umbilicus</label>
            <textarea
              className="form-control textareaform-control"
              style={{ minHeight: "84px" }}
              {...form.register("umbilicus")}
            />
          </FormField>
          <FormField name="barlowAndOrlaniTest">
            <label className={style.pregnancyFormLabel}>
              Barlow and Orlani's Test
            </label>
            <textarea
              className="form-control textareaform-control"
              style={{ minHeight: "84px" }}
              {...form.register("barlowAndOrlaniTest")}
            />
          </FormField>
          <FormField name="heart">
            <label className={style.pregnancyFormLabel}>Heart</label>
            <textarea
              className="form-control textareaform-control"
              style={{ minHeight: "84px" }}
              {...form.register("heart")}
            />
          </FormField>
          <FormField name="fonantelles">
            <label className={style.pregnancyFormLabel}>Fonantelles</label>
            <textarea
              className="form-control textareaform-control"
              style={{ minHeight: "84px" }}
              {...form.register("fonantelles")}
            />
          </FormField>
          <FormField name="buccalMucosa">
            <label className={style.pregnancyFormLabel}>Buccal Mucosa</label>
            <textarea
              className="form-control textareaform-control"
              style={{ minHeight: "84px" }}
              {...form.register("buccalMucosa")}
            />
          </FormField>
          <FormField name="feet">
            <label className={style.pregnancyFormLabel}>Feet</label>
            <textarea
              className="form-control textareaform-control"
              style={{ minHeight: "84px" }}
              {...form.register("feet")}
            />
          </FormField>
          <h3 className={style.pregnancyFormSectionTitle}>Mother</h3>
          <div className={style.inputGroupGrid2Columns}>
            <FormField name="gravida">
              <label className={style.pregnancyFormLabel}>Gravida</label>
              <input
                type="text"
                inputMode="numeric"
                className="form-control"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
                {...form.register("gravida")}
              />
            </FormField>
            <FormField name="para">
              <label className={style.pregnancyFormLabel}>Para</label>
              <input
                inputMode="numeric"
                type="text"
                className="form-control"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
                {...form.register("para")}
              />
            </FormField>
          </div>
          <FormField name="issues">
            <label className={style.pregnancyFormLabel}>Issues</label>
            <textarea
              className="form-control textareaform-control"
              style={{ minHeight: "84px" }}
              {...form.register("issues")}
            />
          </FormField>
          <FormField name="postNatalMood">
            <label className={style.pregnancyFormLabel}>Post-natal mood</label>
            <textarea
              className="form-control textareaform-control"
              style={{ minHeight: "84px" }}
              {...form.register("postNatalMood")}
            />
          </FormField>
          <FormField name="supports">
            <label className={style.pregnancyFormLabel}>Supports</label>
            <textarea
              className="form-control textareaform-control"
              style={{ minHeight: "84px" }}
              {...form.register("supports")}
            />
          </FormField>
          <FormField name="contraception">
            <label className={style.pregnancyFormLabel}>Contraception</label>
            <textarea
              className="form-control textareaform-control"
              style={{ minHeight: "84px" }}
              {...form.register("contraception")}
            />
          </FormField>
          <FormField name="mirena">
            <label className={style.pregnancyFormLabel}>Mirena</label>
            <textarea
              className="form-control textareaform-control"
              style={{ minHeight: "84px" }}
              {...form.register("mirena")}
            />
          </FormField>
          <div className={style.topBorder}>
            <FormField name="impression">
              <label className={style.pregnancyFormLabel}>Impression</label>
              <textarea
                className="form-control textareaform-control"
                style={{ minHeight: "126px" }}
                {...form.register("impression")}
              />
            </FormField>
          </div>
          <FormField name="plan">
            <label className={style.pregnancyFormLabel}>Plan</label>
            <textarea
              className="form-control textareaform-control"
              style={{ minHeight: "126px" }}
              {...form.register("plan")}
            />
          </FormField>
          <div className={style.buttonRow}>
            <button
              type="submit"
              className={style.addConsultationButton}
              disabled={isPending}
            >
              Save consultation
            </button>
          </div>
        </form>
      </FormProvider>
      <div className={style.attachemntButtons}>
        <AddPrescriptionModal />
        <AddReferralModal patient={patient} />
        <AddLetterModal patient={patient} />
      </div>
    </div>
  );
};
