import React, { useEffect, useState } from "react";
import NavigationBar from "../../PatientDetailGp/components/NavigationBar/NavigationBar.jsx";
import { createAxiosInstance } from "../../../../api/axiosConfig.js";
import {
  PATIENT_CONTROLER_URL_ID,
  PATIENT_FAMILY_MEMBER_BY_ID,
} from "../../../../api/Service.js";
import { useDispatch, useSelector } from "react-redux";
import PatientCard from "../PatientCard/PatientCard.jsx";
import EmptyState from "./EmptyState";

import s from "./PatientFamily.module.css";
import Modal from "../../../../components/helpers/Modal/Modal";
import Search from "./PatientFamilySearch";

import toast from "react-hot-toast";
import {
  addPatientFullName,
  addPatientId,
} from "../../../../redux/patient/PatientSlice";
import { logoutFromHelper } from "../../../../api/Helper";
import { logout } from "../../../../redux/auth/AuthSlice";
import { removeuser } from "../../../../redux/user/userSlice";
import { useNavigate } from "react-router-dom";
import PatientFamilyTable from "./PatientFamilyTable";

const PatientFamily = () => {
  let patientFamilyList = [];
  let [showEmpty, setShowEmpty] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState({ open: false, id: null });
  let [dynamicDataSource, setDynamicDataSource] = useState([]);

  const [patient, setPatient] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const patientId = localStorage.getItem("kinesin-patientId");

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  /**
   * Used to display date of birth.
   * @param {*} dob indicates array which contains year, month and date.
   * @returns return date of birth.
   */
  const displayAge = (dob) => {
    if (!dob) return "";
    try {
      let aa = dob.split("-");
      if (aa && aa.length == 3) {
        return aa[2] + "/" + aa[1] + "/" + aa[0];
      } else {
        return dob;
      }
    } catch (err) {
      return "";
    }
  };
  /**
   * Used to calculate age by date of birth.
   * @param {*} dob indicates date of birth.
   * @returns returns age.
   */
  const calculateAge = (dob) => {
    if (!dob) return "";
    try {
      let age;
      let aa = dob.split("-");
      if (aa && aa.length == 3) {
        const dobDate = new Date(
          aa[0], // Year
          aa[1] - 1, // Month
          aa[2] // Day
        );
        const today = new Date();
        age = today.getFullYear() - dobDate.getFullYear();
        const monthDiff = today.getMonth() - dobDate.getMonth();
        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() < dobDate.getDate())
        ) {
          age--;
        }
      }
      return age;
    } catch (err) {
      return "";
    }
  };

  const fetchPatientById = async (id) => {
    try {
      if (!id) return;
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${PATIENT_CONTROLER_URL_ID}${id}`
      );
      if (response && response.data) {
        setPatient(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const goToPatientDetails = async (id, patientFullName) => {
    dispatch(addPatientId(id));
    localStorage.setItem("kinesin-patientId", id);
    dispatch(addPatientFullName(patientFullName));
    navigate("/patient-detail");
  };

  const fetchPatientFamily = async () => {
    let patientFamilyData = [];
    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${PATIENT_FAMILY_MEMBER_BY_ID}${patientId}`
      );

      patientFamilyList = response;
      showEmpty = true;
      setShowEmpty(showEmpty);

      if (response && response.data && response.data.length == 0) {
        showEmpty = false;
        setShowEmpty(showEmpty);
      }

      response.data.forEach((element) => {
        if (element) {
          patientFamilyData.push({
            key: element.id,
            id: element.id,
            patientId: element.relatedToPatient.id,
            patientname:
              element.relatedToPatient.firstName +
              (element.relatedToPatient.surname
                ? " " + element.relatedToPatient.surname
                : ""),
            name: (
              <>
                {element && element.relatedToPatient.firstName && (
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      goToPatientDetails(
                        element.relatedToPatient.id,
                        element.relatedToPatient.firstName +
                          (element.relatedToPatient.surname
                            ? " " + element.relatedToPatient.surname
                            : "")
                      );
                    }}
                    className="patient-title-wrap"
                  >
                    {element.relatedToPatient.firstName}{" "}
                    {element.relatedToPatient.surname}
                  </a>
                )}
              </>
            ),
            dob:
              displayAge(element.relatedToPatient.dateOfBirth) +
              " (" +
              calculateAge(element.relatedToPatient.dateOfBirth) +
              ")",
            addressline: "",
            sex: element.relatedToPatient.gender,
            telephone: element.relatedToPatient.mobilePhone,
            familyRelationType: element.familyRelationType,
            canSeeRecords: element.canSeeRecords ? "Yes" : "No",
            action: (
              <div className="d-flex align-items-center action-wrap">
                <button
                  className="view_file_btn"
                  onClick={() => fetchPatientById(element.id)}
                >
                  Edit
                </button>
              </div>
            ),
          });
        }
      });

      dynamicDataSource = patientFamilyData;
      setDynamicDataSource(dynamicDataSource);
      // setIsPageLoaded(true);
    } catch (error) {
      errorHandling(error);
    }
  };

  const errorHandling = (error) => {
    toast.error(error.message);
    if (error && error.response && error.response.status) {
      if (error.response.status == 403) {
        logoutFromHelper();
        dispatch(logout());
        dispatch(removeuser());
        navigate("/logout");
      }
    }
  };

  useEffect(() => {
    fetchPatientFamily();
    // setSearchQuery("")
  }, []);

  useEffect(() => {
    if (patientId) {
      fetchPatientById(patientId);
    }
  }, [patientId, tenantVal]);

  const handleOpenModal = (patientRelationship) => {
    setIsModalOpen({ open: true, patientRelationship });
  };
  const handleCloseModal = () => {
    setIsModalOpen({ open: false, patient: null });
    clearForm();
  };

  const handleSave = async (event) => {
    event.preventDefault();

    try {
      toast.success("Data saved successfully!");
      //setIsModalOpen({ open: false, id: null });
      clearForm();
    } catch (error) {
      toast.error(`Error saving data: ${error.message}`);
    }
  };

  const isRelationships = () => {
    return showEmpty;
  };

  const clearForm = () => {
    // setName("");
  };

  return (
    <>
      <NavigationBar />
      <div style={{ margin: "0 auto" }}>
        <div className={s.wrapper}>
          <div className={s.firstColumnWrapper}>
            {isLoading ? (
              <p>Patient data is loading...</p>
            ) : (
              <>
                <PatientCard patient={patient} />
              </>
            )}
          </div>
          <div className={s.secondColumnWrapper}>
            <div className={s.secondColumnSubWrapper}>
              {!isRelationships() === true && (
                <EmptyState handleClick={handleOpenModal}></EmptyState>
              )}
              {isRelationships() && (
                <PatientFamilyTable
                  relationshipListList={dynamicDataSource}
                  handleClick={handleOpenModal}
                  refetchRelationships={fetchPatientFamily}
                ></PatientFamilyTable>
              )}
            </div>
          </div>
        </div>

        {isModalOpen.open && (
          <Modal
            title="1 Search for family member"
            position="center"
            onClose={handleCloseModal}
            hideCancel={true}
            // height="850px"
            maxHeight="90vh"
          >
            <div className={s.inputWrapper}>
              <Search
                currentPatientId={patientId}
                closeModal={handleCloseModal}
                refetchRelationships={fetchPatientFamily}
                initialPatientRelationship={isModalOpen?.patientRelationship}
              ></Search>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default PatientFamily;
