import React from "react";

import s from "./CardWithTitle.module.css";

import CustomButton from "../CustomButton/CustomButton.jsx";

const CardWithTitle = ({
  children,
  title,
  icon,
  minWidth,
  buttonTitle,
  onButtonClick,
  marginBottom,
  paddingBottom,
  showButton = true,
  className,
}) => {
  return (
    <div style={{ minWidth: minWidth }} className={s.cardWrapper}>
      <div
        style={{ marginBottom: marginBottom, paddingBottom: paddingBottom }}
        className={s.topWrapper}
      >
        <div className={s.titleWrapper}>
          <h3 className={s.title}>{title}</h3>
        </div>
        <CustomButton
          title={buttonTitle}
          buttonHandle={onButtonClick}
          icon={icon}
          buttonClassName={className}
        />
      </div>
      {children}
    </div>
  );
};

export default CardWithTitle;
