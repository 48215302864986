import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast, Toaster } from "react-hot-toast";
import style from "./PatientFamilySearch.module.css";
import PatientsTable from "../../../../components/Patient/PatientsTable";
import { createAxiosInstance } from "../../../../api/axiosConfig";
import {
  PATIENT_SEARCH,
  PATIENT_FAMILY_MEMBER,
  APPOINMENT_ADD_URL,
} from "../../../../api/Service";
import Error from "../../../../components/Error/Error";
import Select from "react-select";
import { logoutFromHelper } from "../../../../api/Helper";
import { logout } from "../../../../redux/auth/AuthSlice";
import { removeuser } from "../../../../redux/user/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import downarrow from "../../../../assets/images/downarrow.png";

const PatientFamilySearch = ({
  currentPatientId,
  closeModal,
  refetchRelationships,
  initialPatientRelationship,
}) => {
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");
  const [isLoading, setIsLoading] = useState(true);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dobYear: "",
    dobMonth: "",
    dobDay: "",
    relationship: "",
  });

  let relTypes = [
    { name: "Select", value: "" },
    { name: "Child", value: "CHILD" },
    { name: "Father", value: "FATHER" },
    { name: "Mother", value: "MOTHER" },
    { name: "Parent", value: "PARENT" },
    { name: "Carer", value: "CARER" },
    { name: "Grand Parent", value: "GRANDPARENT" },
    { name: "Other", value: "OTHER" },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let patientList = useState([]);

  let [dynamicDataSource, setDynamicDataSource] = useState([
    { patientname: "No results found." },
  ]);
  let [errorList, setErrorList] = useState([]);

  let [patient, setPatient] = useState({});
  let [personRelationship, setPersonRelationship] = useState("");
  let [selectedPatientName, setSelectedPatientName] = useState("");

  const hasInitialRelationship =
    initialPatientRelationship?.patientname &&
    initialPatientRelationship?.familyRelationType;

  useEffect(() => {
    if (hasInitialRelationship) {
      setFormData({
        firstName: initialPatientRelationship.patientname?.split(" ")[0],
        lastName: initialPatientRelationship.patientname?.split(" ")[1],
        relationship: initialPatientRelationship.familyRelationType,
        dobYear: "",
        dobMonth: "",
        dobDay: "",
      });
      setSelectedPatientName(initialPatientRelationship.patientname);
      setPatient({
        id: initialPatientRelationship.patientId,
        firstname: initialPatientRelationship.patientname?.split(" ")[0],
        surname: initialPatientRelationship.patientname?.split(" ")[1],
      });
      handleFormSubmit({
        data: {
          firstName: initialPatientRelationship.patientname?.split(" ")[0],
          surname: initialPatientRelationship.patientname?.split(" ")[1],
        },
      });
    }
  }, [initialPatientRelationship]);

  const handleRelSave = async () => {
    const axiosInstance = createAxiosInstance();
    let response;
    if (selectedPatientName && currentPatientId) {
      try {
        response = await axiosInstance.post(
          `${tenantVal}${PATIENT_FAMILY_MEMBER}`,
          {
            tenant: tenantVal,
            familyRelationType: formData.relationship,
            mainPatientId: currentPatientId,
            relatedToPatientId:
              patient.id || initialPatientRelationship?.patientId,
            canSeeRecords: false,
            id: initialPatientRelationship?.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        toast.success(
          "Family relationship " +
            (response.id ? "updated" : "saved") +
            " successfully"
        );
        refetchRelationships?.();
        closeModal();
      } catch (error) {
        errorHandling(error);
      }
    }
  };

  const errorHandling = (error) => {
    toast.error(error.message);
    if (error && error.response && error.response.status) {
      if (error.response.status == 403) {
        logoutFromHelper();
        dispatch(logout());
        dispatch(removeuser());
        navigate("/logout");
      }
    }
  };

  const relChanged = async (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (obj) => {
    setErrorList([]);
    let firstName = "";
    let lastName = "";

    if (obj?.data && obj?.data?.firstName && obj?.data?.surname) {
      firstName = obj.data.firstName;
      lastName = obj.data.lastName;
    }

    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${PATIENT_SEARCH}?firstNameValue=${
          firstName || formData.firstName
        }&surnameValue=${lastName || formData.lastName}` +
          `&dobYear=${formData.dobYear}&dobMonth=${formData.dobMonth}&dobDate=${formData.dobDate}`
      );
      if (response && response.data) {
        setIsLoading(false);
      }

      patientList = [];

      response.data.forEach((element) => {
        if (element.firstName) {
          patientList.push({
            key: element.id,
            patientname:
              element.firstName +
              (element.surname ? " " + element.surname : ""),
            name: (
              <>
                {element && element.firstName && (
                  <a href="" className="patient-title-wrap">
                    {element.firstName} {element.surname}
                  </a>
                )}
              </>
            ),
            dob: element.dateOfBirth,
            addressline:
              element.address && element.address.addressLine1
                ? element.address.addressLine1
                : "",
            sex: element.gender,
            telephone: element.mobilePhone,
            action: (
              <div className="d-flex align-items-center action-wrap">
                <p className="icon-spacing"></p>
                <button
                  className="view_file_btn"
                  onClick={(event) => {
                    onPaientRowSubit(event, element);
                  }}
                >
                  Select
                </button>
              </div>
            ),
          });
        }
      });
      setDynamicDataSource(patientList);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const onPaientRowSubit = (event, selectedPatient) => {
    setErrorList([]);
    errorList = [];
    setPatient(selectedPatient);
    let patientId = selectedPatient.id;
    selectedPatientName =
      selectedPatient.firstName + " " + selectedPatient.surname;
    setSelectedPatientName(selectedPatientName);
    if (currentPatientId == patientId) {
      let errors = [
        "You cannot create a relationship with the same patient, please select a different patient ",
      ];
      setErrorList(errors);
      errorList = errors;
      toast.error("Error selecting patient");
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="dashBcontbody addpatentdashbord">
        <div className="mt-0">
          <Error errorList={errorList}></Error>

          <div className={style.searchbox}>
            <div className="row frompadding">
              <div className="col-xl-6 col-md-8 mb-8">
                <label className="">First Name</label>
                <input
                  name="firstName"
                  type="text"
                  className="form-control"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </div>

              <div className="col-xl-6 col-md-8 mb-8">
                <label className="">Last Name</label>
                <input
                  name="lastName"
                  type="text"
                  className="form-control"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row frompadding">
              <div className="col-xl-4 col-md-7 mb-7">
                <label>Year of birth</label>
                <input
                  name="dobYear"
                  type="text"
                  className="form-control"
                  value={formData.dobYear}
                  onChange={handleInputChange}
                />
              </div>

              <div className="col-xl-4 col-md-7 mb-7">
                <label>Month of birth </label>
                <select
                  className="form-select form-control dpblock"
                  name="dobMonth"
                  value={formData.dobMonth}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  <option value="">Select</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="9">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>

              <div className="col-xl-4 col-md-7 mb-7">
                <label>Date of birth</label>
                <input
                  name="dobYear"
                  type="text"
                  className="form-control"
                  value={formData.dobDate}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="btnxscenter">
                  <button
                    className={style.searchButton}
                    onClick={handleFormSubmit}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="btnxscenter">
                  <PatientsTable
                    patientList={dynamicDataSource}
                    title="2 Select the family member"
                  ></PatientsTable>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="btnxscenter">
                  <h3 className={style.title}>3. Select relationship</h3>
                  <div className="row frompadding">
                    <div className="col-xl-6 col-md-8 mb-8">
                      <label
                        htmlFor="floatingSelect"
                        className="requiredValidator"
                      >
                        Person's name
                      </label>
                      <input
                        disabled
                        name="dobYear"
                        type="text"
                        className="form-control"
                        value={selectedPatientName}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-xl-6 col-md-8 mb-8">
                      <label
                        htmlFor="floatingSelect"
                        className="requiredValidator"
                      >
                        Relationship
                      </label>
                      <div className="form-floating mb-3">
                        <select
                          className="form-select form-control dpblock"
                          name="relationship"
                          value={formData.relationship}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select</option>
                          <option value="CHILD">Child</option>
                          <option value="PARENT">Parent</option>
                          <option value="GRANDPARENT">Grand parent</option>
                          <option value="CARER">Carer</option>
                          <option value="GRANDPARENT">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="btnxscenter">
                    <button
                      className={style.saveButton}
                      onClick={handleRelSave}
                    >
                      {hasInitialRelationship
                        ? "Edit Relationship"
                        : "Add Relationship"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientFamilySearch;
