import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addPatientFullName,
  addPatientId,
} from "../redux/patient/PatientSlice";

export const useOpenPatientSummaryPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (patientId, patientFullName) => {
    if (!patientId) return;

    dispatch(addPatientId(patientId));
    localStorage.setItem("kinesin-patientId", patientId);
    dispatch(addPatientFullName(patientFullName));
    navigate("/patient-consult");
  };
};
