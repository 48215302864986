import dayjs from "dayjs";
import moment from "moment";
import React from "react";
import { z } from "zod";
import { useForm, FormProvider } from "react-hook-form";
import { toast, Toaster } from "react-hot-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-calendar/dist/Calendar.css";

import style from "./AddVaccineForm.module.css";
import { FormField } from "../../../../../components/form/FormField/FormField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../../../../../api/axiosConfig";

const formSchema = z.object({
  reaction: z.string().min(1, { message: "You must choose reaction." }),
  type: z.string().min(1, { message: "You must choose type." }),
  batchNo: z.string().min(1, { message: "Enter the batch no." }),
  administrationMethod: z
    .string()
    .min(1, { message: "You must choose administration method." }),
  expiryDate: z.string().min(1, { message: "Enter the expiry date." }),
  siteGiven: z.string().min(1, { message: "You must choose site given." }),
  dose: z.string().min(1, { message: "Enter the dose." }),
  notes: z.string().min(1, { message: "Enter the notes." }),
});

export const AddVaccineForm = ({
  currentPatientId,
  closeModal,
  tenantVal,
  vaccine,
}) => {
  const queryClient = useQueryClient();
  const today = dayjs(new Date().setMinutes(0));

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      type: vaccine?.type || "",
      batchNo: vaccine?.batchNo || "",
      administrationMethod: vaccine?.administrationMethod || "",
      manufacturer: vaccine?.manufacturer || "",
      expiryDate: vaccine?.expiryDate
        ? moment(
            new Date(
              vaccine?.expiryDate.at(0),
              vaccine?.expiryDate.at(1) - 1,
              vaccine?.expiryDate.at(2)
            )
          ).format("YYYY-MM-DD")
        : moment(new Date(today)).format("YYYY-MM-DD"),
      siteGiven: vaccine?.siteGiven || "Oral",
      dose: vaccine?.doseMl?.toString() || "",
      reaction: vaccine?.reaction || "UNKNOWN",
      reactionDate: vaccine?.reactionDate
        ? moment(
            new Date(
              vaccine?.reactionDate.at(0),
              vaccine?.reactionDate.at(1) - 1,
              vaccine?.reactionDate.at(2)
            )
          ).format("YYYY-MM-DD")
        : "",
      notes: vaccine?.notes || "",
    },
  });

  const { mutate: addImmunisation, isPending } = useMutation({
    mutationFn: async (values) => {
      const axiosInstance = createAxiosInstance();
      await axiosInstance.post(`${tenantVal}/patientimmunisation`, {
        id: vaccine?.id,
        vaccineName: values.type,
        batchNo: values.batchNo,
        administrationMethod: values.administrationMethod,
        expiryDate: values.expiryDate,
        siteGiven: values.siteGiven,
        doseMl: values.dose,
        reaction: values.reaction,
        reactionDate: values.reactionDate,
        notes: values.notes,
        patientId: currentPatientId,
        type: values.type,
      });
    },
    onSuccess: () => {
      toast.success(
        vaccine?.id ? "Vaccine has been updated." : "Vaccine has been added."
      );
      form.reset();
      closeModal();
      queryClient.invalidateQueries(["vaccines"]);
    },
    onError: () => {
      toast.error("Oups, something went wrong!");
    },
  });

  const handleFormSubmit = async (values) => {
    addImmunisation(values);
  };

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <FormProvider {...form}>
        <form
          className={style.addPregnancyForm}
          onSubmit={form.handleSubmit(handleFormSubmit)}
        >
          <div className={style.inputGroupGrid}>
            <FormField name="type">
              <label className={style.pregnancyFormLabel}>Type</label>
              <select
                as="select"
                {...form.register("type")}
                className="form-select form-control custom-select"
              >
                <option value="Influenza">Influenza</option>∂
                <option value="Pneumococcol">Pneumococcol</option>
                <option value="6:1">6:1</option>
                <option value="MMR">MMR</option>
                <option value="PCV">PCV</option>
                <option value="Men B">Men B</option>
                <option value="Men C">Men C</option>
                <option value="Hib/ MenC">Hib/ MenC</option>
                <option value="Covid-19 Primary/ Secondary">
                  Covid-19 Primary/ Secondary
                </option>
                <option value="Covid-19 Booster">Covid-19 Booster</option>
                <option value="Covid-19 Immunocompromised">
                  Covid-19 Immunocompromised
                </option>
                <option value="MMRV">MMRV</option>
                <option value="MenACWY">MenACWY</option>
                <option value="Rotavirus oral vaccine">
                  Rotavirus oral vaccine
                </option>
              </select>
            </FormField>
            <FormField name="batchNo">
              <label className={style.pregnancyFormLabel}>Batch No</label>
              <input
                type="text"
                className="form-control"
                {...form.register("batchNo")}
              />
            </FormField>
          </div>
          <div className={style.inputGroupGrid}>
            <FormField name="administrationMethod">
              <label className={style.pregnancyFormLabel}>
                Administration Method
              </label>
              <select
                as="select"
                {...form.register("administrationMethod")}
                className="form-select form-control custom-select"
              >
                <option value="Intramuscular (IM)">Intramuscular (IM)</option>
                <option value="Subcutaneous (SQ)">Subcutaneous (SQ)</option>
                <option value="Oral (PO)">Oral (PO)</option>
                <option value="Intranasal">Intranasal</option>
              </select>
            </FormField>
            <FormField name="dose">
              <label className={style.pregnancyFormLabel}>Dose</label>
              <div className={style.mlInput}>
                <input
                  className="form-control"
                  {...form.register("dose")}
                  type="text"
                  inputMode="numeric"
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  }
                />
              </div>
            </FormField>
          </div>
          <div className={style.inputGroupGrid}>
            <FormField name="expiryDate">
              <div className={style.addPregnancyDatePickerWrapper}>
                <label className={style.pregnancyFormLabel}>Expiry Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD-MM-YYYY"
                    name="expiryDate"
                    defaultValue={
                      vaccine?.expiryDate
                        ? dayjs(
                            new Date(
                              vaccine?.expiryDate.at(0),
                              vaccine?.expiryDate.at(1) - 1,
                              vaccine?.expiryDate.at(2)
                            )
                          )
                        : today
                    }
                    sx={{
                      width: "100%",
                      height: "36px",
                    }}
                    onChange={(newValue) => {
                      form.setValue(
                        "expiryDate",
                        moment(new Date(dayjs(newValue))).format("YYYY-MM-DD")
                      );
                    }}
                  />
                </LocalizationProvider>
              </div>
            </FormField>
            <FormField name="siteGiven">
              <label className={style.pregnancyFormLabel}>Site Given</label>
              <select
                as="select"
                className="form-select form-control custom-select"
                {...form.register("siteGiven")}
              >
                <option value="Oral">Oral</option>
                <option value="Bilateral Nares">Bilateral Nares</option>
                <option value="Left Deltoid">"Left Deltoid</option>
                <option value="Left Side">Left Side</option>
                <option value="Left Thigh">Left Thigh</option>
                <option value="Left Upper Arm">Left Upper Arm</option>
                <option value="Right Deltoid">"Right Deltoid</option>
                <option value="Right Side">Right Side</option>
                <option value="Right Thigh">Right Thigh</option>
                <option value="Right Upper Arm">Right Upper Arm</option>
              </select>
            </FormField>
          </div>
          <div className={style.inputGroupGrid}>
            <FormField name="reaction">
              <label className={style.pregnancyFormLabel}>Reaction</label>
              <select
                as="select"
                {...form.register("reaction")}
                className="form-select form-control custom-select"
              >
                <option value="UNKNOWN">Unknown</option>
                <option value="YES">Yes</option>
                <option value="NO">No</option>
              </select>
            </FormField>
            <FormField name="reactionDate">
              <div className={style.addPregnancyDatePickerWrapper}>
                <label className={style.pregnancyFormLabel}>
                  Reaction Date
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD-MM-YYYY"
                    name="reactionDate"
                    defaultValue={
                      vaccine?.reactionDate
                        ? dayjs(
                            new Date(
                              vaccine?.reactionDate.at(0),
                              vaccine?.reactionDate.at(1) - 1,
                              vaccine?.reactionDate.at(2)
                            )
                          )
                        : ""
                    }
                    sx={{
                      width: "100%",
                      height: "36px",
                    }}
                    onChange={(newValue) => {
                      form.setValue(
                        "reactionDate",
                        moment(new Date(dayjs(newValue))).format("YYYY-MM-DD")
                      );
                    }}
                  />
                </LocalizationProvider>
              </div>
            </FormField>
          </div>
          <FormField name="notes">
            <label className={style.pregnancyFormLabel}>Notes</label>
            <textarea
              rows="5"
              placeholder="Enter notes"
              className="form-control"
              name="notes"
              style={{ minHeight: "140px" }}
              {...form.register("notes")}
            />
          </FormField>

          <div className={style.buttonRow}>
            <button
              className={style.cancelButton}
              onClick={closeModal}
              type="button"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={style.addPregnancyButton}
              disabled={isPending}
            >
              {vaccine?.id ? "Save" : "Add"}
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
