import moment from "moment";
import CustomCheckbox from "../../../../../components/helpers/CustomCheckbox/CustomCheckbox";
import style from "./PregnancyGeneralView.module.css";
import Modal from "../../../../../components/helpers/Modal/Modal.jsx";
import editIcon from "../../../../../assets/images/edit-green-pencil.svg";
import { EditPregnancyForm } from "../EditPregnancyForm/EditPregnancyForm.jsx";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchAnteNatalConsultations } from "../utils/fetchAnteNatalConsultations.js";

export const PregnancyGeneralView = ({ pregnancy, patientId, tenantVal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: anteNatalConsultations } = useQuery({
    queryKey: ["anteNatalConsultations", pregnancy.id, tenantVal],
    queryFn: () => fetchAnteNatalConsultations(pregnancy.id, tenantVal),
  });

  const firstConsultationDate = anteNatalConsultations?.at(0)?.date
    ? moment(anteNatalConsultations?.at(0)?.date).format("DD.MM.YYYY")
    : "No consultations found";

  return (
    <div className={style.pregnancyGeneralInformationView}>
      <div className={style.generalInfoTitleWrapper}>
        <h1 className={style.generalInfoTitle}>General Information</h1>
        <button onClick={() => setIsModalOpen(true)}>
          Edit
          <img src={editIcon} alt="edit pregnancy" />
        </button>
      </div>
      <div className={style.generalInfoMainSection}>
        <div>
          <h3>First consultation</h3>
          <p>{firstConsultationDate}</p>
        </div>
        <div>
          <h3>Doctor</h3>
          <p>{pregnancy.doctor}</p>
        </div>
        <div>
          <h3>Gravida</h3>
          <p>{pregnancy.gravida}</p>
        </div>
        <div>
          <h3>Para</h3>
          <p>{pregnancy.para}</p>
        </div>
        <div>
          <h3>Last Menstrual Period</h3>
          <p>{moment(pregnancy.lastMenstrualPeriod).format("DD.MM.YYYY")}</p>
        </div>
        <div>
          <h3>Estimated Due Date</h3>
          <p>{moment(pregnancy.estimatedDueDate).format("DD.MM.YYYY")}</p>
        </div>
        <fieldset>
          <legend className={style.checkboxSectionLegend}>Exam</legend>
          <div className={style.checkboxWrapper}>
            <label className={style.pregnancyFormCheckboxLabel}>
              <CustomCheckbox
                checked={pregnancy.exam.hasClinicHCGPositive}
                disabled
              />
              <p>Clinic HCG positive</p>
            </label>
          </div>
        </fieldset>
        <fieldset>
          <legend className={style.checkboxSectionLegend}>
            Patient checks
          </legend>
          <div className={style.checkboxWrapper}>
            <label className={style.pregnancyFormCheckboxLabel}>
              <CustomCheckbox checked={pregnancy.smoker} disabled />
              <p>Smoker</p>
            </label>
          </div>
          <div className={style.checkboxWrapper}>
            <label className={style.pregnancyFormCheckboxLabel}>
              <CustomCheckbox
                checked={pregnancy.familyHistoryCongentialAbnormality}
                disabled
              />
              <p>No family history of congenital abnormality</p>
            </label>
          </div>
          <div className={style.checkboxWrapper}>
            <label className={style.pregnancyFormCheckboxLabel}>
              <CustomCheckbox checked={pregnancy.hasChickenPox} disabled />
              <p>Has had chicken pox</p>
            </label>
          </div>
        </fieldset>
      </div>
      <div className={style.bottomSection}>
        <div>
          <h3>History</h3>
          <p>{pregnancy.history}</p>
        </div>
      </div>
      {isModalOpen && (
        <Modal
          title="Edit pregnancy"
          position="center"
          onClose={() => setIsModalOpen(false)}
          hideCancel={true}
          width="800px"
        >
          <EditPregnancyForm
            currentPatientId={patientId}
            closeModal={() => setIsModalOpen(false)}
            tenantVal={tenantVal}
            pregnancy={pregnancy}
          />
        </Modal>
      )}
    </div>
  );
};
