import { useState } from "react";

import style from "./AddLetterModal.module.css";

import Modal from "../helpers/Modal/Modal";
import { AddLetterForm } from "../AddLetterForm/AddLetterForm";

export const AddLetterModal = ({
  patient,
  isDisabled,
  buttonClassName,
  initialData,
  consultationId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <button
        className={`${style.openModalButton} ${buttonClassName}`}
        type="button"
        disabled={isDisabled}
        onClick={() => setIsModalOpen(true)}
      >
        {initialData ? "Edit letter" : "Add letter"}
      </button>
      {isModalOpen && (
        <Modal
          title={initialData ? "Edit letter" : "Add letter"}
          position="center"
          onClose={() => setIsModalOpen(false)}
          hideCancel={true}
          width="800px"
        >
          <AddLetterForm
            patient={patient}
            onSuccess={() => setIsModalOpen(false)}
            onSaveForLater={() => setIsModalOpen(false)}
            initialData={initialData}
            consultationId={consultationId}
          />
        </Modal>
      )}
    </>
  );
};
