import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useLongTermMedications = () => {
  const patientId = localStorage.getItem("kinesin-patientId");

  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error fetching long term medications: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const userName =
    useSelector((state) => state.auth.user?.name) ||
    localStorage.getItem("kinesin-name");

  return useQuery({
    queryKey: ["longTermMedications", patientId, tenantVal, userName],
    queryFn: async () => {
      if (!patientId) return null;

      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/patientmedication/getbypatientidandtyoe/${patientId}/LONGTERM`
      );

      return response.data.map((item) => ({
        ...item,
        entry: userName,
      }));
    },
    enabled: !!patientId,
    onError: handleNetworkError,
  });
};
