import React, { useState, useEffect } from "react";
import CardWithFormModal from "../../../../../components/helpers/CardWithFormModal/CardWithFormModal.jsx";
import {
  GET_PATIENT_HEALTHIDENTIFIERS_BY_ID,
  POST_PATIENT_HEALTHIDENTIFIERS,
  PATIENT_CONTROLER_URL_ID,
} from "../../../../../api/Service.js";
import { useSelector } from "react-redux";
import { createAxiosInstance } from "../../../../../api/axiosConfig.js";
import Pencil from "../../../../../assets/images/edit-green-pencil.svg";

const HealthIdentifiers = ({ patient }) => {
  const [medicalCardNumber, setMedicalCardNumber] = useState(
    patient?.medicalCardNumber || ""
  );

  const patientId = localStorage.getItem("kinesin-patientId");
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const fetchMedicalCardNumber = async (id) => {
    try {
      if (!id) return;
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${PATIENT_CONTROLER_URL_ID}${id}`
      );
      if (response && response.data) {
        console.log(
          "response medical card number",
          response.data.medicalCardNumber
        );
        setMedicalCardNumber(response.data.medicalCardNumber);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMedicalCardNumber(patientId);
  }, []);
  return (
    <>
      <CardWithFormModal
        title="Health Identifiers"
        cardIcon={Pencil}
        staticFieldTitle="Medical Card No."
        staticFieldData={medicalCardNumber}
        apiEndpoints={{
          get: GET_PATIENT_HEALTHIDENTIFIERS_BY_ID,
          post: POST_PATIENT_HEALTHIDENTIFIERS,
        }}
        formFields={[
          {
            label: "Health provider",
            type: "text",
            name: "privateInsuranceProvider",
            placeholder: "enter private insurance provider",
          },
            {
            label: "Private",
            type: "text",
            name: "privateInsuranceNumber",
            placeholder: "enter private insurance number",
          },
          {
            label: "Medical Card No.",
            type: "text",
            name: "medicalCardNo",
            placeholder: "enter medical card number",
          },
          {
            label: "IHI",
            type: "text",
            name: "ihi",
            placeholder: "enter IHI number",
          },
          {
            label: "PPSN",
            type: "text",
            name: "ppsNumber",
            placeholder: "enter PPSN number",
          },
        ]}
        localStorageKeys={{
          tenant: "kinesin-tenant",
          patientId: "kinesin-patientId",
        }}
      />
    </>
  );
};

export default HealthIdentifiers;
