import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { MoreVertical, Trash } from "lucide-react";
import styles from "../AlliedHome.module.css";
import { useUpdateAppointmentStatus } from "../../../../hooks/useUpdateAppointmentStatus";

export const ChangeStatusButton = ({
  disabled,
  appointmentId,
  onSelect,
  deleteAppointment,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { mutate: updateAppointmentStatus } =
    useUpdateAppointmentStatus(onSelect);

  return (
    <>
      <div className={styles.changeStatusButtonWrapper}>
        {deleteAppointment && (
          <button
            onClick={() => {
              deleteAppointment(appointmentId);
            }}
            className={styles.deleteButton}
            aria-label="delete"
          >
            <Trash
              size={20}
              width={20}
              height={20}
              className={styles.deleteIcon}
            />
          </button>
        )}
        <button
          id="sortBy"
          aria-controls={open ? "sortBy" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className={styles.sortByButton}
          disabled={disabled}
          aria-label="change status"
        >
          <MoreVertical size={20} width={20} height={20} />
        </button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className={styles.menuItemWrapper}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              updateAppointmentStatus({
                id: appointmentId,
                status: "ARRIVED",
              });
            }}
            className={styles.menuItem}
          >
            <button>Arrived</button>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              updateAppointmentStatus({
                id: appointmentId,
                status: "IN_CONSULTATION",
              });
            }}
            className={styles.menuItem}
          >
            <button>In Consultation</button>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              updateAppointmentStatus({
                id: appointmentId,
                status: "COMPLETED",
              });
            }}
            className={styles.menuItem}
          >
            <button>Completed</button>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              updateAppointmentStatus({
                id: appointmentId,
                status: "DUE",
              });
            }}
            className={styles.menuItem}
          >
            <button>Due</button>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              updateAppointmentStatus({
                id: appointmentId,
                status: "NO_SHOW",
              });
            }}
            className={styles.menuItem}
          >
            <button>DNA</button>
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};
