import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useMainService = (serviceId) => {
  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error fetching main service: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["mainService", serviceId, tenantVal],
    queryFn: async () => {
      if (!serviceId) return null;

      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/practiceservice/get/${serviceId}`
      );
      return response.data;
    },
    enabled: !!serviceId,
    onError: handleNetworkError,
  });
};
