import React from "react";

import Trash from "../../../assets/images/trash-grey-icon.svg";
import redTrash from "../../../assets/images/red-trash.svg";
import editPencil from "../../../assets/images/gray-edit-pencil.svg";

import s from "./Table.module.css";
import { DeleteModal } from "../../Modal/DeleteModal";

const Table = ({
  data = [],
  columns = [],
  paddingContainer,
  showAddButton = false,
  showDeleteButton = false,
  onDelete,
  onEdit,
  columnStyles = {},
  emptyState,
  deleteModalItemName,
}) => {
  const formatDateArray = (dateArray) => {
    if (!Array.isArray(dateArray) || dateArray.length === 0) return "";
    const [year, month, day] = dateArray;
    const formattedYear = year.toString().slice(-2);
    return `${day}/${month}/${formattedYear}`;
  };

  if (!data.length > 0 && emptyState) {
    return emptyState;
  }

  return (
    <div
      style={paddingContainer && { padding: paddingContainer }}
      className={s.tableContainer}
    >
      <table className={s.table}>
        <thead className={s.thead}>
          <tr>
            {columns.map((column, index) => (
              <th key={index} className={s.th}>
                {column.header}
              </th>
            ))}
            <th className={s.th}></th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0
            ? data.map((entry) => (
                <tr key={entry.id}>
                  {columns.map((column, colIndex) => (
                    <td
                      title={entry[column.field]}
                      key={colIndex}
                      className={s.tdName}
                      style={{
                        ...columnStyles[column.field],
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "200px",
                      }}
                    >
                      {entry[column.field] !== undefined
                        ? column.field === "dateCommenced" &&
                          Array.isArray(entry[column.field])
                          ? formatDateArray(entry[column.field])
                          : entry[column.field]
                        : "-"}
                    </td>
                  ))}
                  <td className={s.actions}>
                    <div>
                      <button
                        className={s.stopButton}
                        onClick={() => onEdit(entry.id)}
                      >
                        <img
                          className={s.stopIcon}
                          src={editPencil}
                          alt="edit button"
                        />
                      </button>
                      <button
                        className={s.stopButton}
                        data-bs-toggle="modal"
                        data-bs-target="#myModal"
                      >
                        <img
                          className={s.stopIcon}
                          src={redTrash}
                          alt="stop button"
                        />
                      </button>
                      <DeleteModal
                        deleteService={() => onDelete(entry.id)}
                        context={deleteModalItemName ? "user" : undefined}
                        itemName={deleteModalItemName}
                      />
                    </div>

                    {showAddButton && (
                      <button className={s.addButton}>
                        <p>Add</p>
                      </button>
                    )}
                    {showDeleteButton && (
                      <button
                        className={s.deleteButton}
                        onClick={() => onDelete(entry.id)}
                      >
                        <img
                          className={s.deleteIcon}
                          src={Trash}
                          alt="delete icon"
                        />
                      </button>
                    )}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
      {!data.length > 0 && (
        <tr>
          <td colSpan={columns.length + 1} className={s.noData}>
            No data available, please add new
          </td>
        </tr>
      )}
    </div>
  );
};

export default Table;
