import React, { useEffect, useState } from "react";
import NavigationBar from "../../PatientDetailGp/components/NavigationBar/NavigationBar.jsx";
import { createAxiosInstance } from "../../../../api/axiosConfig.js";
import {
  PATIENT_CONTROLER_URL_ID,
  DOWNLOAD_DOCUMENT,
} from "../../../../api/Service.js";
import { useDispatch, useSelector } from "react-redux";
import PatientCard from "../PatientCard/PatientCard.jsx";
import DocumentMenu from "../../Documents/DocumentMenu/DocumentMenu";
import EmptyState from "./EmptyState";

import s from "./PatientDocumentsGp.module.css";
import DocumentModal2 from "../../../../components/Modal/DocumentModal2";
import PreviewModal from "../../../../components/Modal/previewDocumentModal";
import PatientDocTable from "./PatientDocTable";
import toast from "react-hot-toast";
import { logoutFromHelper } from "../../../../api/Helper";
import { logout } from "../../../../redux/auth/AuthSlice";
import { removeuser } from "../../../../redux/user/userSlice";
import { useNavigate } from "react-router-dom";
import useFetchPatientDocuments from "../../../../hooks/useFetchPatientDocuments.js";

const PatientDocumentGp = () => {
  const [patient, setPatient] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showUpload, setShowUpload] = useState(false);
  const [menuItem, setMenuItem] = useState("OPD_LETTER");
  const [previewImageData, setPreviewImageData] = useState(null);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState(null);

  const toggleDocumentModal = () => {
    setShowUpload(!showUpload);
  };

  const onDocumentModal2Close = () => {
    setShowUpload(false);
    setMenuItem(menuItem);
  };

  const patientDocTableHandleClick = () => {
    setShowUpload(true);
    setMenuItem("");
  };

  const patientId = localStorage.getItem("kinesin-patientId");
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const fetchPatientById = async (id) => {
    try {
      if (!id) return;
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${PATIENT_CONTROLER_URL_ID}${id}`
      );

      if (response && response.data) {
        setPatient(response.data);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (patientId) {
      fetchPatientById(patientId);
      setMenuItem("OPD_LETTER");
    }
  }, [patientId, tenantVal]);

  useEffect(() => {}, [patient]);

  const openPreview = async (id, patientId) => {
    try {
      const imageData = await downloadDocument1(id, patientId);
      setPreviewImageData(imageData);
      setFileName("download file here");
      setShowPreviewModal(true);
      setSelectedDocumentId(id);
    } catch (error) {
      console.error("Error fetching preview image:", error);
    }
  };

  const closePreview = () => {
    setPreviewImageData(null);
    setShowPreviewModal(false);
  };

  const { data: patientDocuments, refetch: refetchDocuments } =
    useFetchPatientDocuments(patientId, menuItem);

  const docData = patientDocuments?.map((element) => ({
    key: element.id,
    datereceived: element.created,
    documentname: element.name ? element.name : "",
    reviewed_by: element.reviewedBy ? element.reviewedBy.firstName : "",
    reviewed_date: element.reviewTime,
    file: element.fileName ? element.fileName : " ",
    created: element.created,
    name: element.name,
    pname:
      (element.patient && element.patient.firstName
        ? element.patient.firstName
        : "") +
      " " +
      (element.patient && element.patient.surname
        ? element.patient.surname
        : ""),
    patientname: (
      <>
        {element.patient && element.patient.firstName && (
          <a href="" className="patient-title-wrap">
            {element.patient.firstName} {element.patient.surname}
          </a>
        )}
      </>
    ),
    documentType: element.documentTypes ? element.documentTypes.name : "",
    status: (
      <>
        {element.status && (
          <p className="colordark">
            <span className="dot1"></span>
            {element.status ? element.status : ""}
          </p>
        )}
      </>
    ),
    action: (
      <>
        {element.documentUrl !== null && (
          <button
            className="view_file_btn"
            onClick={() => {
              let ID = element ? element.id : null;
              let patientId =
                element && element.patient ? element.patient.id : null;
              openPreview(ID, patientId);
            }}
          >
            View File
          </button>
        )}
      </>
    ),
  }));

  const downloadDocument = async (id, patientId) => {
    try {
      if (!id || !patientId) {
        return;
      }
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${DOWNLOAD_DOCUMENT.replace("{documentId}", id).replace(
          "{patientId}",
          patientId
        )}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          responseType: "blob",
        }
      );
      if (response.data instanceof Blob) {
        // Create download link and trigger download
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `document_${id}.png`); // Assuming it's a PNG image
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (error) {
      errorHandling(error);
    }
  };
  const downloadDocument1 = async (id, patientId) => {
    try {
      if (!id || !patientId) {
        return;
      }
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${DOWNLOAD_DOCUMENT.replace("{documentId}", id).replace(
          "{patientId}",
          patientId
        )}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          responseType: "blob",
        }
      );
      if (response.data instanceof Blob) {
        const reader = new FileReader();
        reader.readAsDataURL(response.data);
        return new Promise((resolve, reject) => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.onerror = reject;
        });
      }
    } catch (error) {
      errorHandling(error);
    }
  };

  const errorHandling = (error) => {
    toast.error(error.message);
    if (error && error.response && error.response.status) {
      if (error.response.status === 403) {
        logoutFromHelper();
        dispatch(logout());
        dispatch(removeuser());
        navigate("/logout");
      }
    }
  };

  const menuClick = (value) => {
    setMenuItem(value);
  };

  return (
    <>
      <NavigationBar />
      <div style={{ margin: "0 auto" }}>
        <div className={s.wrapper}>
          <div className={s.firstColumnWrapper}>
            {isLoading ? (
              <p>Patient documents is loading...</p>
            ) : (
              <>
                <PatientCard />
                <DocumentMenu menuClick={menuClick} />
              </>
            )}
          </div>
          <div className={s.secondColumnWrapper}>
            <div className={s.secondColumnSubWrapper}>
              {docData?.length > 0 ? (
                <PatientDocTable
                  handleClick={patientDocTableHandleClick}
                  docType={menuItem}
                  documentList={docData}
                />
              ) : (
                <EmptyState handleClick={toggleDocumentModal}></EmptyState>
              )}
            </div>
          </div>
        </div>
        <PreviewModal
          show={showPreviewModal}
          onHide={closePreview}
          imageData={previewImageData}
          downloadDocument={downloadDocument}
          fileName={fileName}
          documentId={selectedDocumentId}
        />

        {showUpload && (
          <DocumentModal2
            fromPatient={true}
            patient={patient}
            onClose={onDocumentModal2Close}
            docType={menuItem}
            closeModal={() => setShowUpload(false)}
            onSuccess={refetchDocuments}
          />
        )}
      </div>
    </>
  );
};

export default PatientDocumentGp;
