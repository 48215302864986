import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { removeuser } from "../redux/user/userSlice.js";
import { logoutFromHelper } from "../api/Helper.js";
import { logout } from "../redux/auth/AuthSlice.js";

export const useHandleNetworkError = (onError) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (error) => {
    if (error && error.response) {
      if (error.response.data && error.response.data.message)
        toast.error(error.response.data.message);

      if (error.response.status && error.response.status === 403) {
        logoutFromHelper();
        dispatch(logout());
        dispatch(removeuser());
        navigate("/logout");
      } else {
        onError?.(error);
      }
    }
  };
};
