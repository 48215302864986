import React, { useState } from "react";

import arrowUpIcon from "../../../../assets/images/arrowUp.svg";
import arrowDownIcon from "../../../../assets/images/arrowDown.svg";

import reportsStyles from "../Reports.module.css";
import { getQueryParamsFromObject } from "../utils/getQueryParamsFromObject";

export const ImmunistationsFilters = ({ setQueryParams, setPage }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [filters, setFilters] = useState({
    patientFirstname: "",
    patientLastname: "",
    immunisationType: "",
    immunisationProduct: "",
    immunisationBatchNo: "",
    patientAge: "",
    patientGmsStatus: "",
    patientGender: "",
  });

  return (
    <div>
      <div className={reportsStyles.inputGrid3}>
        <div>
          <label className={reportsStyles.formLabel}>Type</label>
          <select
            as="select"
            className="form-select form-control custom-select"
            name="immunisationType"
            value={filters.immunisationType}
            onChange={(e) => {
              setFilters((prev) => ({
                ...prev,
                immunisationType: e.target.value || null,
              }));
            }}
          >
            <option disabled selected value="">
              Select
            </option>
            <option value="Influenza">Influenza</option>
            <option value="Covid 19">Covid 19</option>
            <option value="Childhood Schedule">Childhood Schedule</option>
            <option value="Pneumococcol">Pneumococcol</option>
          </select>
        </div>
        <div>
          <label className={reportsStyles.formLabel}>Product</label>
          <input
            className="form-control"
            name="immunisationProduct"
            value={filters.immunisationProduct}
            onChange={(e) => {
              setFilters((prev) => ({
                ...prev,
                immunisationProduct: e.target.value || null,
              }));
            }}
          />
        </div>
        <div>
          <label className={reportsStyles.formLabel}>Batch number</label>
          <input
            className="form-control"
            name="immunisationBatchNo"
            value={filters.immunisationBatchNo}
            onChange={(e) => {
              setFilters((prev) => ({
                ...prev,
                immunisationBatchNo: e.target.value || null,
              }));
            }}
          />
        </div>
      </div>
      {isExpanded && (
        <>
          <h4 className={reportsStyles.formSubTitle}>Patient</h4>
          <div className={reportsStyles.inputGrid3}>
            <div>
              <label className={reportsStyles.formLabel}>First name</label>
              <input
                className="form-control"
                name="patientFirstname"
                value={filters.patientFirstname}
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    patientFirstname: e.target.value || null,
                  }));
                }}
              />
            </div>
            <div>
              <label className={reportsStyles.formLabel}>Last name</label>
              <input
                className="form-control"
                name="patientLastname"
                value={filters.patientLastname}
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    patientLastname: e.target.value || null,
                  }));
                }}
              />
            </div>
            <div>
              <label className={reportsStyles.formLabel}>Age</label>
              <input
                className="form-control"
                type="text"
                inputMode="numeric"
                value={filters.patientAge}
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
                name="patientAge"
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    patientAge: e.target.value || null,
                  }));
                }}
              />
            </div>
            <div>
              <label className={reportsStyles.formLabel}>GMS Status</label>
              <select
                as="select"
                className="form-select form-control custom-select"
                name="patientGmsStatus"
                value={filters.patientGmsStatus}
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    patientGmsStatus: e.target.value || null,
                  }));
                }}
              >
                <option disabled selected value="">
                  Select
                </option>
                <option value="medical-card">Medical Card</option>
                <option value="doctor-visit-card">Doctor Visit Card</option>
                <option value="private">Private</option>
              </select>
            </div>
            <div>
              <label className={reportsStyles.formLabel}>Gender</label>
                <select
                    as="select"
                    className="form-select form-control custom-select"
                    name="patientGender"
                    value={filters.patientGender}
                    onChange={(e) => {
                        setFilters((prev) => ({
                            ...prev,
                            patientGender: e.target.value || null,
                        }));
                    }}
                >
                    <option disabled selected value="">
                        Select
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                </select>
            </div>
          </div>
        </>
      )}
        {isExpanded ? (
            <button
                className={reportsStyles.expandButon}
                onClick={() => setIsExpanded(false)}
            >
          less filters <img src={arrowUpIcon} alt="show less" />
        </button>
      ) : (
        <button
          className={reportsStyles.expandButon}
          onClick={() => setIsExpanded(true)}
        >
          more filters <img src={arrowDownIcon} alt="show more" />
        </button>
      )}
      <button
        className={reportsStyles.applyFiltersButton}
        onClick={() => {
          setQueryParams(getQueryParamsFromObject(filters));
          setPage(1);
        }}
      >
        Apply filters
      </button>
    </div>
  );
};
