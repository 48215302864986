import React, { useState, useEffect } from "react";
import Table from "../../../../../../components/helpers/Table/Table.jsx";
import Modal from "../../../../../../components/helpers/Modal/Modal.jsx";
import Select from "../../../../../../components/helpers/Select/Select.jsx";
import "react-datepicker/dist/react-datepicker.css";

import { useSelector } from "react-redux";

import EmptyState from "../../../../../../assets/images/alleries-empty-state.svg";
import s from "./Allergies.module.css";
import { createAxiosInstance } from "../../../../../../api/axiosConfig.js";
import CustomCheckbox from "../../../../../../components/helpers/CustomCheckbox/CustomCheckbox.jsx";
import CustomButton from "../../../../../../components/helpers/CustomButton/CustomButton.jsx";
import Plus from "../../../../../../assets/images/transparrent-plus.svg";
import CustomInput from "../../../../../../components/helpers/CustomInput/CustomInput.jsx";
import toast, { Toaster } from "react-hot-toast";
import { Image } from "react-bootstrap";

const Allergies = () => {
  const [isModalOpen, setIsModalOpen] = useState({ open: false, id: null });
  const [name, setName] = useState("");
  const [allergyReaction, setAllergyReaction] = useState("RASH");
  const [serious, setSerious] = useState(false);
  const [drugAllergy, setDrugAllergy] = useState(false);

  const [data, setData] = useState([]);

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const patientId = localStorage.getItem("kinesin-patientId");

  const userName =
    useSelector((state) => state.auth.user?.name) ||
    localStorage.getItem("kinesin-name");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/allergy/getbyallergytype/ALLERGY/bypatient/${patientId}`
      );
      const dataWithUser = response.data.map((item) => ({
        ...item,
        entry: userName,
        drugAllergyVal: item.drugAllergy ? 'Yes' : '-',
        seriousVal: item.serious ? 'Yes' : '-'
      }));
      console.log(response.data);
      setData(dataWithUser.filter((item) => item.allergyType === "ALLERGY"));
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
      console.error("There was a problem with the axios operation:", error);
    }
  };

  const deleteData = async (id) => {
    try {
      const axiosInstance = createAxiosInstance();
      await axiosInstance.delete(`${tenantVal}/allergy/delete`, {
        data: {
          id,
        },
      });
      toast.success("Data deleted successfully!");
      fetchData();
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
      console.error("There was a problem with the axios operation:", error);
    }
  };

  const handleOpenModal = (id) => {
    setIsModalOpen({ open: true, id: typeof id === "number" ? id : null });
    if (id) {
      const selectedData = data.find((item) => item.id === id);
      console.log(selectedData);

      if (selectedData) {
        setName(selectedData.name);
        setAllergyReaction(selectedData.allergyReaction);
        setSerious(selectedData.serious);
        setDrugAllergy(selectedData.drugAllergy)
      }
    }
  };
  const handleCloseModal = () => {
    setIsModalOpen({ open: false, id: null });
    clearForm();
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const formData = {
      id: isModalOpen.id ?? null,
      name,
      allergyType: "ALLERGY",
      allergyReaction,
      serious,
      patientId: patientId,
      drugAllergy
    };

    try {
      const axiosInstance = createAxiosInstance();
      await axiosInstance.post(`${tenantVal}/allergy`, formData);
      toast.success("Data saved successfully!");
      setIsModalOpen({ open: false, id: null });
      clearForm();
      fetchData();
    } catch (error) {
      toast.error(`Error saving data: ${error.message}`);
      console.error("There was a problem with the axios operation:", error);
    }
  };

  const clearForm = () => {
    setName("");
    setAllergyReaction("RASH");
    setSerious(false);
    setDrugAllergy(false)
  };

  const columns = [
    { header: "Name", field: "name" },
    { header: "Symptoms", field: "allergyReaction" },
    { header: "Drug allergy", field: "drugAllergyVal" },
    { header: "Serious", field: "seriousVal" },
  ];

  return (
    <div className={s.allergyWrapper}>
      <Toaster position="top-center" reverseOrder={false} />
      <div className={s.topWrapper}>
        <h3 className={s.title}>Allergies</h3>
        <CustomButton
          title="Add new"
          buttonHandle={handleOpenModal}
          icon={Plus}
          buttonClassName={s.addBtn}
        />
      </div>
      <Table
        columns={columns}
        data={data}
        tableHeader={true}
        onDelete={deleteData}
        onEdit={handleOpenModal}
        onSave={handleSave}
        deleteModalItemName="Allergy"
        emptyState={
          <div className={s.emptyState}>
            <Image src={EmptyState} alt="category" />
            <div>
              <p className={s.emptyStateTitle}>No Known allergies</p>
              <p className={s.emptyStateText}>
                Once a doctor adds an allergy, it will appear here.
              </p>
            </div>
          </div>
        }
      />

      {isModalOpen.open && (
        <Modal
          width="560px"
          title="Allergies"
          onClose={handleCloseModal}
          onSave={handleSave}
          position="center"
        >
          <form onSubmit={handleSave}>
            <div className={s.inputWrapper}>
              <label>Name</label>
              <CustomInput
                type="text"
                value={name ?? ""}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className={s.inputWrapper}>
              <label>Allergy Type</label>
              <CustomInput type="text" value="ALLERGY" disabled={true} />
            </div>
            <div className={s.inputWrapper}>
              <label>Symptoms</label>
              {/* <CustomInput
                type="text"
                onChange={(e) => setAllergyReaction(e.target.value)}
              /> */}
              <Select
                options={["RASH", "NAUSEA", "ANAPHYLAXIS", "UNKNOWN"]}
                value={allergyReaction ?? "RASH"}
                onChange={(value) => setAllergyReaction(value)}
                required
              />
            </div>
            <div className={s.inputWrapperCheckbox}>
              <CustomCheckbox
                checked={serious ?? false}
                handleChange={(e) => setSerious(e.target.checked)}
              />
              <label className={s.checkboxLabel}>Serious</label>
            </div>
            <div className={s.inputWrapperCheckbox}>
              <CustomCheckbox checked={drugAllergy ?? false}
                              handleChange={(e) => setDrugAllergy(e.target.checked)}/>
              <label className={s.checkboxLabel}>Drug allergy</label>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default Allergies;
