import moment from "moment";
import { createAxiosInstance } from "../../../../../api/axiosConfig";

export const finishPostNatalConsultation = async (
  values,
  tenantVal,
  pregnancyid,
  patientId,
  id
) => {
  const axiosInstance = createAxiosInstance();

  return axiosInstance.post(`${tenantVal}/postnatalconsultation`, {
    tenant: tenantVal,
    patientId: patientId,
    pregnancyId: pregnancyid,
    bornAt: moment(values.bornAt, "DD-MM-YYYY").format("YYYY-MM-DD"),
    deliveryMethod: values.deliveryMethod,
    weightAtBirth: Number(values.weightAtBirth),
    jaudice: values.jaudice ? "Yes" : "No",
    breastFeeding: values.feeding === "Breast",
    forumlaFeeding: values.feeding === "Formula",
    combinationFeeding: values.feeding === "Breast and Formula combination",
    issuesOfNote: values.issuesOfNote,
    phvVisit: values.phnVisit,
    weightInG: Number(values.weight),
    lenghtInCm: Number(values.length),
    headCircumferenceInCm: Number(values.headCircumference),
    crt: values.crt,
    umbilicus: values.umbilicus,
    barlowAndOrlaniTest: values.barlowAndOrlaniTest,
    heart: values.heart,
    fonantelles: values.fonantelles,
    buccalMucosa: values.buccalMucosa,
    feet: values.feet,
    impression: values.impression,
    plan: values.plan,
    mothesrGravida: values.gravida,
    motherPara: values.para,
    issueDuringOrSincePregnancy: values.issues,
    postNatalMood: values.postNatalMood,
    motherSupports: values.supports,
    motherContraception: values.contraception,
    motherMirena: values.mirena,
    id,
  });
};
