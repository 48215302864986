import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useConfirmPayment = (consultationId) => {
  const queryClient = useQueryClient();

  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error confirming payment: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useMutation({
    mutationFn: async () => {
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.post(
        `${tenantVal}/consultationpayment/confirmpayment/consultationid/${consultationId}`
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("Payment completed successfully");
      queryClient.invalidateQueries(["activeConsultation"]);
    },
    onError: handleNetworkError,
  });
};
