import { createAxiosInstance } from "../../../../api/axiosConfig.js";

const getPath = (reportType) => {
  switch (reportType) {
    case "immunisations":
      return "get/immunisations/page";
    case "prescriptions":
      return "get/prescriptions/page";
    case "investigations":
      return "get/invesgigations/page";
    case "letters":
      return "get/letters/page";
    case "accounting":
      return "get/accounting/page";
    case "appointments":
      return "get/appointments/page";
    case "medico-legal":
      return "get/medico-legal/page";
    case "audit-log":
      return "get/auditlog/page";
    default:
      return "get/patients/page";
  }
};

export const fetchReports = async (
  tenantVal,
  reportType,
  queryParams,
  page
) => {
  const axiosInstance = createAxiosInstance();
  const response = await axiosInstance.get(
    `${tenantVal}/reports/${getPath(
      reportType
    )}?page=${page}${queryParams.replace("?", "&")}`
  );

  return response.data;
};
