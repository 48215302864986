import React from "react";
import s from "./PatientDocTable.module.css";
import redTrash from "../../../../assets/images/red-trash.svg";
import { Toaster } from "react-hot-toast";
import CustomButton from "../../../../components/helpers/CustomButton/CustomButton";
import Plus from "../../../../assets/images/transparrent-plus.svg";
import { Empty, Table } from "antd";
import { DeleteModal } from "../../../../components/Modal/DeleteModal";
import useDeleteDocument from "../../../../hooks/useDeleteDocument";

const PatientDocTable = ({ documentList, handleClick, docType, onDelete }) => {
  const { mutate: deleteDocument } = useDeleteDocument();

  const columns = [
    {
      title: "Date received",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "Document name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Type",
      dataIndex: "documentType",
      key: "documentType",
    },
    {
      title: "Reviewed by",
      dataIndex: "reviewed_by",
      key: "reviewed_by",
    },
    {
      title: "Reviewed Date",
      dataIndex: "reviewed_date",
      key: "reviewed_date",
    },
    {
      title: "File",
      dataIndex: "file",
      key: "file",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
    },
    {
      title: "",
      key: "action",
      render: (text, record) => (
        <>
          <button
            className={s.stopButton}
            data-bs-toggle="modal"
            data-bs-target="#myModal"
          >
            <img className={s.stopIcon} src={redTrash} alt="stop button" />
          </button>
          <DeleteModal
            deleteService={async () => {
              await deleteDocument(record.key);
              onDelete?.();
            }}
            context="user"
            itemName="document"
          />
        </>
      ),
    },
  ];

  const formatDocType = (type) => {
    const formatted = type.replace(/_/g, "-").toLowerCase();
    return formatted.charAt(0).toUpperCase() + formatted.slice(1);
  };

  return (
    <div className={s.topWrapper}>
      <Toaster position="top-center" reverseOrder={false} />
      <div style={{ width: "100%" }}>
        <div style={{ width: "100%" }}>
          <div className={s.titleWrapper}>
            <h3 className={s.title}>{`Patient documents: ${formatDocType(
              docType
            )} `}</h3>
          </div>
          <div
            className="d-flex align-items-center allied-documents-wrap patientsheading"
            style={{ float: "right", marginBottom: "20px" }}
          >
            <div style={{ float: "right" }}>
              <CustomButton
                title="Add document"
                buttonHandle={handleClick}
                icon={Plus}
                buttonClassName={s.addButton}
              />
            </div>
          </div>
        </div>

        <div className="custom-table-wrapper patients-table">
          <Table
            dataSource={documentList}
            columns={columns}
            locale={{
              emptyText: <Empty description="No Records Found" />,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PatientDocTable;
