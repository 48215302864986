import { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import styles from "./ReportTable.module.css";
import { Loader } from "../../../../../../components/Loader/Loader";
import { GripVertical } from "lucide-react";
import { Pagination } from "@mui/material";

export const ReportTable = ({
  title,
  items,
  isLoading,
  columns,
  page,
  setPage,
  totalPages,
  totalItems,
}) => {
  const [columnResizing, setColumnResizing] = useState({});
  const start = (page - 1) * 10 + 1;
  const end = Math.min(page * 10, totalItems);

  const table = useReactTable({
    data: items,
    columns,
    state: {
      columnResizing,
    },
    onColumnResizingChange: setColumnResizing,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: "onChange",
    enableColumnResizing: true,
    defaultColumn: {
      minSize: 25,
      maxSize: 1000,
      size: 150,
    },
  });

  return (
    <Paper className={styles.reportTablePaper}>
      <div className={styles.reportTableWrapper}>
        <h3>{title}</h3>
        <div className={styles.reportTable}>
          {isLoading && <Loader>Loading reports...</Loader>}

          {table.getRowModel().rows.length <= 0 && !isLoading && (
            <h4 className={styles.reportNoReportsWarning}>
              There are no reports matching current filters.
            </h4>
          )}

          {table.getRowModel().rows.length > 0 && !isLoading && (
            <TableContainer className={styles.tableContainer}>
              <Table stickyHeader>
                <TableHead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow
                      key={headerGroup.id}
                      className={styles.tableHeaderRow}
                    >
                      {headerGroup.headers.map((header) => (
                        <TableCell
                          key={header.id}
                          className={styles.tableHeaderCell}
                          style={{
                            width: header.getSize(),
                            position: "relative",
                            overflow: "visible",
                            background: "transparent",
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {header.column.getCanResize() && (
                            <div
                              onMouseDown={header.getResizeHandler()}
                              onTouchStart={header.getResizeHandler()}
                              className={`${styles.resizer} ${
                                header.column.getIsResizing()
                                  ? styles.isResizing
                                  : ""
                              }`}
                            >
                              <GripVertical className={styles.resizerIcon} />
                            </div>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {table.getRowModel().rows.map((row) => (
                    <TableRow key={row.id} className={styles.tableRow}>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          className={styles.tableCell}
                          style={{
                            width: cell.column.getSize(),
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
                <div className={styles.paginationWrapper}>
                  <p className={styles.resultsInfo}>
                    {start}-{end} of {totalItems} results
                  </p>
                  <Pagination
                    count={totalPages}
                    onChange={(_event, value) => {
                      setPage(value);
                    }}
                    page={page}
                  />
                </div>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </Paper>
  );
};
