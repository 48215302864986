import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";
import { createAxiosInstance } from "../api/axiosConfig";

const useDeleteDocument = () => {
  const queryClient = useQueryClient();

  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error while deleting service: ${error.message}`)
  );
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useMutation({
    mutationFn: async (documentId) => {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.delete(
        `${tenantVal}/document/delete`,
        {
          data: {
            id: Number(documentId),
          },
        }
      );
      return response;
    },
    onSuccess: () => {
      toast.success("Document deleted successfully");
      queryClient.invalidateQueries(["patientDocuments"]);
    },
    onError: handleNetworkError,
  });
};

export default useDeleteDocument;
