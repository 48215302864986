import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useCreateTask = () => {
  const queryClient = useQueryClient();

  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error while creating task: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useMutation({
    mutationFn: async (userData) => {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.post(`${tenantVal}/task`, userData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    },
    onSuccess: () => {
      toast.success("New Task created successfully");
      queryClient.invalidateQueries(["tasks", "closedPaginatedTasks"]);
    },
    onError: handleNetworkError,
  });
};
