import React, { useEffect, useState, useRef } from "react";
import s from "./DocumentModal2.module.css";

import { TaskPriority } from "../../Utils/Constant";
import {
  GET_ALL_DOCUMENT_TYPES,
  PATIENT_DOCUMENTS_UPLOAD,
  PATIENT_SEARCH_AUTOCOMPLETE_URL,
  STAFF_MEMBERS_CONTROLER_URL,
} from "../../api/Service";
import { createAxiosInstance } from "../../api/axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { logoutFromHelper } from "../../api/Helper";
import { logout } from "../../redux/auth/AuthSlice";
import { removeuser } from "../../redux/user/userSlice";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Error from "../Error/Error";
import Modal from "../helpers/Modal/Modal";

const DocumentModal = ({
  handleSaveTask,
  refreshByPatientIdApi,
  refreshAllApi,
  fromPatient,
  fetchDocumentById,
  onClose,
  docType,
  patient,
  closeModal,
  onSuccess,
}) => {
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Staff memebers data
  const [staffMember, setStaffMember] = useState("");
  const [staffMemberId, setStaffMemberId] = useState(null);
  const [showOption, setShowOption] = useState(false);
  const [imageAsPreview, setImageAsPreview] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [fullName, setFullName] = useState("");
  let [errorList, setErrorList] = useState([]);

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    patientId: patient?.id ?? "",
    documentType: "",
    otherDocumentType: "",
    status: "",
    staffMemberId: "",
    priority: "-1",
  });

  const [documentTypes, setDocumentTypes] = useState(false);
  const patientId = localStorage.getItem("kinesin-patientId");

  let tenantVal = useSelector((state) => state.auth.user?.tenant);
  if (!tenantVal) tenantVal = localStorage.getItem("kinesin-tenant");

  const defaultDoctor = () => {
    try {
      if (patient && patient.doctorDetails) {
        setStaffMemberId(patient.doctorDetails.id);
        setFormData({
          ...formData,
          ["staffMemberId"]: patient.doctorDetails
            ? patient.doctorDetails.id
            : null,
        });
      }
    } catch (error) {
      errorHandling(error);
    }
  };

  const fetchDocumentTypes = async () => {
    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${GET_ALL_DOCUMENT_TYPES}`
      );
      let docTypes = [];
      if (response && response.data) {
        response.data.forEach((element) => {
          if (element && element.id && element.name) {
            docTypes.push({ value: element.id, name: element.name });
          }
        });
      }
      setDocumentTypes(docTypes);

      const today = moment().format("YYYY-MM-DD");
      setFormData({
        ...formData,
        documentType: docTypes.find((dt) => dt.name === docType)?.value ?? "",
        name: docType.toLowerCase().replaceAll("_", " ") + "-" + today,
      });
    } catch (error) {
      errorHandling(error);
    }
  };

  // const handleCloseModal = () => {
  //   if (fileInputRef.current) {
  //     fileInputRef.current.value = null; // Reset the file input value to clear the selection
  //   }
  //   resetFormParam();
  //   resetFormState();
  // };
  // const resetFormState = () => {
  //   if (fromPatient) {
  //     setFullName(patient ? patient.firstName + " " + patient.surname : "");
  //     setFormData({
  //       id: "",
  //       name: "",
  //       patientId: patient.id,
  //       documentType: "",
  //       otherDocumentType: "",
  //       status: "",
  //       staffMemberId: "",
  //       priority: "-1",
  //     });
  //   }

  //   setImageFile("");
  //   setImageAsPreview("");
  // };

  // Staff member data//
  const fetchUser = async () => {
    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${STAFF_MEMBERS_CONTROLER_URL}`
      );

      setStaffMember(response.data);
    } catch (error) {
      errorHandling(error);
    }
  };

  useEffect(() => {
    setFullName(patient ? patient.firstName + " " + patient.surname : "");
    setImageAsPreview("");
    setImageFile("");
    setFormData({
      id: "",
      name: "",
      patientId: patient && patient.patient_id ? patient.patient_id : "",
      documentType: "",
      otherDocumentType: "",
      status: "",
      staffMemberId: "",
      priority: "-1",
    });
    // fetchPatientById();
    fetchUser();
    fetchDocumentTypes();
    defaultDoctor();
  }, [tenantVal, patient]);

  const [options, setOptions] = useState([]);

  const getData = async (searchTerm) => {
    if (!searchTerm) setOptions([]);

    const axiosInstance = createAxiosInstance();
    const response = await axiosInstance.get(
      `${tenantVal}${PATIENT_SEARCH_AUTOCOMPLETE_URL}/${searchTerm}`
    );

    if (response && response.data && response.data.length > 0) {
      const updatedOptions = response.data.map((p) => {
        return {
          id: p.id,
          title:
            p.firstName +
            (p.surname ? " " + p.surname : "") +
            (p.dateOfBirth
              ? " (" + moment(p.dateOfBirth).format("DD-MM-YYYY") + ")"
              : ""),
        };
      });
      setOptions(updatedOptions);
    } else setOptions([]);
  };

  const onInputChange = (event, value, reason) => {
    if (value && value.length > 0) {
      getData(value);
    } else {
      setTimeout(() => {
        setOptions([]);
      }, 0);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleStaffChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const [formParam, setFormParam] = useState({
    errors: {
      email: "",
    },
    submitted: false,
  });

  const validateForm = () => {
    let formIsValid = true;
    let f = Object.assign({}, formParam);
    f.errors.documentType = "";
    f.errors.status = "";
    f.errors.patient = "";
    f.errors.priority = "";
    f.errors.name = "";
    if (!formData.documentType) {
      formIsValid = false;
      f.errors.documentType = "*Please select document type.";
    }
    if (!formData.patientId && !patient && imageFile) {
      formIsValid = false;
      f.errors.patient = "*Please select patient.";
    }
    if (!formData.name) {
      formIsValid = false;
      f.errors.name = "*Please enter document name.";
    }
    setFormParam(f);
    return formIsValid;
  };

  const resetFormParam = () => {
    setImageAsPreview(null);
    setImageFile(null);
    setFormParam({
      errors: {
        email: "",
      },
      submitted: false,
    });
  };

  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleSave = async () => {
    errorList = [];
    setErrorList(errorList);

    let id;
    formParam.submitted = true;

    if (formData.documentType == "") {
      errorList.push("Document type is mandatory");
    }

    if (formData.name == "") {
      errorList.push("Document name is mandatory");
    }

    if (imageFile == "") {
      errorList.push("You must upload a file");
    }

    setErrorList(errorList);

    if (validateForm() && errorList.length == 0) {
      formParam.submitted = true;
    } else return;

    let documentUrl;
    if (imageFile && formData.documentType) {
      try {
        const data = new FormData();
        data.append("file", imageFile);

        const axiosInstance = createAxiosInstance();
        const response = await axiosInstance.post(
          `${tenantVal}${PATIENT_DOCUMENTS_UPLOAD}` +
            "/" +
            formData.documentType +
            "/patient/" +
            patient.id,
          data
        );

        if (response && response.data)
          setFormData({
            ...formData,
            ["id"]:
              response && response.data && response.data.id
                ? response.data.id
                : "",
          });
        id =
          response && response.data && response.data.id ? response.data.id : "";
        documentUrl = response.data.documentUrl;
        // onClose();
        onSuccess();
      } catch (error) {
        errorHandling(error);
        return;
      }
    }

    try {
      if (typeof handleSaveTask === "function") {
        handleSaveTask();
      }
      setFullName(patient ? patient.firstName + " " + patient.surname : "");
      setFormData({
        id: "",
        patientId: patient && patient.patient_id ? patient.patient_id : "",
        documentType: "",
        otherDocumentType: "",
        status: "",
        staffMemberId: "",
        priority: "-1",
        name: "",
      });
      setImageFile("");
      setImageAsPreview("");
      if (typeof fetchDocumentById === "function") {
        fetchDocumentById();
      }

      onClose();
      $("#idClosePopup").trigger("click");
    } catch (error) {
      errorHandling(error);
    }
  };

  const errorHandling = (error) => {
    toast.error(error.message);
    if (error && error.response && error.response.status) {
      if (error.response.status == 403) {
        logoutFromHelper();
        dispatch(logout());
        dispatch(removeuser());
        navigate("/logout");
      }
    }
  };
  const handleImagePreview = async (e) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];

    setImageAsPreview(image_as_base64);
    setImageFile(image_as_files);
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="dashBcontbody addpatentdashbord">
        <Error errorList={errorList}></Error>

        <Modal
          title="Upload patient document"
          onSave={handleSave}
          onClose={closeModal}
        >
          <form>
            <div className="row">
              <div className="col-md-12 mb-3">
                <label className="mb-1">Upload Document</label>
                <div className="documentUploadbtn">
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleImagePreview}
                  />
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <label className="requiredValidator mb-1">Document type</label>
                <select
                  as="select"
                  className="form-select form-control custom-select"
                  name="documentType"
                  value={formData.documentType}
                  onChange={(e) => {
                    let today = new Date();
                    today = moment(today).format("YYYY-MM-DD");
                    let docType = "";
                    for (var docT in documentTypes) {
                      var docTyObj = documentTypes[docT];
                      if (docTyObj.value == e.target.value)
                        docType = docTyObj.name;
                    }

                    formData["name"] =
                      docType.toLowerCase().replaceAll("_", " ") + "-" + today;
                    if (e.target.value === "Other") {
                      setShowOption(true);
                      handleChange(e);
                    } else {
                      setShowOption(false);
                      handleChange(e);
                    }
                  }}
                >
                  <option value="">Select</option>
                  {documentTypes &&
                    documentTypes.map((Status, id) => (
                      <option value={Status.value} key={id}>
                        {Status.name}
                      </option>
                    ))}
                </select>

                {showOption ? (
                  <div className="mt-3 ">
                    <input
                      className="form-control"
                      id="designation"
                      value={formData.otherDocumentType}
                      type="text"
                      name="otherDocumentType"
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter New Document Type"
                      required
                    />
                  </div>
                ) : (
                  ""
                )}
                {formParam.submitted && (
                  <div className="errorMsg text-start">
                    {formParam.errors.documentType}
                  </div>
                )}
              </div>

              <div className="col-md-6 mb-3">
                <label className="mb-1">Priority</label>
                <div className="priorityform">
                  {/* <div className="pridot"></div> */}
                  <div
                    className={
                      formData.priority == 0
                        ? "pridot"
                        : formData.priority == 1
                        ? "pridotYellow"
                        : formData.priority == 2
                        ? "pridotGreen"
                        : ""
                    }
                  ></div>
                  <select
                    className="form-select form-control"
                    name="priority"
                    value={formData.priority}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  >
                    <option value={-1}>Select</option>
                    {TaskPriority.map((Priority, id) => (
                      <option value={Priority.value} key={id}>
                        {Priority.name}
                      </option>
                    ))}
                  </select>
                  {formParam.submitted && (
                    <div className="errorMsg text-start">
                      {formParam.errors.priority}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <label className="mb-1">Patient</label>
                <input
                  className={s.input}
                  disabled
                  value={`${patient?.firstName} ${patient?.surname}`}
                />

                {/* <Autocomplete
                      // disabled={isPatientDisabled}
                      disablePortal
                      id="combo-box-demo"
                      value={fullName || null}
                      // value={patient.patient_name}
                      options={options}
                      getOptionLabel={(option) =>
                        option && option.title ? option.title : fullName
                      }
                      onInputChange={onInputChange}
                      onChange={(event, newValue) => {
                        setFullName(newValue ? newValue.title : "");
                        setFormData({
                          ...formData,
                          ["patientId"]: newValue ? newValue.id : "",
                        });
                        if (newValue?.id) {
                          fetchPatientById(newValue.id);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                    /> */}
                {imageFile && !patient && formParam.submitted && (
                  <div className="errorMsg text-start">
                    {formParam.errors.patient}
                  </div>
                )}
              </div>

              <div className="col-md-6 mb-3">
                <label className="mb-1">Assigned to staff member </label>
                <select
                  as="select"
                  className="form-select form-control custom-select"
                  name="staffMemberId"
                  value={
                    formData.staffMemberId
                    //staffMemberId
                  }
                  onChange={(e) => {
                    handleStaffChange(e);
                  }}
                >
                  <option value="">Select</option>
                  {staffMember
                    ? staffMember.map((Staff) => (
                        <option value={Staff.id} key={Staff.id}>
                          {Staff.firstName} {Staff.lastName}
                        </option>
                      ))
                    : ""}
                </select>
                {formParam.submitted && (
                  <div className="errorMsg text-start">
                    {formParam.errors.staffMemberId}
                  </div>
                )}
              </div>

              <div className="col-md-12 mb-3">
                <label className="requiredValidator mb-1">
                  Override document name
                </label>
                <div className="priorityform">
                  <input
                    className={s.input}
                    name="name"
                    value={formData.name}
                    placeholder="Document Name"
                    onChange={handleChange}
                  />
                  {formParam.submitted && (
                    <div className="errorMsg text-start">
                      {formParam.errors.name}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default DocumentModal;
