import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { PATIENT_DOCUMENTS_GET_BY_PATIENT_ID_TYPE } from "../api/Service";
import { createAxiosInstance } from "../api/axiosConfig";

const useFetchPatientDocuments = (patientId, dpcType) => {
  const tenant =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const fetchPatientDocuments = async () => {
    if (!patientId) return [];
    const axiosInstance = createAxiosInstance();
    const response = await axiosInstance.get(
      `${tenant}${PATIENT_DOCUMENTS_GET_BY_PATIENT_ID_TYPE}`.replace(
        "{patientId}",
        patientId
      ) + dpcType
    );

    return response.data;
  };

  return useQuery({
    queryKey: ["patientDocuments", patientId, dpcType],
    queryFn: fetchPatientDocuments,
    enabled: !!patientId,
    onError: (error) => {
      console.error("Error fetching patient documents:", error);
    },
  });
};

export default useFetchPatientDocuments;
