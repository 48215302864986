import { createAxiosInstance } from "../../../../../api/axiosConfig.js";

export const fetchImmunizations = async (tenantVal, patientId) => {
  // Noel told us to leave the immunistaion type and always use one endpoint for now

  // const axiosInstance = createAxiosInstance();
  // const response = await axiosInstance.get(
  //   `${tenantVal}/immunisation/getbytype/${immunisationType}`
  // );
  // return response.data;

  const axiosInstance = createAxiosInstance();
  const response = await axiosInstance.get(
    `${tenantVal}/patientimmunisation/getbypatientid/${patientId}`
  );
  return response.data;
};
