import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useMyPaginatedPatients = (tenant, page = 1, search) => {
  const handleNetworkError = useHandleNetworkError();

  return useQuery({
    queryKey: ["myPaginatedPatients", tenant, page, search],
    queryFn: async () => {
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.get(
        search.length < 3
          ? `${tenant}/patient/mypatients/get`
          : `${tenant}/patient/mypatients/search/page/${search}`,
        {
          params: {
            page,
          },
        }
      );
      return response.data;
    },
    onError: handleNetworkError,
  });
};
