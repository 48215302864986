import React from "react";
import s from "./CustomButton.module.css";

const CustomButton = ({
  title,
  icon = true,
  buttonHandle,
  buttonClassName,
  titleClassName,
  disabled,
}) => {
  return (
    <button
      className={`${buttonClassName} ${s.btnWrapper}${
        disabled ? s.disabled : ""
      }`}
      onClick={buttonHandle}
      disabled={disabled}
    >
      <p
        style={{ fontSize: "16px" }}
        className={`${s.btnTitle} ${titleClassName}`}
      >
        {title}
      </p>
      {icon && <img src={icon} alt={title} />}
    </button>
  );
};

export default CustomButton;
