import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import moment from "moment";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useUpdatePatient = (patientId) => {
  const queryClient = useQueryClient();

  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error updating patient: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useMutation({
    mutationFn: async (patientData) => {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.post(`${tenantVal}/patient`, {
        ...patientData,
        dateOfBirth: patientData.dateOfBirth
          ? moment(patientData.dateOfBirth).format("YYYY-MM-DD")
          : null,
      });
      return response.data;
    },
    onSuccess: () => {
      toast.success("Data saved successfully!");
      queryClient.invalidateQueries(["patient", patientId]);
    },
    onError: handleNetworkError,
  });
};
