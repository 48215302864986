import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import style from "./PregnancyPostNatalView.module.css";

import { ConsultationPreview } from "../../../../../components/ConsultationPreview/ConsultationPreview";
import { PregnancyPostNatalConsultationContent } from "./PregnancyPostNatalConsultation/PregnancyPostNatalConsultationContent";
import { PregnancyNoPostNatalConsultationsFound } from "./PregnancyNoPostNatalConsultationsFound/PregnancyNoPostNatalConsultationsFound";
import { fetchPostNatalConsultations } from "../utils/fetchPostNatalConsultations";
import { PregnancyAddPostNatalConsultationForm } from "./PregnancyAddPostNatalConsultationForm/PregnancyAddPostNatalConsultationForm";
import { PregnancyLoader } from "../components/PregnancyLoader/PregnancyLoader";
import { PregnancyEditPostNatalConsultationForm } from "./PregnancyEditPostNatalConsultationForm/PregnancyEditPostNatalConsultationForm";

export const PregnancyPostNatalView = ({ pregnancy, tenantVal, patientId }) => {
  const [isEditView, setIsEditView] = useState({ open: false, id: null });
  const [isInConsultationMode, setIsInConsultationMode] = useState(false);
  const { isLoading, data: postNatalConsultations } = useQuery({
    queryKey: ["postNatalConsultations", pregnancy.id, tenantVal],
    queryFn: () => fetchPostNatalConsultations(pregnancy.id, tenantVal),
  });

  if (isLoading) {
    return (
      <PregnancyLoader>Loading post natal consultations...</PregnancyLoader>
    );
  }

  if (isEditView.open) {
    return (
      <div
        className={`${style.pregnancyPostNatalView} ${style.pregnancyPostNatalViewConsultationMode}`}
      >
        <div className={style.pregnancyPostNatalViewTitleWrapper}>
          <h1 className={style.pregnancyPostNatalViewTitle}>
            Edit Post Natal consultation
          </h1>
        </div>
        <PregnancyEditPostNatalConsultationForm
          closeView={() => {
            setIsEditView({ open: false, id: null });
          }}
          tenantVal={tenantVal}
          pregnancyId={pregnancy.id}
          pregnancyHistory={pregnancy.history}
          patientId={patientId}
          postNatalConsultation={postNatalConsultations.find(
            (consultation) => consultation.id === isEditView.id
          )}
        />
      </div>
    );
  }

  if (postNatalConsultations?.length < 1) {
    return (
      <>
        {isInConsultationMode ? (
          <PregnancyAddPostNatalConsultationForm
            closeView={() => setIsInConsultationMode(false)}
            tenantVal={tenantVal}
            pregnancyId={pregnancy.id}
            patientId={patientId}
          />
        ) : (
          <PregnancyNoPostNatalConsultationsFound
            onStartConsultationButtonClick={() => setIsInConsultationMode(true)}
          />
        )}
      </>
    );
  }

  return (
    <div
      className={`${style.pregnancyPostNatalView} ${
        isInConsultationMode ? style.pregnancyPostNatalViewConsultationMode : ""
      }`}
    >
      <div className={style.pregnancyPostNatalViewTitleWrapper}>
        <h1 className={style.pregnancyPostNatalViewTitle}>
          Post Natal consultations
        </h1>
        {!isInConsultationMode && (
          <button
            className={style.pregnancyPostNatalStartConsultationButton}
            onClick={() => setIsInConsultationMode(true)}
          >
            Start consultation
          </button>
        )}
      </div>
      {isInConsultationMode ? (
        <PregnancyAddPostNatalConsultationForm
          closeView={() => setIsInConsultationMode(false)}
          tenantVal={tenantVal}
          pregnancyId={pregnancy.id}
          patientId={patientId}
        />
      ) : (
        <div className={style.pregnancyPostNatalStartConsultationsWarpper}>
          {postNatalConsultations.map((consultation) => (
            <ConsultationPreview
              key={consultation.id}
              consultation={consultation}
              text={`Issues of note: ${consultation.subject.issuesOfNote}`}
              onEditConsultation={() =>
                setIsEditView({ open: true, id: consultation.id })
              }
            >
              <PregnancyPostNatalConsultationContent
                consultation={consultation}
              />
            </ConsultationPreview>
          ))}
        </div>
      )}
    </div>
  );
};
