import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useCreateReferral = () => {
  const queryClient = useQueryClient();

  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error while creating referral: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useMutation({
    mutationFn: async (referralData) => {
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.post(
        `${tenantVal}/patientreferral`,
        referralData
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("Referral saved successfully!");
      queryClient.invalidateQueries([
        "activeConsultation",
        "activeConsultationReferral",
      ]);
    },
    onError: handleNetworkError,
  });
};
