import moment from "moment";

export const createDateFromArray = (dateArray) => {
  if (!Array.isArray(dateArray) || dateArray.length < 6) {
    throw new Error("Invalid date array");
  }

  const [year, month, day, hour, minute] = dateArray;

  const date = moment([year, month - 1, day, hour, minute]);

  return date;
};
