import styles from "../AlliedHome.module.css";

export const getAppointmentStatusColor = (status) => {
  if (status === "NO_SHOW") return styles.cancelled;
  if (status === "ARRIVED") return styles.arrived;
  if (status === "IN_CONSULTATION") return styles.inConsultation;
  if (status === "COMPLETED") return styles.completed;

  return styles.default;
};

export const getAppointmentStatusColorForTable = (status) => {
  if (status === "NO_SHOW") return styles.cancelled2;
  if (status === "ARRIVED") return styles.arrived2;
  if (status === "IN_CONSULTATION") return styles.inConsultation2;
  if (status === "COMPLETED") return styles.completed2;

  return styles.default2;
};

export const getAppointmentStatusLabel = (status) => {
  if (status === "NO_SHOW") return "DNA";
  if (status === "ARRIVED") return "Arrived";
  if (status === "COMPLETED") return "Completed";
  if (status === "IN_CONSULTATION") return "In Consultation";

  return "";
};
