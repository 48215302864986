import { useState } from "react";
import moment from "moment";

import srcicon from "../../../../../../../assets/images/srcicon.svg";

import styles from "./Table.module.css";
import { SortByButton } from "./SortByButton";
import { Toaster } from "react-hot-toast";
import { Loader2 } from "lucide-react";

export const Table = ({ items, tableName, isLoading, emptyState }) => {
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState("newest-first");

  const sortedAndFilteredItems =
    items
      ?.sort((a, b) => {
        if (!a.dateRecorded && !b.dateRecorded) return 0;
        if (!a.dateRecorded) return sorting === "newest-first" ? 1 : -1;
        if (!b.dateRecorded) return sorting === "newest-first" ? -1 : 1;

        if (sorting === "newest-first") {
          return (
            moment(b.dateRecorded).valueOf() - moment(a.dateRecorded).valueOf()
          );
        }
        return (
          moment(a.dateRecorded).valueOf() - moment(b.dateRecorded).valueOf()
        );
      })
      .filter((item) =>
        item.idc.toLowerCase().includes(search.toLowerCase())
      ) || [];

  if (emptyState && sortedAndFilteredItems.length === 0) {
    return emptyState;
  }

  const renderTableContent = () => {
    if (isLoading) {
      return (
        <div className={styles.emptyState}>
          <Loader2 className={styles.loadingSpinner} size={24} />
          <p className={styles.emptyStateTitle}>Loading data...</p>
          <p className={styles.emptyStateText}>
            Please wait while we fetch the medical history.
          </p>
        </div>
      );
    }

    if (sortedAndFilteredItems.length === 0) {
      return (
        <div className={styles.emptyState}>
          <p className={styles.emptyStateTitle}>No items found</p>
          <p className={styles.emptyStateText}>
            {search
              ? "Try adjusting your search or filters to find what you're looking for."
              : "There are no items to display at this time."}
          </p>
        </div>
      );
    }

    return (
      <ul className={styles.tableItems}>
        {sortedAndFilteredItems.map((item) => (
          <li className={styles.tableItem} key={item.dateRecorded + item.idc}>
            <p aria-describedby={`${tableName}-table-data-recorded`}>
              {item.dateRecorded
                ? moment(item.dateRecorded).format("DD/MM/YYYY")
                : "N/A"}
            </p>
            <p aria-describedby={`${tableName}-table-icd-code`}>{item.idc}</p>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className={styles.tableWarpper}>
        <div className={styles.table}>
          <div className={styles.tableTopBarWrapper}>
            <div className={`${styles.searchWrapper} search_bar`}>
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                disabled={isLoading}
              />
              <img src={srcicon} alt="" />
            </div>
            <SortByButton
              selectedItem={sorting}
              setSelectedItem={setSorting}
              disabled={isLoading}
            />
          </div>
          <div className={styles.tableHeader}>
            <p id={`${tableName}-table-data-recorded`}>Data recorded</p>
            <p id={`${tableName}-table-icd-code`}>ICD code</p>
          </div>
          {renderTableContent()}
        </div>
      </div>
    </>
  );
};
