import React, { useEffect, useState } from "react";
import { useMyAppointments } from "../../../hooks/useMyAppointments";
import moment from "moment";
import HorizontalCalendarNew1 from "../../../components/HorizontalCalendarNew1";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import s from "./MyAppointments.module.css";
import AppointmentsTable from "./components/AppointmentsTable";
import deleticon from "../../../assets/images/deleticon.svg";
import { createAxiosInstance } from "../../../api/axiosConfig";
import { PRACTICE_SERVICE_CONTROLER_URL, WHO_AM_I } from "../../../api/Service";
import { useDispatch, useSelector } from "react-redux";
import { logoutFromHelper } from "../../../api/Helper";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../redux/auth/AuthSlice";
import { removeuser } from "../../../redux/user/userSlice";
import AddAppointmentModal from "./components/AddAppointmentModal";
import Modal from "../../../components/helpers/Modal/Modal";
import { useDeleteAppointment } from "../../../hooks/useDeleteAppointment";

const defaultDurationInMins = "15";
const selectedView = "week";

const MyAppointments = () => {
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");
  const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedDate, setSelectedDate] = useState(moment().date());
  const [deleteAppointmentId, setDeleteAppointmentId] = useState(false);

  const selectedMoment = moment(
    `${selectedYear}-${selectedMonth}-${selectedDate}`,
    "YYYY-MM-DD"
  );

  const nextSevenDays = Array.from({ length: 7 }, (_, index) =>
    moment(selectedMoment).add(index, "days").format("YYYY-MM-DD")
  );

  const startDate = selectedMoment.format("YYYY-MM-DD");
  const endDate = selectedMoment.add(7, "days").format("YYYY-MM-DD");

  const hoursOfDay = Array.from({ length: 16 * 4 }, (_, index) =>
    moment("07:00 AM", "hh:mm A")
      .add(index * 15, "minutes")
      .format("hh:mm A")
  );

  const {
    data: myAppointments,
    isLoading,
    refetch: refetchMyAppointments,
  } = useMyAppointments(startDate, endDate);

  const { mutate: deleteAppointment } = useDeleteAppointment(() => {
    refetchMyAppointments();
    setDeleteAppointmentId(false);
  });

  const [formData, setFormData] = useState({
    id: "",
    patientId: "",
    fullName: "",
    startDate: moment().startOf("hour").format("YYYY-MM-DD"),
    startTime: moment().startOf("hour").format("HH:mm"),
    endDate: "",
    endTime: "",
    reasonDescription: "",
    reasonForVisit: "",
    practiceServiceId: "",
    durationInMins: defaultDurationInMins,
  });
  const [practiceServices, setPracticeServices] = useState([]);

  const [staffId, setStaffId] = useState([]);

  useEffect(() => {
    const fetchProfileInfo = async () => {
      try {
        const axiosInstance = createAxiosInstance(true);
        const response = await axiosInstance.get(`${WHO_AM_I}`);
        if (response && response.data) {
          if (response.data) {
            setStaffId(response.data.staffMember.id);
          }
        }
      } catch (error) {
        errorHandling(error);
      }
    };

    const fetchPracticeServices = async () => {
      try {
        const axiosInstance = createAxiosInstance();

        const response = await axiosInstance.get(
          `${tenantVal}${PRACTICE_SERVICE_CONTROLER_URL}`
        );
        let t = [{ label: "Type of Consultation*", value: "" }];
        if (response && response.data) {
          response.data.forEach((element) => {
            t.push({ label: element.name, value: element.id });
          });
        }
        setPracticeServices(t);
      } catch (error) {
        errorHandling(error);
      }
    };
    fetchPracticeServices();
    fetchProfileInfo();
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const errorHandling = (error) => {
    toast.error(error.message);
    if (error && error.response && error.response.status) {
      if (error.response.status == 403) {
        logoutFromHelper();
        dispatch(logout());
        dispatch(removeuser());
        navigate("/logout");
      }
    }
  };

  const openAddAppointmentModal = (startDate, startTime) => {
    if (startDate && startTime) {
      setFormData({
        ...formData,
        startDate: startDate,
        startTime: startTime,
      });
    } else {
      setFormData({
        ...formData,
        startDate: moment().startOf("hour").format("YYYY-MM-DD"),
        startTime: moment().startOf("hour").format("HH:mm"),
      });
    }

    setTimeout(() => {
      setIsAddAppointmentModalOpen(true);
    }, 100);
  };

  const [selectedAppointmentForEdit, setSelectedAppointmentForEdit] =
    useState(null);
  const [isAddAppointmentModalOpen, setIsAddAppointmentModalOpen] =
    useState(false);

  return (
    <>
      <div className={s.container}>
        <div className="d-flex align-items-center appointments-calendar-wrap justify-content-between">
          <HorizontalCalendarNew1
            selectedDate={selectedDate}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            setSelectedDate={setSelectedDate}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
          />
          <div className="btn-wrapper">
            <Button
              className="custom_btn addform_btn"
              onClick={() => {
                setIsAddAppointmentModalOpen(true);
                setSelectedAppointmentForEdit(null);
              }}
            >
              Add Appointment
            </Button>
          </div>
        </div>
        <AppointmentsTable
          myAppointments={myAppointments}
          isLoading={isLoading}
          hoursOfDay={hoursOfDay}
          nextSevenDays={nextSevenDays}
          openAddAppointmentModal={openAddAppointmentModal}
          refetchMyAppointments={refetchMyAppointments}
          setSelectedAppointmentForEdit={setSelectedAppointmentForEdit}
          setIsAddAppointmentModalOpen={setIsAddAppointmentModalOpen}
          setDeleteAppointmentId={setDeleteAppointmentId}
        />
      </div>

      {deleteAppointmentId && (
        <Modal
          open={deleteAppointmentId}
          onClose={() => setDeleteAppointmentId(false)}
          description="Are you sure you want to delete this appointment?"
          onSave={() => deleteAppointment(deleteAppointmentId)}
          width="500px"
          saveBtnTitle="Yes"
        >
          <div className="modal-body">
            <div className="worningimg">
              <img src={deleticon} alt="delete" />
            </div>
            <div className="deletext">
              <h5>Are you sure you want to delete this appointment?</h5>
            </div>
          </div>
        </Modal>
      )}
      <AddAppointmentModal
        headerTitle={
          selectedAppointmentForEdit ? "Edit Appointment" : "Add Appointment"
        }
        selectedDate={selectedDate}
        staffId={staffId}
        practiceServices={practiceServices}
        startDate={formData.startDate}
        startTime={formData.startTime}
        selectedView={selectedView}
        onSuccess={refetchMyAppointments}
        selectedAppointmentForEdit={selectedAppointmentForEdit}
        open={isAddAppointmentModalOpen}
        onClose={() => setIsAddAppointmentModalOpen(false)}
      />
    </>
  );
};

export default MyAppointments;
