import { useState } from "react";

import srcicon from "../../../../../../..//assets/images/srcicon.svg";

import styles from "./Table.module.css";
import { SortByButton } from "./SortByButton";
import { Toaster } from "react-hot-toast";
import { Loader2 } from "lucide-react";
import { ConsultationPreview } from "../../../../../../ConsultationPreview/ConsultationPreview";
import moment from "moment";

export const Table = ({ items, isLoading }) => {
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState("a-to-z");

  console.log(123, items);

  const sortedAndFilteredItems =
    items
      ?.sort((a, b) => {
        if (sorting === "a-to-z") {
          return a.consultation.title?.localeCompare(b.name);
        }
        return b.consultation.title?.localeCompare(a.name);
      })
      .filter(
        (item) =>
          item.consultation.title
            ?.toLowerCase()
            ?.includes(search.toLowerCase()) ||
          item.note?.toLowerCase()?.includes(search.toLowerCase())
      ) || [];

  const renderTableContent = () => {
    if (isLoading) {
      return (
        <div className={styles.emptyState}>
          <Loader2 className={styles.loadingSpinner} size={24} />
          <p className={styles.emptyStateTitle}>Loading data...</p>
          <p className={styles.emptyStateText}>
            Please wait while we fetch the consultations.
          </p>
        </div>
      );
    }

    if (sortedAndFilteredItems.length === 0) {
      return (
        <div className={styles.emptyState}>
          <p className={styles.emptyStateTitle}>No items found</p>
          <p className={styles.emptyStateText}>
            {search
              ? "Try adjusting your search or filters to find what you're looking for."
              : "There are no items to display at this time."}
          </p>
        </div>
      );
    }

    return (
      <ul className={styles.tableItems}>
        {sortedAndFilteredItems.map((item) => (
          <ConsultationPreview
            key={item.id}
            consultation={{
              date: moment(item.consultation?.created, "DD-MM-YYYY HH:mm"),
              title: item?.conultation?.title || "Consultation",
              consultation: item?.note,
              attachments: {
                refferrals: [],
                prescriptions: [],
              },
            }}
            text={item.note}
          >
            {item.createdByStaffMember?.firstName &&
              item.createdByStaffMember?.lastName && (
                <div className={styles.consultationDoctorSection}>
                  <h3>Doctor</h3>
                  <p>
                    {item.createdByStaffMember?.firstName +
                      " " +
                      item.createdByStaffMember?.lastName}
                  </p>
                </div>
              )}
          </ConsultationPreview>
        ))}
      </ul>
    );
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className={styles.tableWarpper}>
        <div className={styles.table}>
          <div className={styles.tableTopBarWrapper}>
            <div className={`${styles.searchWrapper} search_bar`}>
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                disabled={isLoading}
              />
              <img src={srcicon} alt="" />
            </div>
            <SortByButton
              selectedItem={sorting}
              setSelectedItem={setSorting}
              disabled={isLoading}
            />
          </div>
          {renderTableContent()}
        </div>
      </div>
    </>
  );
};
