import { useMutation } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useCreatePrescription = () => {
  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error while creating prescription: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useMutation({
    mutationFn: async (prescriptionData) => {
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.post(
        `${tenantVal}/patientprescription`,
        prescriptionData
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("Prescription saved successfully");
    },
    onError: handleNetworkError,
  });
};
