import { useState, useRef, useEffect } from "react";
import style from "./SelectDrug.module.css";
import { useMedicationsSearch } from "../../../hooks/useMedicationsSearch";
import CustomCheckbox from "../../helpers/CustomCheckbox/CustomCheckbox";
import { FormField } from "../../form/FormField/FormField";
// import { useMedicationsByTradeName } from "../../../hooks/useMedicationByTradeName";
import { useMedicationsById } from "../../../hooks/useMedicationById";
export const SelectDrug = ({ index, form, initialData }) => {
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDrug, setSelectedDrug] = useState(null);

  const isManual = form.watch(`medicines.${index}.isManual`);

  const comboboxRef = useRef(null);

  const { data: initialMedicine } = useMedicationsById(initialData?.medicineId);

  useEffect(() => {
    if (initialMedicine) {
      setSelectedDrug(initialMedicine);
    }
  }, [initialMedicine]);

  const { data, isLoading } = useMedicationsSearch(search, isManual);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    setIsOpen(true);
  };

  const handleSelect = (drug) => {
    setSelectedDrug(drug);
    form.setValue(`medicines.${index}.medicineId`, drug.id);
    form.setValue(`medicines.${index}.form`, drug.form);
    form.setValue(`medicines.${index}.dosage`, drug.strength);
    setIsOpen(false);
  };

  const handleRemoveSelection = () => {
    setSelectedDrug(null);
    form.setValue(`medicines.${index}.medicineId`, "");
    form.setValue(`medicines.${index}.form`, "");
    form.setValue(`medicines.${index}.dosage`, "");
    setIsOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (comboboxRef.current && !comboboxRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [comboboxRef]);

  const renderDropdownContent = () => {
    if (search.length < 3) {
      return (
        <div className={style.dropdownItem}>
          Type at least 3 characters to search for medications
        </div>
      );
    }

    if (isLoading) {
      return <div className={style.dropdownItem}>Loading...</div>;
    }

    if (!data?.length) {
      return (
        <div className={style.dropdownItem}>
          <div className={style.noResults}>
            No medications found for "{search}"
          </div>
          <div className={style.noResultsHelper}>
            Try searching with a different name or term
          </div>
        </div>
      );
    }

    return data.map((drug) => (
      <div
        key={drug.id}
        className={style.dropdownItem}
        onClick={() => handleSelect(drug)}
      >
        <div className={style.drugName}>{drug.tradeName}</div>
        <div className={style.drugDetails}>
          <span>Pack Size: {drug.packSize}</span>
          {"•"}
          <span>Generic Name: {drug.genericName}</span>
          {"•"}
          <span>Manufacturer: {drug.manufacturer}</span>
          {"•"}
          <span>GMS No: {drug.gmsNo || "N/A"}</span>
        </div>
      </div>
    ));
  };

  return (
    <div className={style.container} ref={comboboxRef}>
      {isManual ? (
        <div className={style.manualInputs}>
          <FormField name={`medicines.${index}.tradeName`}>
            <label className={style.formLabel}>Trade Name</label>
            <input
              type="text"
              name="tradeName"
              className="form-control"
              {...form.register(`medicines.${index}.tradeName`)}
            />
          </FormField>
          <FormField name={`medicines.${index}.manufacturer`}>
            <div className="form-field-controll">
              <label className={style.formLabel}>Manufacturer</label>
              <input
                type="text"
                name="manufacturer"
                className="form-control"
                {...form.register(`medicines.${index}.manufacturer`)}
              />
            </div>
          </FormField>
          <FormField name={`medicines.${index}.packSize`}>
            <label className={style.formLabel}>Pack Size</label>
            <input
              type="text"
              name="packSize"
              className="form-control"
              {...form.register(`medicines.${index}.packSize`)}
            />
          </FormField>
        </div>
      ) : selectedDrug ? (
        <div className={style.selectedDrug}>
          <div className={style.selectedDrugInfo}>
            <div className={style.drugName}>{selectedDrug.tradeName}</div>
            <div className={style.drugDetails}>
              <span>Pack Size: {selectedDrug.packSize}</span>
              {"•"}
              <span>Generic Name: {selectedDrug.genericName}</span>
              {"•"}
              <span>Manufacturer: {selectedDrug.manufacturer}</span>
              {"•"}
              <span>GMS No: {selectedDrug.gmsNo || "N/A"}</span>
            </div>
          </div>
          <button
            onClick={handleRemoveSelection}
            className={style.removeButton}
            type="button"
            aria-label="Remove selected drug"
          >
            ×
          </button>
        </div>
      ) : (
        <div className={style.comboboxContainer}>
          <FormField name={`medicines.${index}.medicineId`}>
            <input
              type="text"
              className="form-control"
              placeholder="Search for medications..."
              onChange={handleInputChange}
              onFocus={() => setIsOpen(true)}
              value={search}
            />
          </FormField>
          {isOpen && (
            <div className={style.dropdown}>{renderDropdownContent()}</div>
          )}
        </div>
      )}
      <label className={style.manualToggle}>
        <CustomCheckbox
          checked={isManual}
          handleChange={(e) => {
            if (e.target.checked === false && selectedDrug) {
              form.setValue(`medicines.${index}.medicineId`, selectedDrug.id);
              form.setValue(`medicines.${index}.form`, selectedDrug.form);
              form.setValue(`medicines.${index}.dosage`, selectedDrug.strength);
              setIsOpen(false);
            }
            form.setValue(`medicines.${index}.isManual`, e.target.checked);
          }}
        />
        Manual Entry
      </label>
    </div>
  );
};
