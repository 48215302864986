import { useEffect, useRef, useState } from "react";
import { useICD10Codes } from "../../../../../../../../hooks/useICD10Codes";
import style from "../AddMedicalHistoryForm.module.css";
import { FormField } from "../../../../../../../form/FormField/FormField";

export const ICDCodeSelect = ({ form }) => {
  const comboboxRef = useRef(null);

  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const { data: icd10Codes, isLoading } = useICD10Codes(search);

  const handleSelect = ({ code, name }) => {
    form.setValue(`code`, code);
    form.setValue(`medicationCondition`, name);
    setIsOpen(false);
    setSearch(code);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    setIsOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (comboboxRef.current && !comboboxRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [comboboxRef]);

  const renderDropdownContent = () => {
    if (search.length < 3) {
      return (
        <div className={style.dropdownItem}>
          Type at least 3 characters to search for medications
        </div>
      );
    }

    if (isLoading) {
      return <div className={style.dropdownItem}>Loading...</div>;
    }

    if (!icd10Codes?.length) {
      return (
        <div className={style.dropdownItem}>
          <div className={style.noResults}>
            No medications found for "{search}"
          </div>
          <div className={style.noResultsHelper}>
            Try searching with a different name or term
          </div>
        </div>
      );
    }

    return icd10Codes.map((code) => (
      <div
        key={code.id}
        className={style.dropdownItem}
        onClick={() => handleSelect(code)}
      >
        <div className={style.drugName}>{code.code}</div>
        <div className={style.drugDetails}>
          <span className={style.idcCode}>ICD Code: {code.icdType}</span>
          <span>Name: {code.name}</span>
        </div>
      </div>
    ));
  };

  return (
    <div ref={comboboxRef}>
      <FormField name={`code`}>
        <label className={style.formLabel}>Disease code</label>
        <input
          type="text"
          className="form-control"
          placeholder="Search for icd codes..."
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          value={search}
        />
      </FormField>
      {isOpen && (
        <div className={style.dropdown}>{renderDropdownContent()}</div>
      )}
    </div>
  );
};
