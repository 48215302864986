import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useTasks = () => {
  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error fetching tasks: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["tasks", tenantVal],
    queryFn: async () => {
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.get(`${tenantVal}/task/get/open`);

      return response.data.sort((a, b) => a.id - b.id);
    },
    onError: handleNetworkError,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: true,
  });
};
