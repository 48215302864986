import React, { useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { logoutFromHelper } from "../../../../api/Helper";
import { logout } from "../../../../redux/auth/AuthSlice";
import { removeuser } from "../../../../redux/user/userSlice";
import s from "./AppointmentDetails.module.css";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { createAxiosInstance } from "../../../../api/axiosConfig";
import { DELETE_APPOINTMENT } from "../../../../api/Service";
import { getAppointmentStatusColorForTable } from "../../../app/alliedhome/components/utils";

// /**
//  * Used to calculate age by date of birth.
//  * @param {*} dob indicates date of birth.
//  * @returns returns age.
//  */
const calculateAge = (dob) => {
  if (!dob) return "";
  try {
    let age;
    let aa = dob.split("-");
    if (aa && aa.length == 3) {
      const dobDate = new Date(
        aa[0], // Year
        aa[1] - 1, // Month
        aa[2] // Day
      );
      const today = new Date();
      age = today.getFullYear() - dobDate.getFullYear();
      const monthDiff = today.getMonth() - dobDate.getMonth();
      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < dobDate.getDate())
      ) {
        age--;
      }
    }
    return age;
  } catch (err) {
    return "";
  }
};

const AppointmentDetails = ({ appointment, refetchMyAppointments, status }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const tenantVal = useSelector((state) => state.auth.user?.tenant);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const errorHandling = (error) => {
    toast.error(error.message);
    if (error && error.response && error.response.status) {
      if (error.response.status == 403) {
        logoutFromHelper();
        dispatch(logout());
        dispatch(removeuser());
        navigate("/logout");
      }
    }
  };

  const handleAppointmentDelete = async (appointment) => {
    try {
      const axiosInstance = createAxiosInstance();
      const url = `${tenantVal}${DELETE_APPOINTMENT}`;
      await axiosInstance.delete(url, {
        data: {
          id: appointment.id,
          tenant: tenantVal,
        },
      });

      await refetchMyAppointments();
      toast.success("Appointment deleted successfully.");
    } catch (error) {
      errorHandling(error);
    }
  };

  try {
    if (!appointment?.patient) {
      return <div>No patient data available.</div>;
    }

    const {
      patient: {
        firstName,
        surname,
        address: {
          addressLine1,
          addressLine2,
          addressLine3,
          city,
          county,
          country,
          postCode,
        },
        dateOfBirth,
        gender,
      },
      practiceService,
      startTime,
      endTime,
      reasonDescription,
      durationInMins,
    } = appointment;

    const age = dateOfBirth ? calculateAge(dateOfBirth) : "-";
    const serviceName = practiceService ? practiceService.name : "-";
    const formattedStartTime = startTime
      ? moment(startTime).format("Do MMM YYYY HH:mm")
      : "-";
    const formattedEndTime = endTime ? moment(endTime).format("HH:mm") : "-";
    const formattedAddress =
      [
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        county,
        country,
        postCode,
      ]
        .filter(Boolean)
        .join(", ") || "-";
    const formattedReasonDescription = reasonDescription || "-";
    const formattedDurationInMins = durationInMins || "-";

    return (
      <>
        <div
          className={`${s.container} ${getAppointmentStatusColorForTable(
            status
          )}`}
        >
          <div className={s.header}>
            <div>
              <span className={s.userProfileImage}>
                {firstName && surname
                  ? firstName.trim()[0] + surname.trim()[0]
                  : (firstName.trim() ? firstName.trim().substring(0, 1) : "") +
                    (surname.trim() ? surname.trim().substring(0, 1) : "")}
              </span>
            </div>
            <div className="d-flex justify-content-between w-100">
              <div className={s.headerTextContainer}>
                <h4 className={s.name}>
                  {firstName || ""} {surname || ""}
                </h4>
                <h6 className={s.ageGender}>
                  {age} yrs {gender}
                </h6>
              </div>
            </div>
            <div className={s.icons}>
              <button
                className="deletebtn"
                onClick={(event) => {
                  event.stopPropagation();
                  setDeleteModalOpen(true);
                }}
              >
                <i className="fa-solid fa-trash" />
              </button>
            </div>
          </div>

          <ul className={s.detailsBox}>
            <li>
              <h6>Scheduled Appt:</h6>
              <p>
                {formattedStartTime} - {formattedEndTime}
              </p>
            </li>
          </ul>

          <ul className={s.detailsBox}>
            <li>
              <h6>Address:</h6>
              <p>{formattedAddress}</p>
            </li>
            <li>
              <h6>Special Notes:</h6>
              <p>{formattedReasonDescription}</p>
            </li>
            <li>
              <h6>Type of Consultation:</h6>
              <p>{serviceName}</p>
            </li>
            <li>
              <h6>Time Duration:</h6>
              <p>{formattedDurationInMins}</p>
            </li>
          </ul>
        </div>

        <Dialog
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onClick={(e) => e.stopPropagation()}
        >
          <DialogTitle>Are You Sure Want to Delete?</DialogTitle>
          <DialogContent>
            <div className={s.deleteButtonsContainer}>
              <button
                className="custom_btn me-1 w-100"
                type="submit"
                onClick={() => {
                  handleAppointmentDelete(appointment);
                  setDeleteModalOpen(false);
                }}
              >
                Submit
              </button>
              <button
                type="button"
                className="custom_btn btn-danger w-100"
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  } catch (error) {
    errorHandling(error);
    return <div>Error loading patient data.</div>;
  }
};

export default AppointmentDetails;
