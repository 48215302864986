import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useChangeConsulationMainService = (consultation) => {
  const queryClient = useQueryClient();

  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error changing consultation main service: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useMutation({
    mutationFn: async (newMainServiceId) => {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.post(`${tenantVal}/consultation`, {
        ...consultation,
        mainPracticeServiceId: newMainServiceId,
      });
      return response.data;
    },
    onSuccess: () => {
      toast.success("Main service changed successfully");
      queryClient.invalidateQueries(["activeConsultation"]);
    },
    onError: handleNetworkError,
  });
};
