import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";
import toast from "react-hot-toast";

export const useConsultationServices = (consultationId) => {
  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error fetching consultation services: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["consultationServices", consultationId, tenantVal],
    queryFn: async () => {
      if (!consultationId) return null;

      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/consultationservice/getbyconsultationid/${consultationId}`
      );
      return response.data;
    },
    enabled: !!consultationId,
    onError: handleNetworkError,
  });
};
