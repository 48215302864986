import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useActiveConsultationLetter = (consultationId) => {
  const handleNetworkError = useHandleNetworkError();
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["activeConsultationLetter", tenantVal, consultationId],
    queryFn: async () => {
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/patientletter/getbyconsultationid/${consultationId}`
      );
      return response.data;
    },
    onError: handleNetworkError,
    enabled: !!consultationId,
  });
};
