import React from "react";
import { z } from "zod";
import { useForm, FormProvider } from "react-hook-form";
import { toast, Toaster } from "react-hot-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import "react-calendar/dist/Calendar.css";

import style from "./PregnancyAddAnteNatalConsultationForm.module.css";
import { FormField } from "../../../../../../components/form/FormField/FormField";
import moment from "moment";
import { useSelector } from "react-redux";
import { finishAnteNatalConsultation } from "../../utils/finishAnteNatalConsultation";
import { fetchPatientById } from "../../utils/fetchPatientById";
import { AddLetterModal } from "../../../../../../components/AddLetterModal/AddLetterModal";
import { AddPrescriptionModal } from "../../../../../../components/AddPrescriptionModal/AddPrescriptionModal";
import { AddReferralModal } from "../../../../../../components/AddReferralModal/AddReferralModal";

const formSchema = z.object({
  bloodPressure: z
    .string()
    .min(1, { message: "Please provide the Blood Presure." }),
  urinalysis: z.string().min(1, { message: "Please provide the Urinalysis." }),
  ankleOedema: z.enum(["yes", "no"]),
  foetalMovelmentPalpation: z.enum(["yes", "no"]),
  symphysis: z.string().min(1, { message: "Please provide the Symphysis." }),
  heartHeardWithDoppler: z.enum(["yes", "no"]),
  impression: z.string().min(1, { message: "Please provide the impressions." }),
  plan: z.string().min(1, { message: "Please provide the plan." }),
  additionalInfo: z.string(),
});

export const PregnancyAddAnteNatalConsultationForm = ({
  closeView,
  tenantVal,
  pregnancyId,
  pregnancyHistory,
  patientId,
}) => {
  const { data: patient } = useQuery({
    queryKey: ["patient", patientId, tenantVal],
    queryFn: () => fetchPatientById(patientId, tenantVal),
  });
  const userName = useSelector(
    (state) => state.auth.user?.name || localStorage.getItem("kinesin-name")
  );
  const queryClient = useQueryClient();
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      bloodPressure: "",
      urinalysis: "",
      ankleOedema: "no",
      foetalMovelmentPalpation: "no",
      symphysis: "",
      heartHeardWithDoppler: "no",
      impression: "",
      additionalInfo: "",
      plan: "",
    },
  });

  const { mutate: finishConsultation, isPending } = useMutation({
    mutationFn: async (values) => {
      await finishAnteNatalConsultation(
        values,
        tenantVal,
        pregnancyId,
        patientId
      );
    },
    onSuccess: () => {
      form.reset();
      closeView();
      queryClient.invalidateQueries(["anteNatalConsultations"]);
    },
    onError: () => {
      toast.error("Oups, something went wrong!");
    },
  });

  const handleFormSubmit = async (values) => {
    finishConsultation(values);
  };

  return (
    <div className={style.addAnteNatalConsultationFormWrapper}>
      <Toaster position="top-center" reverseOrder={false} />
      <FormProvider {...form}>
        <form
          className={style.addPregnancyForm}
          onSubmit={form.handleSubmit(handleFormSubmit)}
        >
          <div className={style.inputGroupGrid}>
            <div>
              <label className={style.pregnancyFormLabel}>
                Consultation type
              </label>
              <input
                disabled
                className="form-control"
                value="Ante Natal Visit"
              />
            </div>
            <div>
              <label className={style.pregnancyFormLabel}>Date and time</label>
              <input
                className="form-control"
                disabled
                value={moment(new Date()).format("DD/MM/YYYY, hh:mm")}
              />
            </div>
            <div>
              <label className={style.pregnancyFormLabel}>Doctor</label>
              <input disabled value={userName} className="form-control" />
            </div>
          </div>
          <div>
            <label className={style.pregnancyFormLabel}>History</label>
            <textarea
              className="form-control textareaform-control"
              style={{ minHeight: "84px" }}
              disabled
              value={pregnancyHistory}
            />
          </div>
          <h3 className={style.pregnancyFormSectionTitle}>Exam</h3>
          <div className={style.inputGroupGrid}>
            <FormField name="bloodPressure">
              <label className={style.pregnancyFormLabel}>BP</label>
              <input
                type="text"
                inputMode="numeric"
                className="form-control"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9/]/g, ""))
                }
                {...form.register("bloodPressure")}
              />
            </FormField>
            <FormField name="urinalysis">
              <label className={style.pregnancyFormLabel}>Urinalysis</label>
              <input
                type="text"
                className="form-control"
                {...form.register("urinalysis")}
              />
            </FormField>
            <FormField name="ankleOedema">
              <label className={style.pregnancyFormLabel}>Ankle oedema</label>
              <select
                as="select"
                className="form-select form-control custom-select"
                {...form.register("ankleOedema")}
              >
                <option value="yes">yes</option>
                <option value="no">no</option>
              </select>
            </FormField>
            <FormField name="foetalMovelmentPalpation">
              <label className={style.pregnancyFormLabel}>
                Foetal movement present as per palpation
              </label>
              <select
                as="select"
                className="form-select form-control custom-select"
                {...form.register("foetalMovelmentPalpation")}
              >
                <option value="yes">yes</option>
                <option value="no">no</option>
              </select>
            </FormField>
            <FormField name="symphysis">
              <label className={style.pregnancyFormLabel}>
                Symphysis - fundal height (from 24 weeks)
              </label>
              <div className={style.cmInput}>
                <input
                  type="text"
                  inputMode="numeric"
                  className="form-control"
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  }
                  {...form.register("symphysis")}
                />
              </div>
            </FormField>
            <FormField name="heartHeardWithDoppler">
              <label className={style.pregnancyFormLabel}>
                Foetal heart heard with doppler (from 20 weeks)
              </label>
              <select
                as="select"
                className="form-select form-control custom-select"
                {...form.register("heartHeardWithDoppler")}
              >
                <option value="yes">yes</option>
                <option value="no">no</option>
              </select>
            </FormField>
          </div>
          <FormField name="impression">
            <label className={style.pregnancyFormLabel}>Impression</label>
            <textarea
              className="form-control textareaform-control"
              style={{ minHeight: "84px" }}
              {...form.register("impression")}
            />
          </FormField>
          <FormField name="plan">
            <label className={style.pregnancyFormLabel}>Plan</label>
            <textarea
              className="form-control textareaform-control"
              style={{ minHeight: "84px" }}
              {...form.register("plan")}
            />
          </FormField>
          <FormField name="additionalInfo">
            <label className={style.pregnancyFormLabel}>Additional Info</label>
            <textarea
              className="form-control textareaform-control"
              style={{ minHeight: "84px" }}
              {...form.register("additionalInfo")}
            />
          </FormField>
          <div className={style.attachemntButtons}>
            <AddPrescriptionModal />
            <AddReferralModal patient={patient} />
            <AddLetterModal patient={patient} />
          </div>
          <div className={style.buttonRow}>
            <button
              type="submit"
              className={style.addConsultationButton}
              disabled={isPending}
            >
              Save consultation
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
