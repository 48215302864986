import React, { useEffect, useState } from "react";
import CardWithTitle from "../../../../../components/helpers/CardWithTitle/CardWithTitle.jsx";
import Modal from "../../../../../components/helpers/Modal/Modal.jsx";
import Select from "../../../../../components/helpers/Select/Select.jsx";
import Plus from "../../../../../assets/images/transparrent-plus.svg";
import s from "./FamilyMedicalHistory.module.css";
import { createAxiosInstance } from "../../../../../api/axiosConfig.js";
import { useSelector } from "react-redux";
import {
  PATIENT_FAMILY_HISTORY,
  PATIENT_FAMILY_HISTORY_GET_BYPATIENTID,
  GET_DISEASE_CODES,
} from "../../../../../api/Service.js";
import CustomAutocomplete from "../../../../../components/helpers/CustomAutocomplete.jsx";
import Table from "../../../../../components/helpers/Table/Table.jsx";
import toast, { Toaster } from "react-hot-toast";
import {Autocomplete, TextField} from "@mui/material";

const FamilyMedicalHistory = () => {
  const [isModalOpen, setIsModalOpen] = useState({ open: false, id: null });
  const [familyRelationship, setFamilyRelationship] = useState("FATHER");
  const [medicalDetails, setMedicalDetails] = useState("");
  const [diseaseCodeInput, setDiseaseCodeInput] = useState("");
  const [diseaseCodeData, setDiseaseCodeData] = useState([]);
  const [deceased, setDeceased] = useState("NO");
  const [familyData, setFamilyData] = useState([]);

  const patientId = localStorage.getItem("kinesin-patientId");

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  useEffect(() => {
    fetchMedicalHistory();
    setDiseaseCodeData('')
    setFullName('')
  }, []);

  const fetchMedicalHistory = async () => {
    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${PATIENT_FAMILY_HISTORY}${PATIENT_FAMILY_HISTORY_GET_BYPATIENTID}/${patientId}`
      );
      setFamilyData(response.data);
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
      console.error("There was a problem with the axios operation:", error);
    }
  };

  const deleteMedicalHistory = async (id) => {
    try {
      const axiosInstance = createAxiosInstance();
      await axiosInstance.delete(
        `${tenantVal}${PATIENT_FAMILY_HISTORY}/delete`,
        {
          data: {
            id: id,
          },
        }
      );
      fetchMedicalHistory();
      toast.success("Data deleted successfully!");
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
      console.error("There was a problem with the axios operation:", error);
    }
  };

  const handleDeleteMedicalHistory = async (id) => {
    deleteMedicalHistory(id);
  };

  const fetchDiseaseCodes = async () => {
    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${GET_DISEASE_CODES}`
      );
      setDiseaseCodeData(response.data);
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
      console.error("There was a problem with the axios operation:", error);
    }
  };

  const handleDiseaseCodeInputChange = (event, value) => {
    console.log("check disease code gitvalue : ", value);

    if (value.length >= 3) {
      fetchDiseaseCodes();
      setDiseaseCodeInput(value);
    }
  };

  const handleOpenModal = (id) => {
    console.log('form pressed ... ')

    setIsModalOpen({ open: true, id: typeof id === "number" ? id : null });
    if (id) {
      const selectedData = familyData.find((item) => item.id === id);
      if (selectedData) {
        setFamilyRelationship(selectedData.familyRelationship);
        setMedicalDetails(selectedData.medicalDetails);
        setDiseaseCodeInput(selectedData.diseaseCode);
        setDeceased(selectedData.deceased);
      }
    } else {
      clearForm();
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen({ open: false, id: null });
    clearForm();
  };

  const handleSave2 = async (event) => {

    console.log("SAVING HERE FOR MODAL -------------> handleSave2");

    const formData = {
      id: isModalOpen.id ?? null,
      familyRelationship,
      medicalDetails,
      diseaseCode: diseaseCodeData,
      deceased,
      patientId,
    };
    console.log("SAVING HERE FOR MODAL -------------> handleSave3");

    try {
      const axiosInstance = createAxiosInstance();
      console.log("SAVING HERE FOR MODAL -------------> handleSave4");

      await axiosInstance.post(
          `${tenantVal}${PATIENT_FAMILY_HISTORY}`,
          formData
      );

      toast.success("Data saved successfully!");
      setIsModalOpen({ open: false, id: null });
      clearForm();
      fetchMedicalHistory();
    } catch (error) {
      console.error("There was a problem with the axios operation:", error);
    }
  }

    const handleSave = async (event) => {
    event.preventDefault();

    const formData = {
      id: isModalOpen.id ?? null,
      familyRelationship,
      medicalDetails,
      diseaseCode: diseaseCodeData,
      deceased,
      patientId,
    };

    try {
      const axiosInstance = createAxiosInstance();
      if (isModalOpen.id) {
        await axiosInstance.post(
          `${tenantVal}${PATIENT_FAMILY_HISTORY}`,
          formData
        );
      }
      toast.success("Data saved successfully!");
      setIsModalOpen({ open: false, id: null });
      clearForm();
      fetchMedicalHistory();
    } catch (error) {
      console.error("There was a problem with the axios operation:", error);
    }
  };

  const clearForm = () => {
    setFamilyRelationship("FATHER");
    setMedicalDetails("");
    setDiseaseCodeInput("");
    setDeceased("NO");
  };

  const handleFamilyRelationshipChange = (value) => {
    setFamilyRelationship(value);
  };

  const handleMedicalDetailsChange = (event) => {
    setMedicalDetails(event.target.value);
  };

  const handleDeceasedChange = (event) => {
    setDeceased(event.target.checked ? "YES" : "NO");
  };

  const columns = [
    { field: "familyRelationship", header: "Relationship" },
    { field: "diseaseCode", header: "Disease Code" },
    { field: "medicalDetails", header: "Medical Details" },
  ];

  const [fullName, setFullName] = useState('');
  const [options, setOptions] = useState([]);
  const onInputChange = (event, value, reason) => {
    if (value && value.length > 0) {
      getData(value);
    } else {
      setTimeout(() => {
        setOptions([]);
      }, 0);
    }
  };
  const getData = async (searchTerm) => {
    if (!searchTerm)
      setOptions([]);
    const axiosInstance = createAxiosInstance();

    var searchTextValue = searchTerm.replace(/\//g, );
    const response = await axiosInstance.get(
        `${tenantVal}${GET_DISEASE_CODES}` + '/search/' + searchTextValue
    );
    if (response && response.data && response.data.length > 0) {
      const updatedOptions = response.data.map((p) => {
        return {
          id: p.code,
          title: (p.code + ' - ' + p.name),
        };
      });
      setOptions(updatedOptions);
    } else
      setOptions([]);
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <CardWithTitle
        title={"Family Medical History"}
        width={"100%"}
        marginBottom={"34px"}
        onButtonClick={() => handleOpenModal(null)}
        buttonTitle={"Add new"}
        icon={Plus}
        className={s.addButton}
      >
        <Table
          columns={columns}
          data={familyData}
          tableHeader={true}
          onDelete={handleDeleteMedicalHistory}
          onEdit={handleOpenModal}
        />

        {isModalOpen.open && (
          <Modal
            title="Family Medical History"
            width="560px"
            position="center"
            onClose={handleCloseModal}
            onSave={handleSave2}
          >
            <form >
              <div className={s.inputWrapper}>
                <p className={s.rowTitle}>Family Relationship</p>
                <Select
                  options={[
                    "FATHER",
                    "MOTHER",
                    "DAUGHTER",
                    "SON",
                    "GRANDFATHER",
                    "GRANDMOTHER",
                    "CARER",
                    "OTHER",
                  ]}
                  value={familyRelationship ?? "FATHER"}
                  onChange={handleFamilyRelationshipChange}
                  required
                />
              </div>
              <div className={s.inputWrapper}>
                <p className={s.rowTitle}>Medical Details</p>
                <textarea
                  placeholder="type here"
                  className={s.textarea}
                  value={medicalDetails ?? ""}
                  type="text"
                  rows="2"
                  maxLength="200"
                  onChange={handleMedicalDetailsChange}
                />
              </div>
              <div className={s.inputWrapper}>
                <p className={s.rowTitle}>Disease Code</p>
                <Autocomplete

                    disablePortal
                    id="combo-box-demo"
                    value={fullName || null}
                    options={options}
                    getOptionLabel={(option) => (option && option.title ? option.title : fullName)}
                    onInputChange={onInputChange}
                    onChange={(event, newValue) => {
                      setFullName(newValue ? newValue.title : '');
                      console.log(" SELECTED VALUE + " + newValue.id)
                      setDiseaseCodeData(newValue.id);
                    }}
                    renderInput={(params) => <TextField {...params} label=""
                                                        placeholder="Search here..."/>}
                />
              </div>
              <div className={s.inputWrapper}>
                <p className={s.rowTitle}>Deceased ?</p>
                <Select
                  options={["YES", "NO", "UNKNOWN"]}
                  value={deceased ?? "NO"}
                  onChange={handleDeceasedChange}
                  required
                />
              </div>
            </form>
          </Modal>
        )}
      </CardWithTitle>
    </>
  );
};

export default FamilyMedicalHistory;
