import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import debounce from "lodash/debounce";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useUpdateConsultationNotes = (
  consultationId,
  patientId,
  noteId
) => {
  const queryClient = useQueryClient();

  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error saving notes: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const mutation = useMutation({
    mutationFn: async (notes) => {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.post(
        `${tenantVal}/consultationnote`,
        {
          id: noteId,
          consultationId: Number(consultationId),
          note: notes,
          patientId: Number(patientId),
        }
      );
      return response.data;
    },
    onSuccess: (data) => {
      toast.success("Consultation note auto saved");
      queryClient.invalidateQueries(["consultationNote", consultationId]);
    },
    onError: handleNetworkError,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedMutate = useCallback(
    debounce(
      (notes) => {
        if (!mutation.isPending) {
          mutation.mutate(notes);
        }
      },
      2000,
      { maxWait: 3000 }
    ),
    [mutation.isPending]
  );

  return {
    updateNotes: debouncedMutate,
    isPending: mutation.isPending,
  };
};
