import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";
import toast from "react-hot-toast";

export const useNoteTemplates = () => {
  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error fetching note templates: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["noteTemplates", tenantVal],
    queryFn: async () => {
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.get(`${tenantVal}/notetemplate`);
      return response.data;
    },
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    onError: handleNetworkError,
  });
};
