import { Modal, Button } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from "react";

import {
    PATIENT_DOCUMENTS_CONTROLER_URL_ID,
} from "../../api/Service";
import {createAxiosInstance} from "../../api/axiosConfig";
import {useDispatch, useSelector} from "react-redux";
import toast from "react-hot-toast";
import {logoutFromHelper} from "../../api/Helper";
import {logout} from "../../redux/auth/AuthSlice";
import {removeuser} from "../../redux/user/userSlice";
import {useNavigate} from "react-router-dom";


const ModalBody = ({ imageData, onHide, documentId }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentDocument, setCurrentDocument] = useState(null);

    console.log('documentId ' + documentId)

    const tenantVal =
        useSelector((state) => state.auth.user?.tenant) ||
        localStorage.getItem("kinesin-tenant");

    const isImage = () => {
        return currentDocument?.imageType;
    }

    useEffect(() => {
        const fetchDocumentById = async () => {
            try {
                if (documentId) {
                    const axiosInstance = createAxiosInstance();
                    const response = await axiosInstance.get(
                        `${tenantVal}${PATIENT_DOCUMENTS_CONTROLER_URL_ID}${documentId}`
                    );
                    setCurrentDocument(response.data);
                }
            } catch (error) {
                errorHandling(error)
            }
        };
        fetchDocumentById();
    }, [documentId]);

    const errorHandling = (error) => {
        console.log(error);
        toast.error(error.message);
        if (error && error.response && error.response.status) {
            if (error.response.status == 403) {
                logoutFromHelper();
                dispatch(logout());
                dispatch(removeuser());
                navigate("/logout");
            }
        }
    };


    const handlePrint = () => {

        function ImageToPrint(source)
        {
            return "<html><head><scri"+"pt>function step1(){\n" +
                "setTimeout('step2()', 10);}\n" +
                "function step2(){window.print();window.close()}\n" +
                "</scri" + "pt></head><body onload='step1()'>\n" +
                "<img src='" + imageData + "' /></body></html>";
        }
        // const printWindow = window.open('', '_blank');
        // printWindow.document.write(`
        //   <html>
        //     <head>
        //       <title>Print Preview</title>
        //       <style>
        //         img {
        //           width: 100%;
        //         }
        //       </style>
        //     </head>
        //     <body>
        //       <img src="${imageData}" />
        //     </body>
        //   </html>
        // `);
        // // printWindow.document.close();
        // printWindow.print();
        // printWindow.close();
        var Pagelink = "about:blank";
        var pwa = window.open(Pagelink, "_new");
        pwa.document.open();
        pwa.document.write(ImageToPrint(imageData));
        pwa.document.close();
    };

    const handleDownload = async () => {
        try {
            const response = await fetch(imageData);
            const blob = await response.blob();
            saveAs(blob, currentDocument.fileName);
        } catch (error) {
            console.error('Error downloading document:', error);
        }
    };

    return (
        <div>
            <h3 style={{paddingBottom: '50px'}} >Preview document - {currentDocument?.fileName} </h3>
            { isImage() && <img src={imageData} alt="Document Preview" style={{ maxWidth: '100%' }} /> }
            { !isImage() && <span style={{ maxWidth: '100%', textAlign: 'center', width: '100%' }} >Cannot preview <b>{ currentDocument?.fileType} files </b> please download {currentDocument?.fileName} </span> }
            <div className="modal-button-group">
                { isImage() && <Button onClick={handlePrint} className='custom_btn'>Print</Button> }
                <Button  onClick={() => handleDownload()} className='custom_btn'>Download</Button>
                {/* <Button variant="secondary" onClick={onHide}>Close</Button> */}
            </div>
        </div>
    );
};

const PreviewModal = ({ show, onHide, imageData, documentId }) => {
    return (
        <Modal show={show} onHide={onHide} size="lg" className='preview-modal custom-modal-wrap' centered>
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalBody imageData={imageData} documentId={documentId} />
            </Modal.Body>
        </Modal>
    );
};

export default PreviewModal;
