import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useAddMedicalHistory = () => {
  const queryClient = useQueryClient();
  const patientId = localStorage.getItem("kinesin-patientId");

  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error adding medical history: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useMutation({
    mutationFn: async (newHistory) => {
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.post(
        `${tenantVal}/patientmedicalhistory`,
        {
          tenant: tenantVal,
          patientId,
          ...newHistory,
        }
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("Medical history added successfully!");
      queryClient.invalidateQueries(["medicalHistory", tenantVal]);
    },
    onError: handleNetworkError,
  });
};
