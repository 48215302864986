import { Toaster } from "react-hot-toast";
import styles from "./ConsultationsCard.module.css";
import { ConsultationsTable } from "../ConsultationsTable/ConsultationsTable";

export const ConsultationsCard = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>Consultations</h2>
      </div>
      <div className={styles.wrapper}>
        <Toaster position="top-center" reverseOrder={false} />
        <ConsultationsTable />
      </div>
    </div>
  );
};
