import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useClientSearch = (searchTerm) => {
  const handleNetworkError = useHandleNetworkError();

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["clients", searchTerm],
    tenantVal,
    queryFn: async () => {
      if (!searchTerm) return [];
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/patient/search/${searchTerm}`
      );
      return response.data;
    },
    onError: handleNetworkError,
    enabled: !!searchTerm,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
  });
};
