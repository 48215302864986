import React, { useState, useEffect } from "react";
import s from "./EditModalForm.module.css";
import Select from "../../../../../components/helpers/Select/Select.jsx";
import CustomInput from "../../../../../components/helpers/CustomInput/CustomInput.jsx";
import CustomDatepicker from "../../../../../components/helpers/CustomDatepicker.jsx";
import { useDispatch, useSelector } from "react-redux";
import { GET_ALL_STAFF } from "../../../../../api/Service.js";
import { createAxiosInstance } from "../../../../../api/axiosConfig.js";
import toast from "react-hot-toast";
import { logoutFromHelper } from "../../../../../api/Helper";
import { logout } from "../../../../../redux/auth/AuthSlice";
import { removeuser } from "../../../../../redux/user/userSlice";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

const EditModalForm = ({
  patient,
  handleInputChange,
  handleInputAddressChange,
  handleInputDoctorChange,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [doctors, setDoctors] = useState([]);
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const errorHandling = (error) => {
    console.log(error);
    toast.error(error.message);
    if (error && error.response && error.response.status) {
      if (error.response.status == 403) {
        logoutFromHelper();
        dispatch(logout());
        dispatch(removeuser());
        navigate("/logout");
      }
    }
  };

  const patientDied = (patientObj) => {
    if (patient && patient.died && patient.died == true) return "Yes";
    else return "No";
  };

  const patientGsm = (patientObj) => {
    if (patient && patient.gsm && patient.gsm == true) return "Yes";
    else return "No";
  };

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const axiosInstance = createAxiosInstance();
        const response = await axiosInstance.get(
          `${tenantVal}${GET_ALL_STAFF}`
        );
        let t = [];
        if (response && response.data) {
          response.data.forEach((element) => {
            t.push({
              label: element.firstName + " " + element.lastName,
              doctorName: `${element.firstName} ${element.lastName}`,
              firstName: element.firstName,
              lastName: element.lastName,
              value: element.id,
              profilePic: element.profilePic,
            });
          });
        }
        setDoctors(t);
      } catch (error) {
        errorHandling(error);
      }
    };

    fetchDoctors();
  }, []);

  console.log(patient)
  console.log("DOB >> "  + patient.dateOfBirth)
  console.log(moment(new Date(patient.dateOfBirth)).format("yyyy-MM-DD"))

  return (
    <div>
      <form className={s.formContainer}>
        <div className={s.topContainer}>
          <div className={s.formTopContainer}>
            <div className={s.inputWrapper}>
              <label className={s.label}>Title</label>
              <Select
                options={["Mr.", "Mrs.", "Miss", "Dr."]}
                value={patient.title || ""}
                onChange={(value) => handleInputChange("title", value)}
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>First name</label>
              <CustomInput
                type="text"
                value={patient.firstName || ""}
                onChange={(e) => handleInputChange("firstName", e.target.value)}
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>Last name</label>
              <CustomInput
                type="text"
                value={patient.surname || ""}
                onChange={(e) => handleInputChange("surname", e.target.value)}
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>PPS Number</label>
              <CustomInput
                type="text"
                value={patient.nationalNumber || ""}
                onChange={(e) =>
                  handleInputChange("nationalNumber", e.target.value)
                }
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>DOB</label>
              <CustomDatepicker
                value={moment(new Date(patient.dateOfBirth))  || ""}
                onChange={(date) => handleInputChange("dateOfBirth", date)}
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>Mobile number</label>
              <CustomInput
                type="text"
                value={patient.mobilePhone || ""}
                onChange={(e) =>
                  handleInputChange("mobilePhone", e.target.value)
                }
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>Phone number</label>
              <CustomInput
                type="text"
                value={patient.homePhone || ""}
                onChange={(e) => handleInputChange("homePhone", e.target.value)}
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>Email</label>
              <CustomInput
                type="email"
                value={patient.email || ""}
                onChange={(e) => handleInputChange("email", e.target.value)}
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>Sex</label>
              <Select
                options={["","Male", "Female","Other"]}
                value={patient.gender || ""}
                onChange={(value) => handleInputChange("gender", value)}
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>Next of kin</label>
              <CustomInput
                type="text"
                value={patient.nextOfKin || ""}
                onChange={(e) => handleInputChange("nextOfKin", e.target.value)}
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>Next of kin Relationship</label>
              <CustomInput
                type="text"
                value={patient.nextOfKinRelationship || ""}
                onChange={(e) =>
                  handleInputChange("nextOfKinRelationship", e.target.value)
                }
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>Next of kin Phone</label>
              <CustomInput
                type="text"
                value={patient.nextOfKinPhone || ""}
                onChange={(e) =>
                  handleInputChange("nextOfKinPhone", e.target.value)
                }
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>Is deceased</label>
              <Select
                options={[".", "Yes", "No"]}
                value={patientDied(patient)}
                onChange={(value) => {
                  console.log("died " + value);
                  if (value == "Yes") {
                    handleInputChange("died", true);
                  } else {
                    handleInputChange("died", false);
                  }
                }}
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>Date of Death</label>
              <CustomDatepicker
                value={patient.dateOfDeath || ""}
                onChange={(date) => handleInputChange("dateOfDeath", date)}
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>Cause of death</label>
              <CustomInput
                type="text"
                value={patient.causeOfDeath || ""}
                onChange={(e) =>
                  handleInputChange("causeOfDeath", e.target.value)
                }
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>Main doctor</label>
              <select
                as="select"
                className="form-select form-control custom-select"
                value={patient.doctorDetailsId}
                onChange={(e) => {
                  console.log("data " + e.target);
                  console.log(e.target);
                  handleInputDoctorChange(e);
                }}
              >
                <option value="">Select</option>

                {doctors
                  ? doctors.map((Staff) => (
                      <option value={Staff.value} key={Staff.value}>
                        {Staff.firstName} {Staff.lastName}
                      </option>
                    ))
                  : ""}
              </select>
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>GSM patient</label>
              <Select
                options={[".", "Yes", "No"]}
                value={patientGsm(patient)}
                onChange={(value) => {
                  if (value == "Yes") {
                    handleInputChange("gsm", true);
                  } else {
                    handleInputChange("gsm", false);
                  }
                }}
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>Medical card number (GSM)</label>
              <CustomInput
                name="medicalCardNumber"
                type="text"
                value={patient.medicalCardNumber}
                onChange={(e) =>
                  handleInputChange("medicalCardNumber", e.target.value)
                }
              />
            </div>
          </div>
        </div>
        <div className={s.bottomContainer}>
          <h3 className={s.subTitle}>Address</h3>
          <div className={s.formTopContainer}>
            <div className={s.inputWrapper}>
              <label className={s.label}>Address Line 1</label>
              <CustomInput
                type="text"
                value={patient.address.addressLine1 || ""}
                onChange={(e) =>
                  handleInputAddressChange("addressLine1", e.target.value)
                }
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>Address Line 2</label>
              <CustomInput
                type="text"
                value={patient.address.addressLine2 || ""}
                onChange={(e) =>
                  handleInputAddressChange("addressLine2", e.target.value)
                }
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>Address Line 3</label>
              <CustomInput
                type="text"
                value={patient.address.addressLine3 || ""}
                onChange={(e) =>
                  handleInputAddressChange("addressLine3", e.target.value)
                }
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>County</label>
              <CustomInput
                type="text"
                value={patient.address.county || ""}
                onChange={(e) =>
                  handleInputAddressChange("county", e.target.value)
                }
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>Post Code</label>
              <CustomInput
                type="text"
                value={patient.address.postCode || ""}
                onChange={(e) =>
                  handleInputAddressChange("postCode", e.target.value)
                }
              />
            </div>

            <div className={s.inputWrapper}>
              <label className={s.label}>City</label>
              <CustomInput
                type="text"
                value={patient.address.city || ""}
                onChange={(e) =>
                  handleInputAddressChange("city", e.target.value)
                }
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditModalForm;
